import React from 'react';
import { Box, HStack, Text, Tooltip, Icon, VStack } from "@chakra-ui/react";
import { TimeIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { DollarIcon } from 'evergreen-ui';

const StatsPanel = ({ runTime, promptTokens, completionTokens, passPercent, dollarCost, isCollapsed, currRunState=null}) => {
  const Wrapper = isCollapsed ? React.Fragment : Box;
  const tokensSuffix = isCollapsed ? '' : 'tkn';

  let totalTests, passedTests, totalAssertions, passedAssertions, testsPassedWithAssertion, testsWithAssertion;

  // Compute stats only if currRunState is not null
  if (currRunState) {
    console.log("CURR RUN STATE IS ", currRunState)
    totalTests = currRunState.test_results.length;
    passedTests = currRunState.test_results.reduce((acc, test) => {
      return acc + (test.test_stats.stats.percent_success >= 90 ? 1 : 0);
    }, 0);

    // Reset totalAssertions and passedAssertions to 0 before calculation
    totalAssertions = 0;
    passedAssertions = 0;

    testsPassedWithAssertion = 0;
    testsWithAssertion = 0;


    // Iterate over each test result
    currRunState.test_results.forEach(test => {
      let hasAssertions = false;
      let allAssertionsPassed = true;

      // Iterate over each cell result within the test
      test.cell_results.forEach(cell => {
        // Iterate over each evaluation (assertion) within the cell result
        cell.evals.forEach(evaluation => {
          hasAssertions = true; // There is at least one assertion in this test
          totalAssertions += 1;
          if (evaluation.status.toLowerCase() === 'passed') {
            passedAssertions += 1;
          } else {
            allAssertionsPassed = false; // Not all assertions are passed
          }
        });
      });

      if (hasAssertions) {
        testsWithAssertion += 1;
        if (test.test_stats.stats.percent_success >= 90) {
          testsPassedWithAssertion += 1;
        }
      }
    });

    // in this case, pass percentage is tests passed over not
    passPercent = ((testsPassedWithAssertion / testsWithAssertion) * 100).toFixed(2)
  }

  // Tooltip content that shows additional details when currRunState is not null
  const chainDetailedPassedView = (currRunState && currRunState.chain_stats && currRunState.chain_stats.stats) ? (
    <Tooltip hasArrow label={
      <VStack align="start" spacing="0">
        <Text>Tests passed / total: {passedTests}/{totalTests} ({((passedTests / totalTests) * 100).toFixed(2)}%)</Text>
        <Text>Assertions passed / total: {passedAssertions}/{totalAssertions} ({((passedAssertions / totalAssertions) * 100).toFixed(2)}%)</Text>
        <Text>Tests with assertions passed / total: {testsPassedWithAssertion}/{testsWithAssertion} ({((testsPassedWithAssertion / testsWithAssertion) * 100).toFixed(2)}%)</Text>
      </VStack>
    } fontSize="md">
      <InfoOutlineIcon color="blue.500" />
    </Tooltip>
  ) : null;

  return (
    <Wrapper {...(!isCollapsed && {border: "1px", borderRadius: "md", padding: "2", marginBottom: "1", borderColor: "orange.500"})}>
      <HStack spacing="6%" justify="center">
        {(runTime !== null && runTime !== undefined) && (
          <Tooltip label="Total Time" fontSize="md">
            <HStack spacing="8px">
              <Icon as={TimeIcon} color="green"/>
              <Text>{Number(runTime).toFixed(3)}s</Text>
            </HStack>
          </Tooltip>
        )}
        {(promptTokens !== undefined && completionTokens !== undefined) && (
          <Tooltip label="Prompt/Completion Tokens" fontSize="md">
            <HStack spacing="8px">
              <Icon viewBox="0 0 24 24">
              <path fill="none" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
              </Icon>
              <Text>{Number(promptTokens).toFixed(0)}{tokensSuffix}/{Number(completionTokens).toFixed(0)}{tokensSuffix}</Text>
            </HStack>
          </Tooltip>
        )}
        {(passPercent !== null && passPercent !== undefined) && (
          (currRunState) ? (
            <HStack spacing="8px">
              <Icon viewBox="0 0 24 24">
              <path fill="none" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
              </Icon>
              <HStack spacing="8px">
                <Text>{Number(passPercent).toFixed(2)}%</Text>
                {chainDetailedPassedView}
              </HStack>
            </HStack>
          ) : (<Tooltip label="% Passed" fontSize="md">
            <HStack spacing="8px">
              <Icon viewBox="0 0 24 24">
              <path fill="none" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
              </Icon>
              <HStack spacing="8px">
                <Text>{Number(passPercent).toFixed(2)}%</Text>
              </HStack>
            </HStack>
          </Tooltip>)
        )}
        {(dollarCost !== null && dollarCost !== undefined) && (
          <Tooltip label="Cost" fontSize="md">
            <HStack spacing="8px">
              <Icon as={DollarIcon} color="green"/>
              <Text>{Number(dollarCost).toFixed(6)}</Text>
            </HStack>
          </Tooltip>
        )}
      </HStack>
    </Wrapper>
  );
}

export default StatsPanel;
