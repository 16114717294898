import React, {useEffect, useState} from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    FormControl,
    FormLabel,
    Input,
    Box, Menu, MenuButton, MenuList, MenuItem, Tag, VStack, Text, Textarea, HStack, useDisclosure
} from '@chakra-ui/react';
import axios from "axios";
import {useUtilityFunctions} from "../../UtilityFunctions";

const RetrievalConfig = ({
                             variableName,
                             variables,
                             setVariables,
                             suiteData,
                             setSuiteData,
                             workspaceDataID
                         }) => {
    const [documents, setDocuments] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const {getAccessToken} = useUtilityFunctions();
    const [localVariable, setLocalVariable] = useState(variables[variableName]);
    const [queryString, setQueryString] = useState('');
    const [retrievalResults, setRetrievalResults] = useState('');
    const {isOpen: isLargePreviewOpen, onOpen: onOpenLargePreview, onClose: onCloseLargePreview} = useDisclosure();

    const handleSendQuery = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceDataID}/documents/${selectedDocument.id}/retrieval`,
                {
                    query: queryString,
                    variable: localVariable // Assuming localVariable is the correct format for variable_in
                },
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`, // Assuming getAccessToken is defined somewhere
                    }
                }
            );

            // Assume response.data.retrieval holds the retrieval result
            setRetrievalResults(JSON.stringify(response.data.retrieval, null, 2));
        } catch (error) {
            console.error('Failed to fetch retrieval:', error);
            // Handle error as needed
        }
    };


    const handleDocumentSelection = (selectedDoc) => {


        setSelectedDocument(selectedDoc);

        // Update the local copy of the variable
        if (selectedDoc.db_details.type === "pinecone") {
            setLocalVariable({
                ...localVariable,
                aux: {
                    ...localVariable.aux,
                    document_id: selectedDoc.id,
                    workspace_id: workspaceDataID,
                },
            });
        }
    };

    const handleTopKChange = (e) => {
        const intTopK = parseInt(e.target.value);
        setLocalVariable((prevLocalVariable) => ({
            ...prevLocalVariable,
            aux: {
                ...prevLocalVariable.aux,
                params: {
                    ...prevLocalVariable.aux.params,
                    top_k: intTopK,
                },
            },
        }));
    };


    const fetchDocuments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceDataID}/documents`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}` // Assuming getAccessToken is defined somewhere
                }
            });
            setDocuments(response.data.documents);
        } catch (error) {
            console.error('Failed to fetch documents:', error);
            // Handle error as needed
        }
    };


    useEffect(() => {
        fetchDocuments();

        // Set selectedDocument based on docId from localVariable
        if (localVariable?.aux?.document_id) {
            const selectedDoc = documents.find(
                (doc) => doc.id === localVariable.aux.document_id
            );
            if (selectedDoc) setSelectedDocument(selectedDoc);
        }

    }, [isOpen, localVariable]);


    const updateVariables = async (vars) => {
        try {
           
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${suiteData._id}/variables`,
                {
                    variables: vars,
                }, {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    },
                });
        } catch (error) {
            console.error("Error updating the variables", error);
        }
    };


    const handleSave = async () => {
        // Update the variable in both the variables object and suiteData
        const newVariables = {
            ...variables,
            [variableName]: localVariable
        };

        setVariables(newVariables);
        setSuiteData({
            ...suiteData,
            variables: newVariables
        });

        await updateVariables(newVariables); // Assuming updateVariables is async
        setIsOpen(false); // Close the modal
    };

// sidetail

    return (
        <>
            <Button size={'sm'} onClick={() => setIsOpen(true)}>
                Configure
            </Button>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={'3xl'}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Configure + Test Retrieval</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <HStack align={'start'}>
                            <Box width="33%">
                                <FormControl>
                                    <FormLabel>Select a Document</FormLabel>
                                    <Menu>
                                        <MenuButton as={Button} size="sm" w={'100%'}>
                                            {selectedDocument ? selectedDocument.name : "Select Document"}
                                        </MenuButton>
                                        <MenuList>
                                            {documents.map((doc) => (
                                                <MenuItem key={doc.id} onClick={() => handleDocumentSelection(doc)}>
                                                    <VStack align={'start'}>
                                                        <Text>{doc.name} </Text>
                                                        <Tag size={'xs'} fontSize={'xs'} p={1}>{doc.config} </Tag>
                                                        {/*<Tag size={'xs'} fontSize={'xs'} p={1}>{doc.db_details.type} </Tag>*/}
                                                    </VStack>
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                </FormControl>
                                {selectedDocument?.db_details?.type === "pinecone" && (
                                    <FormControl mt={4}>
                                        <FormLabel>top_k value</FormLabel>
                                        <Input
                                            type="number"
                                            value={localVariable?.aux?.params?.top_k || 0}
                                            onChange={handleTopKChange}
                                            size={'sm'}
                                            borderRadius={'md'}
                                        />

                                    </FormControl>
                                )}
                            </Box>

                            <Box width="66%" marginLeft={4}>
                                <FormControl>
                                    <FormLabel>Query String</FormLabel>
                                    <Input
                                        type="text"
                                        size={'sm'}
                                        borderRadius={'md'}
                                        // Add a state variable to control the value of this input
                                        value={queryString}
                                        onChange={(e) => setQueryString(e.target.value)}
                                    />
                                </FormControl>
                                <Button mt={4} onClick={handleSendQuery}>Send</Button>

                                <FormControl mt={4}>
                                    <FormLabel>Retrieval Results</FormLabel>
                                    {/*<Textarea*/}
                                    {/*    // Add a state variable to control the value of this textarea*/}
                                    {/*    value={retrievalResults}*/}
                                    {/*    size={'sm'}*/}
                                    {/*    borderRadius={'md'}*/}
                                    {/*    readOnly*/}
                                    {/*    resize={'none'}*/}
                                    {/*/>*/}
                                    <div
                                        style={{position: 'relative', width: '100%', marginTop: '4'}}
                                        onMouseOver={() => document.getElementById("expandButton").style.visibility = 'visible'}
                                        onMouseOut={() => document.getElementById("expandButton").style.visibility = 'hidden'}
                                    >
                                        <Textarea
                                            value={retrievalResults}
                                            size={'sm'}
                                            borderRadius={'md'}
                                            readOnly
                                            resize={'none'}
                                        />
                                        <Button
                                            id="expandButton"
                                            style={{position: 'absolute', top: '0', right: '0', visibility: 'hidden'}}
                                            onClick={onOpenLargePreview}
                                            size="xs"
                                        >
                                            Expand
                                        </Button>
                                    </div>

                                </FormControl>
                                <Modal isOpen={isLargePreviewOpen} onClose={onCloseLargePreview} size={'2xl'}>
                                    <ModalOverlay/>
                                    <ModalContent>
                                        <ModalHeader>Retrieval Results</ModalHeader>
                                        <ModalCloseButton/>
                                        <ModalBody>
                                            <Textarea value={retrievalResults} size={'md'} borderRadius={'md'} readOnly
                                                      resize={'none'} h={'800px'}/>
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>
                            </Box>
                        </HStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleSave}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default RetrievalConfig;
