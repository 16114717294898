//! Objgen.js
//! version : 4.0.4
//! authors : Jim Winfield, Objgen.js contributors
//! license : AGPL-3.0
//! objgen.com
import is from 'is_js'

var Objgen = function() {};

Objgen.rawLineRegx = new RegExp('^.*$|\n|$', 'g');
Objgen.newLineRegx = new RegExp('\n');
Objgen.spacesRegx = new RegExp('\\s');
Objgen.arrayRegx = new RegExp('\\[\\s*?([0-9]{1,100})?\\s*?\\]');
Objgen.typesRegx = new RegExp('^(\\w+)(\\s+)(\\w+).*?$');

function isDefined(variable) {
    return typeof(variable) !== 'undefined' && variable !== null;
}

Objgen.getTypeAndName = function(prop) {
    let type = 'string';
    let description = prop;
    if (prop.charAt(0) === '#') {
      type = 'number';
      description = prop.slice(1);
    } else if (prop.charAt(0) === '!') {
      type = 'boolean';
      description = prop.slice(1);
    }
    return { type, description };
  };

Objgen.parseLines = function(val, options, callback) {
    if(callback === null) {
      return;
    }

    var curOffset = 0;
    var lastOffset = 0;
    var currentLevel = 1;
    var lines = 0;

    // parse the raw inbound lines, to find individual input lines
    val.replace(Objgen.rawLineRegx, function(p1, curOffset) {
      if(curOffset > lastOffset) {
        // get the current "raw" line
        var raw = val.substring(lastOffset, curOffset).replace(Objgen.newLineRegx, '');

        if(lines > 0) {
          // glean current level from leading whitespace (tabs or spaces)
          var spaces = 0;
          var level = 1;

          for(var i = 0; i < raw.length; i++) {
            var c = raw.charAt(i);
            if(c === '\t') {
              level++;
              spaces = 0;
            } else if (c === ' ') {
              spaces++;
            } else {
              break;
            }

            if(spaces === options.numSpaces) {
              level++;
              spaces = 0;
            }
          }
          currentLevel = level;
        } else {
          currentLevel = 1;
        }

        // count and process lines with real values
        var lineText = raw.replace(Objgen.spacesRegx, '');
        if(lineText.length > 0) {
          lines++;
        }

        if(lineText.length > 0) {
          callback(raw.trim(), currentLevel);
        }

        // update progress parsing the inbound value
        lastOffset = curOffset;
      }
    });
  };

Objgen.newValueOfType = function(dataType, initialVal) {
  if(initialVal === null) {
    initialVal = {};
  }

  var val = initialVal;
  var t = dataType.charAt(0);

  if(t === 's') {
    if(typeof val !== 'string') {
      val = '';
    }
  } else if(t === 'b') {
    val = initialVal === 'true' ? true : false;
  } else if(t === 'd') {
    var dt = new Date();
    if(initialVal.length > 0) {
      dt.setTime(Date.parse(initialVal));
    }
    val = dt;
  } else if(t === 'n') {
    var num;
    if(initialVal.length > 0) {
      num = parseFloat(initialVal);
      if(isNaN(num)) {
        num = parseInt(initialVal);
        if(!isNaN(num)) {
           num = 0;
        }
      }
    } else {
      num = 0;
    }
    val = num;
  } else {
    val = {};
  }

  return val;
};

Objgen.newArrayOfType = function(dataType, initialVal) {
  var a = [];
  if(typeof initialVal === 'string') {
    var vals = initialVal.split(',');
    for(var i in vals) {
      var val = vals[i].trim();
      a[i] = Objgen.newValueOfType(dataType, val);
    }
  }

  return a;
};

  Objgen.xJson = function(val, options) {
    var propStack = [];
    var arrays = {};
    var model = {};
    var genRoot = {};
    var keysep = '__keysep__';

    if(!isDefined(options)) {
      options = {
        numSpaces: 2
      };
    } else if(!isDefined(options.numSpaces)) {
      options.numSpaces = 2;
    }

    // parse the raw inbound lines, to find individual input lines
    Objgen.parseLines(val, options, function(line, depth) {
      if(line.match('^\s+$/|^\/$|^\/\/|^\s.*\/$|^\s.*\/\/') !== null) {
        return '';
      }

      while(depth != propStack.length) {
        if(depth > propStack.length) {
          propStack.push('');
        } else {
          propStack.pop();
        }
      }

      // Determine the data type being defined at this line level
      var level = depth - 1;
      var arrayInfo = Objgen.arrayRegx.exec(line);
      var isArray = arrayInfo !== null ? arrayInfo.length > 0 : false;
      var arrayIndex = -1;
      var rootArray = false;

      if(isArray === true) {
        if(level === 0 && line.match(/^\s*?\[.*?$/)) {
          rootArray = true;
        }

        if(arrayInfo.length > 1) {
          arrayIndex = parseInt(arrayInfo[1]);
        }
      }

      var type = null;

      if(rootArray === true) {
        type = 'object';
      } else {
        var typeSearch = Objgen.typesRegx.exec(line.replace(Objgen.arrayRegx, ''));
        if(typeSearch !== null && typeSearch.length >= 4) {
          type = typeSearch[3].toLowerCase();
        }
      }

      // Determine if there is a value to assign
      var initialVal = null;

      var eqs = line.indexOf('=');
      if(eqs !== -1) {
        initialVal = line.substr(eqs + 1);
        if(initialVal.length > 0) {
          initialVal = initialVal.trim();
          if(type === null) {
            type = 's';
          }
        }
        line = line.substr(0, eqs);
      } else {
        // Create a default initial value
        if(isArray === true) {
          if(arrayIndex > 0) {
            initialVal = {};
          } else {
            initialVal = [];
          }
        } else {
          initialVal = {};
        }
      }

      // Find the JSON property the line is defining
      var prop = line;

      if(type !== null) {
        if(isArray === true) {
          initialVal = Objgen.newArrayOfType(type, initialVal);
        } else {
          initialVal = Objgen.newValueOfType(type, initialVal);
        }

        // remove type info from model line
        var propNameRegx = new RegExp('(^[^\\s]+)');
        var rx = propNameRegx.exec(prop);
        if(rx !== null) {
          prop = propNameRegx.exec(prop)[0];
        }
      }

      // clean up prop name
      prop = prop.replace(Objgen.spacesRegx, '');
      prop = rootArray ? '[]' : prop.replace(Objgen.arrayRegx, '');
      prop = prop.replace(/\[.*|]/g, '');
      propStack[level] = prop;

      // derive prop type key
      var propKey = '';
      for(var k = 0; k < propStack.length; k++) {
        if(k > 0) {
          propKey += keysep;
        }
        propKey += propStack[k];
      }

      if(isArray === true) {
        var arr = arrays.hasOwnProperty(propKey) ? arrays[propKey] : { name: prop, length: 0 };

        if(arrayIndex < 0 || isNaN(arrayIndex)) {
          arrayIndex = arr.length;
        }

        arr.length += 1;
        arrays[propKey] = arr;

        var idx = ':' + arrayIndex;
        propKey += idx;
        propStack[level] += idx;
      }

      // represent model information
      if(!isDefined(model[propKey])) {
        if(rootArray === true && arrayIndex === 0) {
          genRoot = initialVal;
        }

        var parentKey = propKey.substring(0, propKey.lastIndexOf(keysep));
        model[propKey] = {
            name: prop,
            type: type,
            array: isArray,
            index: arrayIndex > -1 ? arrayIndex : null,
            modelParent: parentKey.length > 0 ? model[parentKey] : null,
            genParent: genRoot,
            val: initialVal
        };
      }

      // Add the current value into the generated object
      var curProp = model[propKey];
      var modelParent = curProp.modelParent;
      if(modelParent !== null) {
        if(modelParent.array) {
          curProp.genParent = modelParent.genParent[modelParent.name];
          if(!isDefined(curProp.genParent)) {
            curProp.genParent = [];
          }
          if((curProp.genParent.length < modelParent.index + 1) ||
            (is.array(curProp.genParent[modelParent.index]) && is.empty(curProp.genParent[modelParent.index]))) {
            curProp.genParent[modelParent.index] = {};
          }
          curProp.genParent = curProp.genParent[modelParent.index];
        } else {
          curProp.genParent = modelParent.genParent[modelParent.name];
        }
      }

      if(rootArray && level === 0 && arrayIndex > 0) {
        genRoot[arrayIndex] = {};
      }

      if(curProp.array && arrayIndex > 0) {
        if(!isDefined(curProp.genParent[prop])) {
          curProp.genParent[prop] = [];
          curProp.genParent[prop][0] = curProp.val;
        } else {
          curProp.genParent[prop][arrayIndex] = curProp.val;
        }
      } else {
        curProp.genParent[prop] = curProp.val;
      }
    });

    return JSON.stringify(genRoot, undefined, options.numSpaces);
  };

export default Objgen;
  


