import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    VStack,
    Box,
    Text, SimpleGrid, Tooltip
} from '@chakra-ui/react';
import DocumentCard from "./Document-Retrieval/DocumentCard";
import {useUtilityFunctions} from "../UtilityFunctions";
import {Search2Icon} from "@chakra-ui/icons";
import AddDocumentModal from "./Document-Retrieval/AddDocumentModal";
import * as PropTypes from "prop-types";
import DocumentMenu from "./Document-Retrieval/DocumentMenu";
import CustomModelsMenu from "./Custom-Models/CustomModelsMenu";


const IntegrationsModal = ({workspaceData, setWorkspaceData}) => {
    const [documents, setDocuments] = useState([]);
    const [customModels, setCustomModels] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const {getAccessToken, getIsFreeUser} = useUtilityFunctions();
    const isPlayground = sessionStorage.getItem('state') === 'playground';
    const isFreeUser = getIsFreeUser()

    const fetchDocuments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceData._id}/documents`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceData._id)}` // Assuming getAccessToken is defined somewhere
                }
            });
            setDocuments(response.data.documents);
            setWorkspaceData(prevState => ({
                ...prevState,
                aux: {
                    ...prevState.aux,
                    documents: response.data.documents
                }
            }));
        } catch (error) {
            console.error('Failed to fetch documents:', error);
            // Handle error as needed
        }
    };

    const fetchCustomModels = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceData._id}/models`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceData._id)}` // Assuming getAccessToken is defined somewhere
                }
            });
            setCustomModels(response.data.models);
            setWorkspaceData(prevState => ({
                ...prevState,
                aux: {
                    ...prevState.aux,
                    models: response.data.models
                }
            }));
        } catch (error) {
            console.error('Failed to fetch models:', error);
            // Handle error as needed
        }
    }

    useEffect(() => {
        fetchDocuments();
        fetchCustomModels();
    }, [isOpen]);


    useEffect(() => {
        setWorkspaceData(prevState => ({
            ...prevState,
            aux: {
                ...prevState.aux,
                models: customModels
            }
        }));
    }, [customModels]);


    return (
        <>
            <Tooltip w="100%" label={(isPlayground) ? "Document retrieval is a premium feature" : ""} placement="right">
                <Box w="100%">
                    <Button w="100%" onClick={() => setIsOpen(true)} variant={'solid'} colorScheme={'telegram'}
                            leftIcon={<Search2Icon/>} isDisabled={isPlayground}>
                        Integrations
                    </Button>
                </Box>
            </Tooltip>


            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={'3xl'}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Integrations</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <DocumentMenu workspaceData={workspaceData} setWorkspaceData={setWorkspaceData} isOpen={isOpen}
                                      getAccessToken={getAccessToken()} documents={documents}
                                      setDocuments={setDocuments}/>
                        <CustomModelsMenu workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}
                                          customModels={customModels} setCustomModels={setCustomModels}/>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default IntegrationsModal;