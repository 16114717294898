import {
    Box,
    Button,
    Tooltip,
    Text,
    VStack,
    SimpleGrid,
    Tag,
    Code,
    Modal,
    ModalOverlay,
    ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure
} from '@chakra-ui/react';
import {CopyIcon} from '@chakra-ui/icons';
import axios from "axios";
import {useUtilityFunctions} from "../../UtilityFunctions";

const DocumentCard = ({document, workspaceId, updateDocuments }) => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {getAccessToken} = useUtilityFunctions();

    const handleCopyApiKey = () => {
        if (document.config.startsWith('chatter')) return;

        navigator.clipboard.writeText(document.db_details.api_key);
    };

    const deleteDocument = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceId}/documents/${document.id}`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceId)}` // Assuming getAccessToken is defined somewhere
                }
            });

            if (response.data.msg === "Document deleted") {
                console.log('Document deleted successfully');
                updateDocuments(document.id);
                onClose();
            }
        } catch (error) {
            console.error('Failed to delete document:', error);
            // Handle error as needed
        }
    };


    return (
        <Box borderWidth="1px" borderRadius="lg" padding="4">
            <VStack spacing={2} align={'start'}>
                <Text fontWeight="bold">{document.name}</Text>
                {document.config && <Tag size="sm">{document.config}</Tag>}
                {document.db_details.type && <Tag size="sm">{document.db_details.type}</Tag>}
                {!document.config.startsWith('chatter') && (
                    <>
                        <Text>Environment: <Code>{document.db_details.environment}</Code></Text>
                        <Text>Namespace: <Code>{document.db_details.namespace}</Code></Text>
                        <Text>DB Link: <Code>{document.db_details.db_link}</Code></Text>
                        <Tooltip label={document.db_details.api_key} aria-label="API Key">
                            <Button size={'sm'} onClick={handleCopyApiKey} leftIcon={<CopyIcon/>}>
                                Copy API Key
                            </Button>
                        </Tooltip>
                    </>
                )}
                <Text>Dimension: <Code>{document.db_details.dimension}</Code></Text>
                <Text>Index: <Code>{document.db_details.index}</Code></Text>
                <Text>Embedding Model: <Code>{document.db_details.embedding_model}</Code></Text>
            </VStack>

            <Button mt={2} size={'sm'} onClick={onOpen}>Delete</Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Warning</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Text>Are you sure you want to delete this document?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" onClick={deleteDocument}>Delete</Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default DocumentCard;
