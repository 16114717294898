import {Box, Text, VStack, Select, MenuList, MenuButton, Menu, MenuItem, Button} from '@chakra-ui/react';
import {useEffect, useState} from "react";
import CellCodeBlock from "./CellBlock";
import {ChevronDownIcon} from "@chakra-ui/icons";
import axios from "axios";

const CodePanel = ({chainName, chainID, cells}) => {
    const [language, setLanguage] = useState({lang: 'python', type: 'library'});
    const [modelData, setModelData] = useState({});

    const handleLanguageChange = (value) => {
        const selectedValue = value.split("-");
        setLanguage({lang: selectedValue[0], type: selectedValue[1]});
    };

    const fetchModelData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/model`, {});

            setModelData(response.data);
        } catch (error) {
            console.error('Failed to fetch cells:', error);
        }
    }

    useEffect(() => {
        fetchModelData();
    }, []);


    return (
        <Box>
            <Menu>
                <MenuButton mb={4} as={Button} rightIcon={<ChevronDownIcon />} size={'sm'}>
                    {language.lang.charAt(0) + language.lang.slice(1)}
                    {language.type && ` - ${language.type.charAt(0) + language.type.slice(1)}`}
                </MenuButton>
                <MenuList>
                    <MenuItem value="python-library" onClick={() => handleLanguageChange("python-library")}>python - library</MenuItem>
                    <MenuItem value="python-requests" onClick={() => handleLanguageChange("python-requests")}>python - requests</MenuItem>
                    <MenuItem value="cURL-" onClick={() => handleLanguageChange("cURL-")}>cURL</MenuItem>
                </MenuList>
            </Menu>

            <VStack align="start" spacing={4}>
                {cells.map(cell =>
                    <CellCodeBlock key={cell._id} nameIn={cell.name} configIn={cell.config} modelData={modelData} language={language}/>
                )}
            </VStack>
        </Box>
    );
}

export default CodePanel;
