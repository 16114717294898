import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, CircularProgress, Divider, Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader, PopoverTrigger, Progress, Spinner, Table, Tag, Tbody, Td, Th, Thead, Tr, useDisclosure,
    useToast
} from '@chakra-ui/react';
import axios from "axios";
import {useUtilityFunctions} from "../UtilityFunctions";
import {PlayIcon} from "evergreen-ui";

const StreamRunSuiteButton = ({
                                  setChainIsRunning, handleIconClick,
                                  workspaceDataID, chainID, suiteData,
                                  setCurrRunState, setSuiteData, chainIsRunning,
                                  cells
                              }) => {

    const toast = useToast();
    const {getAccessToken} = useUtilityFunctions();
    const [progress, setProgress] = useState(null);
    let historyObj = "";


    async function handleLastMessage(message) {
        const history = message;

        const chain_stats = {
            timestamp: history.timestamp,
            stats: history.stats
        };

        const test_results = history.tests.map((test, index) => {
            const test_stats = {
                timestamp: history.timestamp,
                stats: test.stats
            };

            const cell_results = test.cell_results.map((cell_result, index) => ({
                cell_id: cells[index]._id,
                timestamp: history.timestamp,
                timestampIsStandout: false,
                result: cell_result.result,
                usage: cell_result.usage,
                evals: cell_result.evals,
                passed: cell_result.passed
            }));

            return {
                testID: test.id,
                test_stats,
                cell_results
            };
        });

        setCurrRunState({chain_stats, test_results});
        let updatedSuiteData = {
            ...suiteData,
            state: {chain_stats, test_results}
        };
        setSuiteData(updatedSuiteData)


        try {
            const newState = {
                chain_stats: chain_stats,
                test_results: test_results
            };

            const runStatePutResponse = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/suites/${suiteData._id}/state`, newState, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
            });


        } catch (error) {
            console.error('Failed to update the current run state:', error);

            setChainIsRunning(false);
        }

    }

    function getJson(sseData) {
        // Split by double newline and filter out any empty strings in case of extra newlines
        const messages = sseData.split('\n\n').filter(msg => msg.trim() !== '');

        // Initialize a variable to store the last valid JSON message
        let lastValidJson = null;

        messages.forEach(message => {
            if (message.startsWith('data:')) {
                const jsonData = message.replace(/^data: /, '').trim();
                try {
                    // Parse and store the last valid JSON message
                    lastValidJson = JSON.parse(jsonData);
                } catch (error) {
                    console.error('Error parsing JSON message:', jsonData, error);
                }
            } else if (message.startsWith('hist:')) {
                const jsonData = message.replace(/^hist: /, '').trim();
                historyObj = jsonData;
            } else {
                historyObj = historyObj + message;
            }
        });

        // Return the last valid JSON message or null
        return lastValidJson;
    }


    const runStreamChainOnSuite = async () => {
        try {
            setChainIsRunning(true);
            handleIconClick('TestingPanel');

            // update workspace, get vault ID
            let updatedWorkspace = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/byID/${workspaceDataID}`,
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    },
                });
            if (!updatedWorkspace.data.aux.vault_id) {
                toast({
                    title: "No vault found",
                    description: "Please choose an API key vault before running",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                setChainIsRunning(false);
                return;
            }
            const updatedWorkspaceVault = updatedWorkspace.data.aux.vault_id

            const apiUrl = `${process.env.REACT_APP_ROUTE_PREFIX}/api/chain/${chainID}/run/suite`;
            const inputInfo = {
                vault_id: updatedWorkspaceVault,
                stream: true
            };

            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
                body: JSON.stringify(inputInfo)
            }).then(response => {
                const reader = response.body.getReader();
                const stream = new ReadableStream({
                    start(controller) {
                        function push() {
                            reader.read().then(({done, value}) => {
                                if (done) {
                                    try {
                                        const history = JSON.parse(historyObj);
                                        handleLastMessage(history);

                                    } catch (error) {
                                        console.error('Error parsing JSON message:', historyObj, error);
                                    }
                                    setChainIsRunning(false);
                                    setProgress(null);
                                    historyObj = "";
                                    controller.close();
                                    return;
                                }
                                const chunk = new TextDecoder().decode(value);
                                const data = chunk.trim();

                                if (data) {
                                    try {
                                        const valid_json = getJson(data);

                                        if (valid_json) {
                                            if (valid_json.hasOwnProperty('progress') && valid_json.hasOwnProperty('stats')) {
                                                setProgress(valid_json);
                                            }
                                        }
                                    } catch (error) {
                                        console.error('Error parsing JSON message:', data, error);
                                    }

                                }
                                controller.enqueue(value);
                                push();
                            }).catch(error => {
                                console.error('Error while reading the stream', error);
                                controller.error(error);
                            });
                        }

                        push();
                    }
                });

                return new Response(stream);
            }).catch(error => {
                console.error('Fetch error:', error);
            });

        } catch (error) {
            console.error('Failed to run suite on chain:', error);
            toast({
                title: "There was a problem.",
                description: "Please make sure your model is selected and your vault has correct keys for the selected models.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setChainIsRunning(false);
        }
    };

    const {isOpen, onToggle, onClose} = useDisclosure();
    const [buttonClicked, setButtonClicked] = useState(false);

    const handleButtonClick = () => {
        if (chainIsRunning) {
            onToggle();
            setButtonClicked(true);
        } else {
            runStreamChainOnSuite();
        }
    };

    const closePopover = () => {
        onClose();
        setButtonClicked(false);
    };

    return (
        <Popover
            isOpen={chainIsRunning && buttonClicked && isOpen}
            onClose={closePopover}
            placement="right"
        >
            <PopoverTrigger>
                <Button
                    leftIcon={
                        chainIsRunning ? (
                                progress && progress.stats ? (
                                    <CircularProgress
                                        trackColor="gray.400"
                                        size="20px"
                                        thickness="18px"
                                        value={progress.stats.complete_percent + progress.stats.running_percent * 0.5}
                                    />
                                ) : (
                                    <Spinner size="xs"/>
                                )
                            )
                            : (
                                <PlayIcon boxSize="3" color="success"/>
                            )
                    }
                    variant="solid"
                    justifyContent="center"
                    fontSize="sm"
                    fontWeight="normal"
                    onClick={handleButtonClick}
                    disabled={chainIsRunning}
                    size={'sm'}
                >
                    {chainIsRunning ? 'Running...' : 'Run Suite'}
                </Button>
            </PopoverTrigger>
            {chainIsRunning && ( // Renders the Popover content only if chainIsRunning is true
                <PopoverContent h={'400px'} w={'400px'} mt={10} style={{overflowX: 'auto', overflowY: 'auto'}}>
                    <PopoverHeader>
                        <Tag size={'lg'}>Progress Tracker</Tag>
                    </PopoverHeader>
                    <PopoverBody>
                        {progress && progress.stats ? (
                            <>
                                <Box mb={4}>
                                    <Tag mb={2}
                                         colorScheme={'green'}>Complete {progress.stats.complete_percent.toFixed(2)}%</Tag>
                                    <Progress
                                        colorScheme="green"
                                        size="sm"
                                        value={progress.stats.complete_percent}
                                        mb={2}
                                    />
                                </Box>
                                <Box mb={4}>
                                    <Tag mb={2}
                                         colorScheme={'teal'}>Running {progress.stats.running_percent.toFixed(2)}%</Tag>
                                    <Progress
                                        colorScheme="teal"
                                        size="sm"
                                        value={progress.stats.running_percent}
                                        mb={2}
                                    />
                                </Box>
                                <Box mb={4}>
                                    <Tag mb={2} colorScheme={'red'}>Not
                                        Started {progress.stats.not_started_percent.toFixed(2)}%
                                    </Tag>
                                    <Progress
                                        colorScheme="red"
                                        size="sm"
                                        value={progress.stats.not_started_percent}
                                        mb={2}
                                    />
                                </Box>
                            </>
                        ) : (
                            <div>No progress data available.</div>
                        )}
                        {progress && progress.progress ? (
                            <Table variant="simple" size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>Test Name</Th>
                                        <Th>Status</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {Object.entries(progress.progress).map(([testId, testInfo]) => (
                                        <Tr key={testId}>
                                            <Td>{testInfo.name}</Td>
                                            <Td>{testInfo.status}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <div>No process data available.</div>
                        )}
                    </PopoverBody>
                </PopoverContent>
            )}
        </Popover>
    );
};

export default StreamRunSuiteButton;
