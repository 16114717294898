import {useEffect, useState} from 'react';
import {Input, FormControl, FormLabel, Text, HStack} from '@chakra-ui/react';

const AzureForm = ({setAttributes, setModelFamilyName, setIsValid}) => {
    const [azureData, setAzureData] = useState({
        endpoint: '',
        key: '',
        api_version: '',
    });

    const [name, setName] = useState("");

    const handleChange = (e, field) => {
        setAzureData({...azureData, [field]: e.target.value});
    };

    // Push changes to parent
    useEffect(() => {
        setAttributes(azureData);
    }, [azureData]);

    useEffect(() => {
        setModelFamilyName("azure-" + name);
    }, [name]);

    useEffect(() => {
        if (azureData.endpoint && azureData.key && azureData.api_version && name) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [azureData, name]);


    return (
        <>
            <FormControl mt={4}>
                <FormLabel>{"Custom Model Family Name"}</FormLabel>
                <HStack spacing={0}>
                    <Text fontWeight={'semibold'}>azure-</Text>
                    <Input
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        isRequired
                    />
                </HStack>
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>Endpoint</FormLabel>
                <Input placeholder="Endpoint" value={azureData.endpoint} onChange={(e) => handleChange(e, 'endpoint')}
                       isRequired/>
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>Key</FormLabel>
                <Input type="password" placeholder="Key" value={azureData.key} onChange={(e) => handleChange(e, 'key')} isRequired/>
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>API Version</FormLabel>
                <Text>Current: 2023-05-15</Text>
                <Input placeholder="API Version" value={azureData.api_version}
                       onChange={(e) => handleChange(e, 'api_version')} isRequired/>
            </FormControl>
        </>
    );
};

export default AzureForm;
