import React from 'react';
import {useEffect} from 'react';
import {Tabs, TabList, Tab, TabPanels, TabPanel, Flex, Text, CloseButton, Box} from '@chakra-ui/react';
import ChainSpace from '../Chain/ChainSpace'
import axios from 'axios';
import {useUtilityFunctions} from "../UtilityFunctions";

function WorkspaceTabList({
                              allChainTabs,
                              selectedChain,
                              setSelectedChain,
                              setAllChainTabs,
                              workspaceData,
                              userData,
                              setUserData
                          }) {
    const selectedChainIndex = allChainTabs.findIndex(chain => chain.id === selectedChain.id);
    const {getAccessToken} = useUtilityFunctions();

    console.log("ALL CHAIN TABS IS ", selectedChain)

    useEffect(() => {
        // Watch for changes in allChainTabs and save the state whenever it changes
        saveChainsStateToBackend();
    }, [allChainTabs, selectedChain]);

    const saveChainsStateToBackend = async () => {

        if (!allChainTabs.length || !selectedChain || !selectedChain.id || !selectedChain.name) {
            return;
        }


        const workspaceID = workspaceData._id;
        const chainsState = allChainTabs.map(chain => ({
            id: chain.id,
            name: chain.name
        }));

        const currChainOpen = {
            id: selectedChain.id,
            name: selectedChain.name
        };


        try {
            // Update the current open chains
            await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceID}/state/current-open-chains`, chainsState, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceID)}`
                }
            });

            // Update the current open chain
            await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceID}/state/current-open-chain`, currChainOpen, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceID)}`
                }
            });

        } catch (error) {
            console.error('Error saving chains state:', error);
        }
    };

    return (
        allChainTabs.length === 0 ? (
            <Flex
                height="100%"
                width="100%"
                alignItems="center"
                justifyContent="center"
                backgroundColor="white"
            >
                <Text fontSize="5xl" color="black" mb={"20vh"}>
                    Select or add a chain
                </Text>
            </Flex>
        ) : (

        <Tabs
            index={selectedChainIndex}
            onChange={(index) => setSelectedChain(allChainTabs[index])}
            overflowY={"hidden"}
            sx={{
                height: "100%",
                ".chakra-tabs__tab": {
                    borderColor: "transparent",
                    mb: "-1px",
                    _selected: {
                        color: 'black',
                        borderTop: "2px solid #ED7547",
                        borderLeft: "1px solid #F3F3F3",
                        borderRight: "1px solid #F3F3F3",
                        borderBottom: "none",
                    },
                    _hover: {
                        bg: "#F2F2F2",
                        ".close-button": {
                            visibility: "visible",
                        }
                    },
                    _focus: {
                        boxShadow: "none",
                    },
                },
            }}
        >
            <TabList overflowX={"auto"} borderBottom={"1px solid #ededed"}>
                {allChainTabs.map((chain, index) => (
                    <Tab key={chain.id}>
                        <Flex alignItems="center" justifyContent="space-between">
                            <Text isTruncated>{chain.name}</Text>
                            <CloseButton
                                className="close-button"
                                size="sm"
                                ml={'100px'}
                                visibility={selectedChain.id === chain.id ? 'visible' : 'hidden'}
                                onClick={(e) => {
                                    e.stopPropagation(); // prevent tab switch event
                                    const newTabs = allChainTabs.filter((c) => c.id !== chain.id);
                                    setAllChainTabs(newTabs);
                                    if (selectedChain?.id === chain.id) { // if we are closing the currently selected tab
                                        setSelectedChain(newTabs[index] || newTabs[newTabs.length - 1] || null); // set selectedChain to next tab, or last tab, or null if no tabs remain
                                    }
                                }}
                            />
                        </Flex>
                    </Tab>
                ))}
            </TabList>

            <TabPanels sx={{height: "100%", margin: 0, ".chakra-tab__tabpanel": {padding: 0, overflowY: 'hidden'}}}>
                {allChainTabs.map(chain => (
                    <TabPanel key={chain.id} sx={{height: "100%", padding: 0, overflowY: 'hidden'}}>
                        <ChainSpace workspaceData={workspaceData} workspaceDataID={workspaceData._id}
                                    chainName={chain.name} chainID={chain.id}
                                    userData={userData} setUserData={setUserData}
                        />
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
        )
    );
}

export default WorkspaceTabList;
