// FunctionSelectInput.js
import {Select} from "@chakra-ui/react";

const FunctionSelectInput = ({ currAssertion, handleAssertionChange, handleAssertionBlur, workspaceData }) => {


    const handleChange = (e) => {
        // Find the function whose name matches the selected option
        const selectedFunction = workspaceData.aux.functions.find(func => func.function.name === e.target.value);

        // Get the _id of the selected function (or use an empty string if not found)
        const functionId = selectedFunction ? selectedFunction._id : "";

        handleAssertionChange(currAssertion.id, {
            ...currAssertion,
            assert_string: e.target.value,
            assert_string_addl_optional: functionId
        });
    };


    return (
        currAssertion && <Select
            size="sm"
            value={currAssertion.assert_string}
            onChange={handleChange}
            onBlur={handleAssertionBlur}
        >
            <option key={"none"} value={"none"}>{"none"}</option>
            {workspaceData.aux && workspaceData.aux.functions && workspaceData.aux.functions.map((func) => (
                <option key={func._id} value={func.function.name}>{func.function.name}</option>
            ))}
        </Select>
    );
}

export default FunctionSelectInput;