import {Box, Text, IconButton, Input, useToast, Flex, Button} from "@chakra-ui/react";
import {CopyIcon} from "@chakra-ui/icons";

const VaultCard = ({vault, activeVaultId, onSelect, onDelete}) => {
    const toast = useToast();

    const handleCopy = (key) => {
        navigator.clipboard.writeText(key)
            .then(() => {
                toast({
                    title: "Copied to clipboard",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch(() => {
                toast({
                    title: "Failed to copy",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    return (
        <Box borderWidth="2px" borderRadius="lg" padding="6" mt={4}
             borderColor={vault._id === activeVaultId ? "blue.500" : "gray.200"}
        >
            <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="xl" marginBottom="4">{vault.name}</Text>
                <Box>
                <Button
                    size={'sm'}
                    colorScheme={vault._id === activeVaultId ? 'blue' : 'gray'}
                    onClick={() => onSelect(vault._id)}
                >
                    {vault._id === activeVaultId ? 'Active' : 'Activate'}
                </Button>
                </Box>
            </Flex>
            {vault.keys.map((key, index) => (
                <Box key={index} marginBottom="2">
                    <Text>{key.model_family}</Text>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Input mr={2} type="password" value={key.key} isReadOnly/>
                        <IconButton aria-label="Copy key" icon={<CopyIcon/>} onClick={() => handleCopy(key.key)}/>
                    </Flex>
                </Box>
            ))}
            <Button mr={2} variant={'outline'} size={'sm'} colorScheme={'red'} onClick={() => onDelete(vault._id)} disabled={vault._id === activeVaultId}>
                Delete
            </Button>
        </Box>
    );

};

export default VaultCard;
