

export const OpenAIPythonLibrary = (config, indent=2) => {

    // Start building the API call string
    let apiCall = `messages = ${JSON.stringify(config.prompt)}\n\n`;

    // Add the API call
    apiCall += `completion = openai.ChatCompletion.create(\n`;
    apiCall += `${" ".repeat(indent)}model="${config.model}",\n`;
    apiCall += `${" ".repeat(indent)}messages=messages,\n`;

    // Only add functions and function_call if use_functions is true
    if (config?.params?.functions) {
        apiCall += `${" ".repeat(indent)}functions=${JSON.stringify(config.params.functions, null, 2)},\n`;
        // apiCall += `${" ".repeat(indent)}function_call="${config.response_format.function_call}",\n`;
    }

    // Add additional parameters, if any
    if (config.params && Object.keys(config.params).length > 0) {
        const paramString = JSON.stringify(config.params, null, indent).replace(/^{/, '').replace(/}$/, '');
        const indentedParamString = paramString.split("\n").map(line => `${" ".repeat(indent)}${line}`).join("\n");
        apiCall += `${indentedParamString}\n`;
    }

    apiCall += `)`;

    return apiCall;
}


export const OpenAICurl = (config, model_data, indent=2) => {
    let url = model_data["url"];
    let headers = model_data["headers"];
    let data = {
        model: config.model,
        messages: config.prompt
    };

    if (config?.params?.functions) {
        data["functions"] = config.params.functions;
    }

    if (config.params && Object.keys(config.params).length > 0) {
        data = {...data, ...config.params};
    }

    let curlCommand = `curl ${url} \\\n`;

    for (let key in headers) {
        curlCommand += `  -H "${key}: ${headers[key]}" \\\n`;
    }

    curlCommand += `  -d '${JSON.stringify(data, null, indent)}'`;

    return curlCommand;
}


export const OpenAIPythonRequests = (config, model_data, indent=2) => {
    let url = model_data["url"];
    let headers = model_data["headers"];
    let data = {
        model: config.model,
        messages: config.prompt
    };

    if (config?.params?.functions) {
        data["functions"] = config.params.functions;
        // data["function_call"] = config.response_format.function_call;
    }

    if (config.params && Object.keys(config.params).length > 0) {
        data = {...data, ...config.params};
    }

    let importStatement = "import requests\n\n";
    let payload = JSON.stringify(data, null, indent);
    let headersString = JSON.stringify(headers, null, indent);

    let pythonCode = `${importStatement}`;
    pythonCode += `url = "${url}"\n`;
    pythonCode += `payload = ${payload}\n`;
    pythonCode += `headers = ${headersString}\n\n`;
    pythonCode += `response = requests.request("POST", url, headers=headers, data=payload)\n\n`;
    pythonCode += `print(response.text)`;

    return pythonCode;
}


export const AnthropicPythonLibrary = (config, model_data, indent=2) => {
    // Start building the API call string
    let apiCall = `prompt = f"{HUMAN_PROMPT} ${config.prompt} {AI_PROMPT}"\n\n`;

    // Check for max_tokens_to_sample in params
    let params = config["params"] || {};
    let paramsCopy = {...params};

    const default_max_tokens = model_data["context_limit"] || 100000;
    if (!paramsCopy.hasOwnProperty('max_tokens_to_sample')) {
        paramsCopy['max_tokens_to_sample'] = default_max_tokens;
    }


    // Add the API call
    apiCall += `response = anthropic.completions.create(\n`;
    apiCall += `${" ".repeat(indent)}model="${config.model}",\n`;
    apiCall += `${" ".repeat(indent)}prompt=prompt,\n`;

    // Add additional parameters, if any
    if (Object.keys(paramsCopy).length > 0) {
        const paramString = JSON.stringify(paramsCopy, null, indent).replace(/^{/, '').replace(/}$/, '');
        const indentedParamString = paramString.split("\n").map(line => `${" ".repeat(indent)}${line}`).join("\n");
        apiCall += `${indentedParamString}\n`;
    }

    apiCall += `)`;

    return apiCall;
}


export const AnthropicCurl = (config, model_data, indent=2) => {
    let url = model_data["url"];
    let headers = model_data["headers"];
    let prompt = `\\n\\nHuman: ${config.prompt}\\n\\nAssistant:`;

    // Check for max_tokens_to_sample in params
    let params = config.params
    let paramsCopy = {...params};

    const default_max_tokens = model_data["context_limit"] || 100000;
    if (!paramsCopy.hasOwnProperty('max_tokens_to_sample')) {
        paramsCopy['max_tokens_to_sample'] = default_max_tokens;
    }

    let data = {
        model: config.model,
        prompt: prompt,
        ...paramsCopy
    };

    let curlCommand = `curl ${url} \\\n`;

    for (let key in headers) {
        curlCommand += `  -H "${key}: ${headers[key]}" \\\n`;
    }

    curlCommand += `  -d '${JSON.stringify(data, null, indent)}'`;

    return curlCommand;
}

export const AnthropicPythonRequests = (config, model_data, indent=2) => {
    let url = model_data["url"];
    let headers = model_data["headers"];
    let prompt = `{HUMAN_PROMPT} ${config.prompt} {AI_PROMPT}`;

    // Check for max_tokens_to_sample in params
    let params = config.params
    let paramsCopy = {...params};

    const default_max_tokens = model_data["context_limit"] || 100000;
    if (!paramsCopy.hasOwnProperty('max_tokens_to_sample')) {
        paramsCopy['max_tokens_to_sample'] = default_max_tokens;
    }

    let data = {
        model: config.model,
        prompt: prompt,
        ...paramsCopy
    };

    let importStatement = "import requests\n\n";
    let payload = JSON.stringify(data, null, indent);
    let headersString = JSON.stringify(headers, null, indent);

    let pythonCode = `${importStatement}`;
    pythonCode += `url = "${url}"\n`;
    pythonCode += `payload = ${payload}\n`;
    pythonCode += `headers = ${headersString}\n\n`;
    pythonCode += `response = requests.request("POST", url, headers=headers, data=payload)\n\n`;
    pythonCode += `print(response.text)`;

    return pythonCode;
}
