import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Tabs,
    TabList,
    Tab,
    Text,
    TabPanels,
    TabPanel,
    Flex
  } from "@chakra-ui/react";
  import { useState } from 'react';

const TestCaseGenerationModal = ({ isGenerationModalOpen, setIsGenerationModalOpen, addRedTeam, isAddRedTeamLoading }) => {
    const [testAddQty, setTestAddQty] = useState(1);
    const [tabIndex, setTabIndex] = useState(0);
    const testGenTypes = ["General adversarial", "PII", "Bias", "Accessibility"];
    const testGenTypesDescriptions = [
        "General adversarial tests are crafted to rigorously assess the model's behavior under various types of prompt injections: <br><br> <strong>Incorrect Responses:</strong> These tests focus on situations where attackers might manipulate the input prompts to elicit inaccurate responses, structured in ways that could potentially lead the model to ignore or misinterpret the user's intentions. <br><br> <strong>Inappropriate Reponses:</strong> These are attempts to make the model display inappropriate or harmful behavior. ",
        
        "Generate test cases to address the following: <br><br><strong>Adherence:</strong> Evaluate the model’s adherence to privacy and data protection standards. <br><br>" +
        "<strong>Malicious Behavior:</strong> Simulate scenarios where users, intentionally or accidentally, input sensitive information into the model, or attempt to retrieve or manipulate such information.<br><br>",
    
        "Generate test cases to address the following: <br><br><strong>Output Bias:</strong> Ensuring the output text is not explicity biased, racist, sexist, etc. <br><br>" +
        "<strong>Differential treatment:</strong> Involve variations of input prompts to observe differential treatment or outcomes, aiming to uncover any implicit biases embedded in the model.<br><br>",
    
    "Generate test cases to address the following: <br><br><strong>Content Accessibility:</strong> Ensuring the text is easily readable and understandable by individuals with various abilities. <br><br>" +
    "<strong>Inclusive Content:</strong> The outputs should be bias-free, and users should have the ability to customize the content for better understanding.<br><br>" +
    "<strong>Compliance with Standards:</strong> Adhering to established accessibility standards such as the Web Content Accessibility Guidelines (WCAG).<br><br>"
    ];

    return (
        <Modal isOpen={isGenerationModalOpen} onClose={() => setIsGenerationModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Test Case Generation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Flex direction="column" align="center" mb={4}>
                    <Text fontWeight="bold" mb={2}>
                        Quantity
                    </Text>
                    <NumberInput value={testAddQty} onChange={(value) => setTestAddQty(value)} min={1} mb={4}>
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>

                <Flex direction="column" align="center" mb={4}>
                    <Text fontWeight="bold" mb={2}>
                        Type
                    </Text>
                    <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)} w="100%">
                        <TabList>
                            {testGenTypes.map(label => <Tab key={label}>{label}</Tab>)}
                        </TabList>
                        <TabPanels>
                            {testGenTypesDescriptions.map((desc, index) => (
                                <TabPanel key={index}>
                                    <Text dangerouslySetInnerHTML={{ __html: desc }} />
                                </TabPanel>
                            ))}
                        </TabPanels>
                    </Tabs>
                </Flex>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme="blue" mr={3} isLoading={isAddRedTeamLoading} onClick={() => addRedTeam(testAddQty, testGenTypes[tabIndex])}>
                    Add Test(s): {testGenTypes[tabIndex]}
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    );
  };

export default TestCaseGenerationModal;