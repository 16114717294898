import {Input, Textarea} from "@chakra-ui/react";

const StandardEvaluationInput = ({currAssertion, handleAssertionChange, handleAssertionBlur, userName}) => {
    return (
        currAssertion && <Textarea
            placeholder="Evaluation text"
            value={currAssertion.assert_string
            }
            size="sm"
            variant="unstyled"
            onChange={e => handleAssertionChange(currAssertion.id, {
                ...currAssertion,
                assert_string: e.target.value
            })}
            onBlur={e => {
                handleAssertionBlur();
            }}
            resize="vertical"
        />
    );
}

export default StandardEvaluationInput;