import { 
    Modal, ModalOverlay, ModalContent, ModalHeader, 
    ModalBody, ModalCloseButton, Table, Thead, Tbody, 
    Tr, Th, Td, Button, Box, Flex, useToast, Text
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { AddIcon } from "@chakra-ui/icons";

const ChatTestSelectModal = ({ isOpen, onClose, parsedData, onUpload }) => {
    const [selectedRowIndices, setSelectedRowIndices] = useState(new Set());
    const [isAddLoading, setIsAddLoading] = useState(false);
    const toast = useToast();

    const handleRowClick = (index) => {
        const updatedIndices = new Set(selectedRowIndices);
        if (updatedIndices.has(index)) {
            updatedIndices.delete(index);
        } else {
            updatedIndices.add(index);
        }
        setSelectedRowIndices(updatedIndices);
    };

    const handleAddTest =  async () => {
        // if (selectedRowIndices.size === 0) {
        //     toast({
        //         title: "No chat selected.",
        //         description: "Select a chat to import.",
        //         status: "error",
        //         duration: 3000,
        //         isClosable: true,
        //     });
        //     return;
        // }


        setIsAddLoading(true);
        await onUpload(parsedData);
        setIsAddLoading(false)
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Review your import</ModalHeader>
                <Text fontSize="md" color="gray.500" mb={4} mt={-4} ml={6}>You will get a chance to select which messages to test once you import.</Text>
                <ModalCloseButton />
                <ModalBody>
                    <Box maxH="70vh" overflowY="auto">
                        <Table variant="simple" size="sm">
                            <Thead>
                                <Tr>
                                    <Th>Role</Th>
                                    <Th>Content</Th>
                                </Tr>
                            </Thead>
                            <Tbody style={{ overflowY: 'auto' }}>
                            {parsedData.map((item, index) => (
                                <Tr 
                                    key={index}
                                    // bg={selectedRowIndices.has(index) ? "green.200" : ""}
                                    onClick={() => handleRowClick(index)}
                                    _hover={{ cursor: "pointer" }}
                                >
                                    <Td isTruncated>{item.role}</Td>
                                    <Td isTruncated>{item.content}</Td>
                                </Tr>
                            ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Flex mt={4} mb={2} justifyContent="flex-end">
                        <Button 
                            leftIcon={<AddIcon boxSize={3} />} 
                            size={"md"}
                            onClick={handleAddTest}
                            colorScheme="green"
                            isLoading={isAddLoading}
                        >
                            Add chats
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ChatTestSelectModal;
