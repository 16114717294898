import React, { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import PlaygroundIntroModal from './PlaygroundIntroModal'

function PlaygroundHandler({ setIsPlayground, setIsLoggedIn, isLoggedIn }) {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose: close } = useDisclosure();
    const toast = useToast()

    useEffect(() => {
        onOpen();
    }, [onOpen]);

    const handleClose = () => {
        if (!isLoggedIn) {
            navigate("/");
        }
        close();
    }


    const fetchPlaygroundData = async (email_input) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/playground/auth`, {
                email: email_input,
            });
            const responseData = response.data;
            const workspaceId = responseData.workspace_id; 
            navigate(`/workspace/${workspaceId}`);
            sessionStorage.setItem('user_data', JSON.stringify(response.data));
            sessionStorage.setItem('access_token', response.data.access_token);
            sessionStorage.setItem('token_type', response.data.token_type);
            sessionStorage.setItem('state', 'playground');
            setIsPlayground(true);
            setIsLoggedIn(true);
            close();
        } catch (err) {
            toast({
                title: "Error",
                description: "Failed to fetch playground data. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.error(err);
            handleClose();
        }
    };

    return (
        <PlaygroundIntroModal 
            isOpen={isOpen} 
            onClose={handleClose} 
            fetchPlaygroundData={fetchPlaygroundData} 
        />
    );
}

export default memo(PlaygroundHandler);