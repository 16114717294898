import { 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalFooter, 
    ModalBody, 
    ModalCloseButton, 
    Button, 
    Input, 
    Text,
    HStack,
    Box,
    Tooltip,
    Flex
} from "@chakra-ui/react";
import { useState, useEffect } from 'react';
import {CodeIcon} from "evergreen-ui";
import axios from 'axios';
import {useUtilityFunctions} from "../UtilityFunctions";
import { useToast } from "@chakra-ui/react";
import FunctionCallEditorTool from "../Tools/FunctionCallEditor/FunctionCallEditorTool";

const FunctionEditorHandler = ({ workspaceData, setWorkspaceData }) => {

    const [editorIsOpen, setEditorIsOpen] = useState(false)
    const {getAccessToken} = useUtilityFunctions();
    const toast = useToast()

    const handleToolClose = async (functionIDs, textAreas, JSONVersionTexts, switchStates) => {
    
        // Transform the JSONVersionTexts into an array of parsed JSON objects
        const functionsArray = JSONVersionTexts.map((text, index) => {
            let parsedFunction = null;
            try {
                parsedFunction = JSON.parse(text);
            } catch (error) {
                console.error("Error parsing JSON:", error);
                return null;
            }

    
            return {
                function_id: functionIDs[index],
                function: {
                    name: parsedFunction.name,
                    description: parsedFunction.description,
                    parameters: parsedFunction.parameters
                },
                plain_text: textAreas[index],
                use_raw: switchStates[index]
            };
        });

    
        try {
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/update-functions`, {
                workspace_id: workspaceData._id, 
                functions: functionsArray
            }, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceData._id)}` 
                },
            });

            if (response.data && response.data.msg) {
                // Extract the updated functions from the response
                const updatedFunctions = response.data.functions;
                if (updatedFunctions) {
                    let newData = {
                        ...workspaceData,
                        aux: {
                            ...workspaceData.aux,
                            // Update the functions with the new `_id` fields
                            functions: updatedFunctions
                        }
                    };
                    
                    setWorkspaceData(newData);
                    
                }
            } else {
                toast({
                    title: "Failed to update functions",
                    description: "Please make sure your functions have valid syntax and try again.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Error updating functions:", error);
            // Handle error, for example, show a toast
            toast({
                title: "Failed to update functions",
                description: "Please make sure your functions have valid syntax and try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };


    return (
        <>
                    <Box w="100%">  
                        <Button w="100%" variant={'solid'} colorScheme={'teal'}
                                leftIcon={<CodeIcon/>} mb={2} onClick={() => { setEditorIsOpen(true) }}>
                            Functions
                        </Button>
                    </Box>
                    {(editorIsOpen && workspaceData && workspaceData.aux && <FunctionCallEditorTool existingFunctions={workspaceData.aux.functions} onClose={handleToolClose} isInternal={true} setEditorIsOpen={setEditorIsOpen} /> )}
        </>
    )
}

export default FunctionEditorHandler;
