import {usePlayground} from "./Playground/PlaygroundContext";

export const useUtilityFunctions = () => {
    const isPlayground = usePlayground();

    function isColorDark(hexColor) {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return yiq < 128; // Return true if YIQ is less than 128 and false otherwise
    }

    const extractNumberFromVarName = (key) => {
        const match = key.match(/\d+/); // This regex matches the first sequence of digits in the string
        return match ? parseInt(match[0], 10) : -1; // Convert the matched string to an integer
    };

    const getAccessToken = () => {
        if (isPlayground) {
            return sessionStorage.getItem('access_token');
        }

        return localStorage.getItem('access_token')
    };

    const getUserData = () => {
        if (isPlayground) {
            return sessionStorage.getItem('user_data');
        }
        return localStorage.getItem('user_data')
    };

    const getIsFreeUser = () => {
        try {
            const userData = JSON.parse(getUserData());
            return (userData?.user?.org_id === "free-user");
        } catch (error) {
            console.error("Error parsing user data:", error);
            return true;
        }
    };

    const logoutStorage = () => {
        if (isPlayground) {
            sessionStorage.removeItem('user_data');
            sessionStorage.removeItem('access_token');
            sessionStorage.removeItem('token_type');
            return;
        }
        localStorage.removeItem('user_data');
        localStorage.removeItem('access_token');
        localStorage.removeItem('token_type');
    };

    // const getAssertionTypes = () => {
    //     return [
    //         'note',
    //         'model-gpt-4',
    //         'model-gpt-3.5-turbo',
    //         'model-gpt-3.5-turbo-16k',
    //         'text-contains',
    //         'text-not-in',
    //         'text-equals',
    //         'semantic-ada-v2-cosine',
    //         'semantic-ada-v2-euclidean',
    //         'regex',
    //         'moderation',
    //         'called-function',
    //         'called-router',
    //         'boolean-and',
    //         'boolean-or',
    //     ];
    // };

    const getAssertionTypes = () => {
        return {
            model: [
                'model-gpt-4',
                'model-gpt-3.5-turbo',
                'model-gpt-3.5-turbo-16k'
            ],
            text: [
                'text-contains',
                'text-not-in',
                'text-equals',
                'regex'
            ],
            semantic: [
                'semantic-ada-v2-cosine',
                'semantic-ada-v2-euclidean'
            ],
            moderation: [
                'moderation',
                'contains-sexual-content',
                'contains-hate-speech',
                'contains-harassment',
                'contains-self-harm',
                'contains-underage-content',
                'contains-hate-threatening',
                'contains-violence-graphic',
                'contains-self-harm-intent',
                'contains-self-harm-instructions',
                'contains-harassment-threatening',
                'contains-violence'
            ],
            agentic: [
                'called-function',
                'called-router'
            ],
            logic: [
                'boolean-and',
                'boolean-or'
            ],
            manual: [
                'note',
                'binary-grade'
            ],
        };
    };


    return {
        getAccessToken,
        getUserData,
        logoutStorage,
        isColorDark,
        extractNumberFromVarName,
        getAssertionTypes,
        getIsFreeUser
    };
};
