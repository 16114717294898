import {Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, Button, useToast, Popover, PopoverTrigger, PopoverContent} from "@chakra-ui/react";
import ChainHistoryDetails from "./ChainHistoryDetails";
import axios from 'axios';
import { JoinTableIcon, ComparisonIcon } from 'evergreen-ui';
import {useUtilityFunctions} from "../UtilityFunctions";
import ABComparisonModal from "./ABComparisonModal";
import { useState } from 'react';



const ChainHistories = ({ chainHistories, chainID, workspaceDataID, isChat, isSuite }) => {
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    const [isABModalOpen, setABModalOpen] = useState(false);

    const openABModal = () => {
        setABModalOpen(true);
    };

    const closeABModal = () => {
        setABModalOpen(false);
    };


    const toast = useToast();

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000); // Multiplying by 1000 to convert seconds to milliseconds
        return date.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        });
    };

    const groupByHour = (histories) => {
        const groups = {};
        histories.forEach(history => {
            const date = new Date(history.timestamp * 1000);
            const hourKey = date.toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', hour12: false });
            const displayDate = date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
            if (!groups[hourKey]) {
                groups[hourKey] = {
                    displayDate,
                    histories: []
                };
            }
            groups[hourKey].histories.push(history);
        });
        return groups;
    };

    const groupedHistories = groupByHour(chainHistories);

    const getTimeRange = (histories) => {
        const times = histories.map(history => new Date(history.timestamp * 1000));
        const startTime = times[0].toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        const endTime = times[times.length - 1].toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        return `${endTime} - ${startTime} (${histories.length})`;
    };


    const downloadCSV = (csv, filename) => {
        let csvFile;
        let downloadLink;
      
        // CSV file
        csvFile = new Blob([csv], {type: "text/csv"});
      
        // Download link
        downloadLink = document.createElement("a");
      
        // File name
        downloadLink.download = filename;
      
        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);
      
        // Hide download link
        downloadLink.style.display = "none";
      
        // Add the link to DOM
        document.body.appendChild(downloadLink);
      
        // Click download link
        downloadLink.click();
      };


    function convertToCSV(obj, prefix = '') {
        let row = '';
        let header = '';
        for (let key in obj) {
            let value = obj[key];
            if (typeof value === 'object' && value !== null) {
                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        const csv = convertToCSV(value[i], `${prefix}${key}[${i}].`);
                        header += csv.header;
                        row += csv.row;
                    }
                } else {
                    const csv = convertToCSV(value, `${prefix}${key}.`);
                    header += csv.header;
                    row += csv.row;
                }
            } else {
                header += `"${prefix}${key}",`;
                row += `"${value}",`;
            }
        }
        return { header, row };
    }
    

    function exportToCSV(event, history) {
        event.stopPropagation();

        let csvData = convertToCSV(history);
       
            
        downloadCSV(csvData.header + '\n' + csvData.row, 'export.csv');
    }

    const exportAllChainHistories = async () => {
        try {
            const allHistoriesUrl = isSuite ? 
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/history/chain/${chainID}/suite/all`
                : `${process.env.REACT_APP_ROUTE_PREFIX}/api/history/chain/${chainID}/all`

            const response = await axios.post(
                allHistoriesUrl,
                {}, // no pagination parameters needed
                { headers: { 'Authorization': `Bearer ${getAccessToken(workspaceDataID)}` } }
            );
    
            const allChainHistories = response.data;
    
            if (allChainHistories.length > 0) {
                const allRows = allChainHistories.map(history => {
                    const csvData = convertToCSV(history);
                    return csvData.row;
                });
                const headers = convertToCSV(allChainHistories[0]).header;
                const csvContent = headers + '\n' + allRows.join('\n');
                downloadCSV(csvContent, 'all_chain_histories.csv');
            } else {
                toast({
                    title: "No data to export.",
                    status: "info",
                    duration: 4000,
                    isClosable: true,
                });
            }
    
        } catch (error) {
            console.error('Failed to fetch all chain histories:', error);
        }
    };

    return (
        <div>
            <Button 
                onClick={exportAllChainHistories}
                size="sm"
                borderRadius="sm"
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
                bg={"#ED7547"}
                leftIcon={<JoinTableIcon />}
                href={"#"}
                _hover={{
                    bg: "#D05D32",
                }}
                mr={2}>
                Export all to CSV
            </Button>
            {
                chainHistories.length < 2 ?
                (
                    <Popover placement="right">
                        <PopoverTrigger>
                            <Button leftIcon={<ComparisonIcon />} size="sm" borderRadius="sm" fontSize={"sm"} fontWeight={600} colorScheme="blue" isDisabled>
                                Create A/B comparison
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent zIndex={1000}>
                            <Box p={2}>
                                Must be at least two histories present.
                            </Box>
                        </PopoverContent>
                    </Popover>
                ) : (
                    <Button size="sm" leftIcon={<ComparisonIcon />} borderRadius="sm" fontSize={"sm"} fontWeight={600} colorScheme="blue" onClick={openABModal}>Create A/B comparison</Button>
                )
            }
            <br/>
            <br/>
            <Accordion allowToggle>
                {Object.keys(groupedHistories).map(dateKey => (
                    <AccordionItem key={dateKey}>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                <Heading size="sm">{groupedHistories[dateKey].displayDate}</Heading>
                                <Box fontSize="sm">{getTimeRange(groupedHistories[dateKey].histories)}</Box>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>
                            <Accordion allowToggle>
                                {groupedHistories[dateKey].histories.map(history => (
                                    <AccordionItem key={history._id.$oid} border={'none'}>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                {formatTimestamp(history.timestamp)}
                                            </Box>
                                            <Button 
                                                onClick={(event) => exportToCSV(event, history)}
                                                leftIcon={<JoinTableIcon boxSize="3"/>}
                                                variant="ghost"
                                                justifyContent="center"
                                                fontSize="xs"
                                                size="xs"
                                                fontWeight="normal"
                                                mr={2}>
                                                Export to CSV
                                            </Button>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel>
                                            <ChainHistoryDetails history={history} isChat={isChat}/>
                                        </AccordionPanel>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
            {chainHistories.length >= 2 && <ABComparisonModal isOpen={isABModalOpen} onClose={closeABModal} histories={chainHistories} formatTimestamp={formatTimestamp} isCell={false} isChat={isChat}/>}
        </div>
    );
};

export default ChainHistories;
