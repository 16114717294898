import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Box,
    Text,
    Image,
    Flex,
    Spacer,
    Input
} from '@chakra-ui/react';
import {useRef, useState} from 'react';
import {AddToFolderIcon} from 'evergreen-ui';
import csv from 'csvtojson';
import axios from 'axios';
import {useUtilityFunctions} from "../UtilityFunctions";

const ImportTestsModal = ({isOpen, onClose, suiteData, setSuiteData, workspaceDataID}) => {
    const [csvData, setCsvData] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [unknownVars, setUnknownVars] = useState([]);
    const fileInputRef = useRef(null);
    const [importIsRunning, setImportIsRunning] = useState(false);
    const {getAccessToken} = useUtilityFunctions();



    const handleAddTestsClick = async () => {

        setImportIsRunning(true);

        // Convert the CSV data into JSON
        const jsonData = await csv().fromString(csvData);

      

        const unknownVars = [];

        const testsToAdd = [];

        // Iterate over the rows in the CSV
        for (const row of jsonData) {
            // Create the var_mapping for this row
            const var_mapping = {};

            for (const varName in row) {
                if (!(varName in suiteData.variables)) {
                    if (!unknownVars.includes(varName)) {
                        unknownVars.push(varName);
                    }
                } else {
                    var_mapping[varName] = row[varName];
                }
            }

            if (Object.keys(var_mapping).length > 0) {
                // Create the test with this var_mapping
                const test = {
                    var_mapping, // var_mapping created from the current row
                    assertions: [] // empty array
                };

                // Add test to the array
                testsToAdd.push(test);
            }

            
        }

        if (testsToAdd.length > 0 && testsToAdd.some(test => Object.keys(test.var_mapping).length > 0)) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${suiteData._id}/tests/bulk`,
                    testsToAdd,
                    {
                        headers: {
                            Authorization: `Bearer ${getAccessToken(workspaceDataID)}`,
                        },
                    }
                );

                if (response.data) {
                    // Update the state with the new tests
                    setSuiteData((prevSuiteData) => ({
                        ...prevSuiteData,
                        tests: [...prevSuiteData.tests, ...response.data.tests],
                    }));
                }
            } catch (error) {
                console.error('Failed to add tests:', error);
                setImportIsRunning(false);
            }
        }

        setImportIsRunning(false)
        // Reset the csvData and fileName state variables
        handleClose()

    };

    const handleClose = () => {
        // Reset state variables
        setCsvData(null);
        setFileName(null);
        setUnknownVars([]);
        setImportIsRunning(false);

        // Close the modal
        onClose();
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];

        if (!file) { // if no file was selected, do nothing
            return;
        }

        setFileName(file.name);
        const reader = new FileReader();

        reader.onload = async (evt) => {
            // evt.target.result contains the file content as text
            const fileContent = evt.target.result;
            setCsvData(fileContent);

            // Convert the CSV data into JSON
            const jsonData = await csv().fromString(fileContent);

            const tempUnknownVars = [];

            if (jsonData.length > 0) {
                const variableNames = Object.keys(jsonData[0]);

                for (const varName of variableNames) {
                    if (!(varName in suiteData.variables)) {
                        if (!tempUnknownVars.includes(varName)) {
                            tempUnknownVars.push(varName);
                        }
                    }
                }
            }

            setUnknownVars(tempUnknownVars);
        };
        reader.readAsText(file);
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay/>
            <ModalContent minW={"30vw"}>
                <ModalHeader>Import Test Set</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Box borderWidth={2} borderRadius="md" borderColor="orange" padding={3} marginBottom={3}>
                        <Text fontWeight="bold">How to format your .CSV</Text>
                        <Flex direction={["column", "row"]} mt={3}>
                            <Text mr={3}>Spread your variable names out as the headers. Each row corresponds to a
                                test. <br/> <br/> Your imported test will be appended to the existing test set.</Text>
                            <Spacer/>
                            <Image src="https://i.ibb.co/1qsDfH5/Screen-Shot-2023-08-01-at-12-58-35-AM.png"
                                   alt="Your Image Description" maxWidth={"40%"} minWidth={"40%"} objectFit="contain"/>
                        </Flex>
                    </Box>

                    <Input type="file" accept=".csv" onChange={handleFileUpload} ref={fileInputRef}
                           style={{display: 'none'}}/>
                    <Button leftIcon={<AddToFolderIcon/>} colorScheme="blue" variant="solid"
                            onClick={() => fileInputRef.current.click()} size="lg" mt={3} borderRadius="md">
                        Choose CSV File
                    </Button>
                    {fileName && <Text mt={3}>Selected file: {fileName}</Text>}
                    {unknownVars.length > 0 &&
                        <Text color="red" mt={3}>Variables from this CSV that aren't in your testing suite and won't be
                            added: {unknownVars.join(', ')}</Text>}

                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleAddTestsClick} isLoading={importIsRunning}>
                        Add Test Cases
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ImportTestsModal;
