import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {
    Box,
    Flex,
    Text,
    useColorModeValue,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Button,
    Input,
    useToast,
    useAccordionContext,
    Tooltip,
    Checkbox,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalFooter
} from '@chakra-ui/react';
import {
    AddIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    SettingsIcon,
    DeleteIcon
} from '@chakra-ui/icons';

import {useUtilityFunctions} from "../UtilityFunctions";

import {EditIcon} from "evergreen-ui";
import WorkspaceCollectionSettingsModal from '../CollectionConfig/WorkspaceCollectionSettingsModal'
import ShareModal from "../Collaboration/ShareModal";
import VaultModal from "../Vault/VaultModal";
import FunctionEditorHandler from "./FunctionEditorHandler";
import IntegrationsModal from "../Integrations/IntegrationsModal";


// Collection component
const WorkspaceSidebarCollection = ({
                                        collection,
                                        collectionIdentifier,
                                        workspaceData,
                                        setWorkspaceData,
                                        allChainTabs,
                                        setAllChainTabs,
                                        selectedChain,
                                        setSelectedChain,
                                        userData,
                                    }) => {
    const [isAddingChain, setIsAddingChain] = useState(false);
    const [newChainName, setNewChainName] = useState('');
    const [chainsIDAndName, setChainsIDAndName] = useState([]);
    const {getAccessToken} = useUtilityFunctions();
    const {isOpen} = useAccordionContext();
    const inputRef = useRef(null);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

    const [editingChainId, setEditingChainId] = useState(null);
    const [isChatCheckboxChecked, setIsChatCheckboxChecked] = useState(false);
    const checkboxRef = useRef(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [chainIdToDelete, setChainIdToDelete] = useState(null);

    const openDeleteModal = (chainId) => {
        setChainIdToDelete(chainId);
        setIsDeleteModalOpen(true);
      };

    const toast = useToast()

    const firstRender = useRef(true);

    const fetchChains = async () => {
        // Check if collection and collection.chains are defined
        if (collection && collection.chains) {
            const promises = collection.chains.map(async currChain => {
                if (currChain.id !== undefined) {
                    const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/chain/${currChain.id}`, {
                        headers: {
                            'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                        }
                    });
                    return response.data;
                } else {
                    return null;
                }
            });

            const chainsData = await Promise.all(promises);
            // Filter out null values and map to simplified structure
            const simplifiedChainsData = chainsData.filter(chain => chain !== null).map(chain => ({
                id: chain._id,
                name: chain.name
            }));
            setChainsIDAndName(simplifiedChainsData);
        }
    };


    useEffect(() => {
        fetchChains();

    }, [collectionIdentifier]);


    const handleAddChain = async () => {
        if (newChainName !== '') {

            try {
                const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/chain`, {
                    name: newChainName,
                    collection_id: collection.id,
                    workspace_id: workspaceData._id,
                    config: {
                        type: isChatCheckboxChecked ? "chat" : ""
                    },
                    cells: []
                }, {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                    }
                });


                // Update local state with new chain
                setWorkspaceData(prevState => {
                    // Find the updated collection and update its chains
                    const updatedCollections = prevState.collections.map(coll => {
                        if (coll.id === collection.id) {
                            return {
                                ...coll,
                                chains: [...coll.chains, {id: response.data.id}] // Add the new chain
                            };
                        }
                        return coll;
                    });

                    return {
                        ...prevState,
                        collections: updatedCollections
                    };
                });

                // Update chains state with new chain
                setChainsIDAndName(prevChains => [
                    ...prevChains,
                    {id: response.data.id, name: newChainName} // assuming response.data.id contains the id of the newly created chain
                ]);

                setNewChainName('');
                setIsAddingChain(false);
                setIsChatCheckboxChecked(false)
            } catch (err) {
                console.error(err);
                toast({
                    title: "Could not add chain.",
                    description: "Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            setNewChainName('');
            setIsAddingChain(false);
            setIsChatCheckboxChecked(false)
        }
    }

    const handleKeyDownChain = (event) => {
        if (event.key === 'Enter') {
            handleAddChain();
        }
    }

    const handleChainClick = (chain) => {
        setAllChainTabs(prevTabs => {
            const chainExists = prevTabs.some(existingChain => existingChain.id === chain.id);

            if (!chainExists) {
                return [...prevTabs, chain];
            }

            return prevTabs;
        });

        setSelectedChain(chain);
    }

    // Function to handle name change on blur
    const handleChainNameChange = async (chainId) => {
        const chain = chainsIDAndName.find(ch => ch.id === chainId);
        if (!chain) return;

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/chain/${chainId}/update-name`,
                {new_name: chain.name},
                {headers: {'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`}}
            );

            // Update local state with the new name
            setChainsIDAndName(chains => chains.map(ch =>
                ch.id === chainId ? {...ch, name: response.data.new_name} : ch
            ));

            // Update allChainTabs with the new name
            setAllChainTabs(prevTabs => prevTabs.map(tab =>
                tab.id === chainId ? {...tab, name: response.data.new_name} : tab
            ));

        } catch (err) {
            console.error(err);
            toast({
                title: "Could not update chain name.",
                description: "Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

        setEditingChainId(null);
    }

    async function deleteChain(chainId) {
        try {
          const response = await axios.delete(`${process.env.REACT_APP_ROUTE_PREFIX}/api/chain/${chainId}`, {
            headers: {
              'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
            }
          });
          // Handle response, e.g., by updating state to remove the chain from the list
          if (response.status === 200) {
            // Assuming response is successful if the status is 200
            setWorkspaceData(prevState => {
              const updatedCollections = prevState.collections.map(coll => {
                if (coll.id === collection.id) {
                  // Filter out the chain that was just deleted
                  const filteredChains = coll.chains.filter(chain => chain.id !== chainId);
                  return { ...coll, chains: filteredChains };
                }
                return coll;
              });
      
              return {
                ...prevState,
                collections: updatedCollections
              };
            });
      
            // Update chains state to remove deleted chain
            setChainsIDAndName(prevChains => prevChains.filter(chain => chain.id !== chainId));

            // Remove the deleted chain from allChainTabs
            setAllChainTabs(prevTabs => prevTabs.filter(chain => chain.id !== chainId));

            // If the deleted chain is the currently selected one, select a different chain
            setSelectedChain(prevSelectedChain => {
                if (prevSelectedChain && prevSelectedChain.id === chainId) {
                // Here we assume setAllChainTabs has the updated list, 
                // so we select the first one in that list or null if none are left
                return allChainTabs[0] || null;
                }
                return prevSelectedChain;
            });

          }
        } catch (error) {
          // Handle error, e.g., by showing an error message
          console.error(error);
          // Replace this with your error handling UI, e.g., a toast notification
          toast({
            title: "Error deleting chain.",
            description: "There was an error deleting the chain, please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }

      const confirmDelete = async () => {
        if (chainIdToDelete) {
          await deleteChain(chainIdToDelete);
        }
        setIsDeleteModalOpen(false); // Close the modal after deletion
      };




    useEffect(() => {
        console.log("POINT dsfsdfds1")
        if (isAddingChain) {
            inputRef.current && inputRef.current.focus();
        }
    }, [isAddingChain]);

    return (
        <>
            <AccordionItem border="none">
                {({isExpanded}) => (
                    <>
                        <AccordionButton pl={2} pr={0} h="2.5rem" display="flex" alignItems="center">
                            <>
                                {isExpanded ? <ChevronDownIcon boxSize={4}/> : <ChevronRightIcon boxSize={4}/>}
                                <Box flex="1" textAlign="left" display="flex" alignItems="center" ml={2}>
                                    <Text
                                        _hover={{bg: "#EDEDED"}}
                                        _active={{bg: "#EDEDED"}}
                                        fontWeight="medium"
                                        color="#2B2B2B"
                                        fontSize="sm"
                                    >
                                        {collection.name}
                                    </Text>
                                </Box>
                                {
                                    ((!userData.status || !userData.status.role) || (userData.status && userData.status.role && userData.status.role === 'developer')) ? (
                                        <>
                                            <Tooltip label="Configure collection" fontSize="sm" placement="right"
                                                     openDelay={300}>
                                                <Box
                                                    as="div"
                                                    p={0}
                                                    h="2.5rem"
                                                    w="2.5rem"
                                                    onClick={(e) => {
                                                        if (isExpanded) {
                                                            e.stopPropagation();
                                                        }
                                                        setIsSettingsModalOpen(true);
                                                    }}
                                                    cursor="pointer"
                                                    _hover={{bg: "#e6e6e6"}}
                                                    _active={{bg: "#e6e6e6"}}
                                                    pt={1}
                                                >
                                                    <SettingsIcon boxSize="2.5"/>
                                                </Box>
                                            </Tooltip>
                                        </>
                                    ) : null

                                }
                                <Tooltip label="Create new chain" fontSize="sm" placement="right" openDelay={300}>
                                    <Box
                                        as="div"
                                        p={0}
                                        h="2.5rem"
                                        w="2.5rem"
                                        onClick={(e) => {
                                            if (isExpanded) {
                                                e.stopPropagation();
                                            }
                                            setIsAddingChain(true);
                                        }}
                                        cursor="pointer"
                                        _hover={{bg: "#e6e6e6"}}
                                        _active={{bg: "#e6e6e6"}}
                                        pt={1}
                                    >
                                        <AddIcon boxSize="2"/>
                                    </Box>
                                </Tooltip>
                            </>
                        </AccordionButton>
                        <AccordionPanel px={0} py={0}>
                            {isAddingChain &&
                                <Flex align="right" onBlur={(e) => {
                                    if (e.target !== inputRef.current && e.target !== checkboxRef.current) {
                                        handleAddChain();
                                    }
                                }}>
                                    <Tooltip label="Add chain as a chat" placement="top" mb={2}>
                                        <Checkbox
                                            colorScheme="orange"
                                            isChecked={isChatCheckboxChecked}
                                            onChange={e => {
                                                setIsChatCheckboxChecked(e.target.checked)
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                            pl={2}
                                            ref={checkboxRef}
                                        />
                                    </Tooltip>
                                    <Input
                                        size="sm"
                                        placeholder="Chain name (↵ to save)."
                                        value={newChainName}
                                        onChange={e => setNewChainName(e.target.value)}
                                        onKeyDown={handleKeyDownChain}
                                        onClick={(e) => e.stopPropagation()}
                                        autoFocus
                                        border="none"
                                        borderRadius="none"
                                        _focus={{
                                            borderColor: "none",
                                            boxShadow: "none"
                                        }}
                                        pl={3}
                                        ref={inputRef}
                                    />
                                </Flex>
                            }
                            {(chainsIDAndName.length || isAddingChain) ? chainsIDAndName.map((chain, chainIndex) => (
                                editingChainId === chain.id ? (
                                    <Input
                                        size="sm"
                                        value={chain.name}
                                        onChange={e => {
                                            const newValue = e.target.value;
                                            setChainsIDAndName(prevChains => prevChains.map(ch =>
                                                ch.id === chain.id ? {...ch, name: newValue} : ch
                                            ));
                                        }}
                                        onBlur={() => handleChainNameChange(chain.id)}
                                        autoFocus
                                        border="none"
                                        borderRadius="none"
                                        _focus={{
                                            borderColor: "none",
                                            boxShadow: "none"
                                        }}
                                        pl={9}
                                        ref={inputRef}
                                    />
                                ) : (
                                    <Box className="group" position="relative">
                                    <Button
                                        key={chainIndex}
                                        fontSize="sm"
                                        color="#2B2B2B"
                                        width="full"
                                        fontWeight="medium"
                                        variant="ghost"
                                        justifyContent="space-between"
                                        onClick={() => handleChainClick(chain)}
                                        _hover={{
                                            bg: "#EDEDED", width: '100%',
                                            justifyContent: 'space-between'
                                        }}
                                        _active={{bg: "#EDEDED"}}
                                        my={0}
                                        borderRadius="none"
                                        position="relative"
                                        left={"0"}
                                        pl={"3"}
                                        bg={selectedChain && chain.id === selectedChain.id ? "#e6e6e6" : "transparent"}
                                    >
                                        {selectedChain && chain.id === selectedChain.id && (
                                            <Box
                                                position="absolute"
                                                left="0"
                                                top="0"
                                                bottom="0"
                                                width="2px"
                                                backgroundColor="#ff703f"
                                            />
                                        )}
                                         {((!userData.status || !userData.status.role) || (userData.status && userData.status.role && userData.status.role === 'developer')) && <Box
                                            position="absolute"
                                            left="2"
                                            opacity="0"
                                            _groupHover={{ opacity: "1" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openDeleteModal(chain.id);
                                            }}
                                            >
                                            <Tooltip label="Delete chain" fontSize="sm" placement="top" openDelay={300}>
                                                <DeleteIcon boxSize="3.5" cursor="pointer" color="red.500" />
                                            </Tooltip>
                                        </Box>}

                                        <Text pl={6}>{chain.name}</Text>
                                        <EditIcon
                                            size={10}
                                            onClick={e => {
                                                e.stopPropagation();
                                                setEditingChainId(chain.id);
                                            }}
                                        />
                                    </Button>
                                    </Box>
                                ))) : (
                                <Box fontSize="sm" color="#7d7d7d" pl={9} py={2}>
                                    This collection is empty.<br/>
                                    <Button
                                        variant="link"
                                        fontSize="sm"
                                        color="#ff703f"
                                        fontWeight="normal"
                                        onClick={() => {
                                            setIsAddingChain(true);
                                        }}
                                        _hover={{textDecoration: "none"}}
                                    >
                                        Add a chain
                                    </Button>
                                    {" "} to start working.
                                </Box>
                            )}
                        </AccordionPanel>
                    </>
                )}
            </AccordionItem>
            <WorkspaceCollectionSettingsModal
                isOpen={isSettingsModalOpen}
                onClose={() => setIsSettingsModalOpen(false)}
                workspaceData={workspaceData}
                setWorkspaceData={setWorkspaceData}
                collectionID={collectionIdentifier}
            />
            <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete Chain</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    <Text>Are you sure you want to delete this chain? This action cannot be undone.</Text>
                    </ModalBody>
                    <ModalFooter>
                    <Button colorScheme="gray" mr={3} onClick={() => setIsDeleteModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button colorScheme="red" onClick={confirmDelete}>
                        Delete
                    </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default WorkspaceSidebarCollection;