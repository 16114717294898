import React, { useState, useEffect } from 'react';
import { Box, Text, Input, Button, Select, HStack, VStack, Textarea, IconButton, useToast } from "@chakra-ui/react";
import axios from 'axios';
import {useUtilityFunctions} from "../UtilityFunctions";
import { TrashIcon, UploadIcon, AddIcon } from 'evergreen-ui';

const AssertionTemplateComponent = ({ collection, workspaceData, setWorkspaceData, assertionTemplates: initialTemplates }) => {
    const [assertionTemplates, setAssertionTemplates] = useState(initialTemplates || []);
    const {getAssertionTypes, getAccessToken} = useUtilityFunctions();
    const toast = useToast()
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const assertionTypes = Object.values(getAssertionTypes()).flat();


    useEffect(() => {
        // // Initialize assertion templates from workspaceData when it changes
        if (workspaceData) {
            const collectionAssertionTemplates = workspaceData.collections.find((col) => col.id === collection.id)?.assertion_templates || [];
            setAssertionTemplates(collectionAssertionTemplates);
        }
    }, [workspaceData, collection]);


    const handleTemplateNameChange = (id, name) => {
        const updatedTemplates = assertionTemplates.map(t => 
            t.id === id ? { ...t, template_name: name } : t
        );
        setAssertionTemplates(updatedTemplates);
    };

    const handleAssertionChange = (templateId, index, field, value) => {
        const updatedTemplates = assertionTemplates.map(t => {
            if (t.id === templateId) {
                const updatedAssertions = t.assertions.map((a, i) => 
                    i === index ? { ...a, [field]: value } : a
                );
                return { ...t, assertions: updatedAssertions };
            }
            return t;
        });
        setAssertionTemplates(updatedTemplates);
    };

    const addAssertion = (templateId) => {
        const updatedTemplates = assertionTemplates.map(t => {
            if (t.id === templateId) {
                const newAssertion = { assert_type: assertionTypes[0], assert_string: '', assert_num: '' };
                return { ...t, assertions: [...t.assertions, newAssertion] };
            }
            return t;
        });
        setAssertionTemplates(updatedTemplates);
    };

    const deleteAssertion = (templateId, index) => {
        const updatedTemplates = assertionTemplates.map(t => {
            if (t.id === templateId) {
                const updatedAssertions = t.assertions.filter((_, i) => i !== index);
                return { ...t, assertions: updatedAssertions };
            }
            return t;
        });
        setAssertionTemplates(updatedTemplates);
    };

    const deleteTemplate = (templateId) => {
        const updatedTemplates = assertionTemplates.filter(t => t.id !== templateId);
        setAssertionTemplates(updatedTemplates);
    };

    const addTemplate = async () => {
        const input = {
            workspace_id: workspaceData._id, // Assuming you have workspaceData available as props or from context
            collection_id: collection.id, // Replace with actual collection ID
            template_name: "New Template" // Replace with actual template name or keep it empty for the user to fill in
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/add-template`, 
                input, 
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                    }
                }
            );

            if (response.data && response.data.code === "SUCCESS") {
                const newTemplate = response.data.assertion_templates.pop();
                const updatedTemplates = [...assertionTemplates, newTemplate]
                setAssertionTemplates(updatedTemplates);
                // Update the local state (`workspaceData`) similar to your example
                const updatedWorkspace = { ...workspaceData };
                const targetCollection = updatedWorkspace.collections.find(col => col.id === collection.id);

                if (targetCollection) {
                    targetCollection.assertion_templates = updatedTemplates
                }
            
                setWorkspaceData(updatedWorkspace);
            } else {
                console.error('Failed to add template', response.data);
                toast({
                    title: "An error occured.",
                    description: "Could not add, please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('An error occurred while adding the template', error);
            toast({
                title: "An error occured.",
                description: "Could not add, please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const saveTemplates = async () => {
        const input = {
            workspace_id: workspaceData._id,
            collection_id: collection.id,
            templates: assertionTemplates // sending the current state of the assertionTemplates
        };
        setIsSaveLoading(true)

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/update-templates`, 
                input, 
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                    }
                }
            );

            if (response.data && (response.data.code === "SUCCESS" || response.data.code === "NO_CHANGE")) {
                toast({
                    title: "Success.",
                    description: "Templates saved successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

                    // Update the local state (`workspaceData`) similar to your example
                const updatedWorkspace = { ...workspaceData };
                const targetCollection = updatedWorkspace.collections.find(col => col.id === collection.id);

                if (targetCollection) {
                    targetCollection.assertion_templates = assertionTemplates
                }
            
                setWorkspaceData(updatedWorkspace);

                setIsSaveLoading(false)
            } else {
                console.error('Failed to save templates', response.data);
                toast({
                    title: "An error occured.",
                    description: "Could not save templates, please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                setIsSaveLoading(false)
            }
        } catch (error) {
            console.error('An error occurred while saving the templates', error);
            toast({
                title: "An error occured.",
                description: "Could not save templates, please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setIsSaveLoading(false)
        }
    };


    return (
        <VStack spacing={4} align="start">
            {assertionTemplates.map(template => (
                <Box key={template.id} p={5} border="1px" borderColor="black" borderRadius="md">
                    <HStack mb={3}>
                        <Text>Name:</Text>
                        <Input 
                            value={template.template_name} 
                            onChange={(e) => handleTemplateNameChange(template.id, e.target.value)} 
                        />
                        <IconButton 
                                icon={<TrashIcon />} 
                                size="sm" 
                                aria-label="Delete route"
                                color="danger"
                                onClick={() => deleteTemplate(template.id)}
                            />
                    </HStack>
                    {assertionTypes && template.assertions.map((assertion, index) => (
                        <HStack key={index} mb={2}>
                            <Select
                                value={assertion.assert_type}
                                onChange={(e) => handleAssertionChange(template.id, index, 'assert_type', e.target.value)}
                                maxW={"25%"}
                            >
                               {assertionTypes.map((type, idx) => (
                                            <option key={idx} value={type}>{type}</option>
                                ))}
                            </Select>
                            <Textarea 
                                value={assertion.assert_string}
                                placeholder="Eval string"
                                onChange={(e) => handleAssertionChange(template.id, index, 'assert_string', e.target.value)}
                            />
                            <Input 
                                value={assertion.assert_num}
                                placeholder="Eval #"
                                maxW={"15%"}
                                onChange={(e) => handleAssertionChange(template.id, index, 'assert_num', e.target.value)}
                            />
                            <IconButton 
                                icon={<TrashIcon />} 
                                size="sm" 
                                aria-label="Delete route"
                                color="danger"
                                onClick={() => deleteAssertion(template.id, index)}
                            />
                        </HStack>
                    ))}
                    <Button onClick={() => addAssertion(template.id)} leftIcon={<AddIcon />}>Add Assertion</Button>
                </Box>
            ))}
            <HStack mb={3}>
                <Button onClick={addTemplate} colorScheme="blue" leftIcon={<AddIcon />}>Add Template</Button>
                <Button onClick={saveTemplates} colorScheme="green" leftIcon={<UploadIcon />} isLoading={isSaveLoading}>Save Templates</Button>
            </HStack>
        </VStack>
    );
};

export default AssertionTemplateComponent;
