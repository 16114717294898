import { Switch, FormControl, FormLabel, Stack, HStack, Input, Table, Thead, Tbody, Tr, Th, Td, Select, Flex, Grid, Text, Box } from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {useUtilityFunctions} from "../UtilityFunctions";
import AdvancedResponseFormatEditor from '../Tools/FunctionCallEditor/FunctionCallEditorTool'

const ResponseFormatEditor = ({cellID, prompt, setPrompt, paramDetails, cell, localCell, setLocalCell, suiteData, setSuiteData, workspaceDataID, workspaceData, chainID}) => {

    const initialFunctions = localCell.config.params.functions || [];
    const [isSwitchedOn, setIsSwitchedOn] = useState(initialFunctions.length > 0);
    const [outputName, setOutputName] = useState("");
    const [outputDescription, setOutputDescription] = useState("");

    const [isRoutesSwitchedOn, setIsRoutesSwitchedOn] = useState(
        localCell.config.multi_run && 
        localCell.config.multi_run.logic && 
        localCell.config.multi_run.logic.routers &&
        localCell.config.multi_run.logic.routers.use_routers
      );



    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    const [availableFunctions, setAvailableFunctions] = useState([]);
    const [selectedFunctionNames, setSelectedFunctionNames] = useState(initialFunctions.map(func => func.name));

    const collectionForChain = workspaceData.collections.find(collection =>
        collection.chains.some(chain => chain.id === chainID)
    );

    const arraysAreEqual = (arr1, arr2) => {
        return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
    }

    useEffect(() => {
        // Check if use_routers is true
        if (localCell.config.multi_run && 
            localCell.config.multi_run.logic && 
            localCell.config.multi_run.logic.routers &&
            localCell.config.multi_run.logic.routers.use_routers && collectionForChain && workspaceData && availableFunctions && (!isSwitchedOn || !isRoutesSwitchedOn || !arraysAreEqual(selectedFunctionNames, availableFunctions.map(func => func.name)))) {
    
            // 1. Set "Specify response format" to always be on
            setIsSwitchedOn(true);
            
            // 2. Set "Use routes" to always be on
            setIsRoutesSwitchedOn(true);
            toggleRouters(true);
    
            // 3. Preselect all available functions
            const allFunctionNames = availableFunctions.map(func => func.name);
            setSelectedFunctionNames(allFunctionNames);
        }
    }, [localCell.config, availableFunctions]);


    useEffect(() => {
        if (collectionForChain && collectionForChain.available_functions && workspaceData.aux && workspaceData.aux.functions) {
            // Extract function_id properties from collectionForChain.available_functions
            const functionIds = collectionForChain.available_functions.map(obj => obj.function_id);

            
            // Filter out the functions from workspaceData.aux.functions where the function's ID matches with the functionIds
            const functionsFromAvailable = workspaceData.aux.functions.filter(func =>
                functionIds.includes(func._id)  // Assuming the ID field is _id, adjust if it's different
            );
            
            setAvailableFunctions(functionsFromAvailable.map(item => item.function));

        } else if (workspaceData.aux && workspaceData.aux.functions) {
            const extractedFunctions = workspaceData.aux.functions.map(item => item.function);
            setAvailableFunctions(extractedFunctions);
        }
    }, [workspaceData, collectionForChain]);

    const prevUseRoutersRef = useRef();

    useEffect(() => {
        // const bulkUpdateOnAllFunctionRouters = async () => {
        //     if (localCell.config.multi_run && 
        //         localCell.config.multi_run.logic && 
        //         localCell.config.multi_run.logic.routers) {
                
        //         if (prevUseRoutersRef.current !== localCell.config.multi_run.logic.routers.use_routers && localCell.config.multi_run.logic.routers.use_routers) {
        //             setIsRoutesSwitchedOn(true);
        //             setIsSwitchedOn(true)
        //             setSelectedFunctionNames(availableFunctions.map((func) => func.name));
                    
        //             try {
        //                 console.log("BEING CALLED FROM HERE")
        //                 const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cellID}/update/params`, {
        //                     params: { functions: availableFunctions },
        //                     config: paramDetails
        //                 }, {
        //                     headers: {
        //                         'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`,
        //                         'Content-Type': 'application/json',
        //                     }
        //                 });
    
        //                 setLocalCell(prevCell => ({ ...prevCell, config: { ...prevCell.config, params: response.data.params } }));
        //                 setParams(response.data.params);
        //             } catch (error) {
        //                 console.error(error);
        //             }
        //         }
                
        //         prevUseRoutersRef.current = localCell.config.multi_run.logic.routers.use_routers;
        //     } else {
        //         setIsRoutesSwitchedOn(false);
        //     }
        // };

        // if (paramDetails) {
        //     bulkUpdateOnAllFunctionRouters();
        // }
    }, [localCell.config]);



    const [params, setParams] = useState(localCell.config.params);


    const handleFunctionSelection = async (func) => {

        let updatedSelectedFunctions = [...selectedFunctionNames];
        // Check if the function name is included in the array
        if (updatedSelectedFunctions.includes(func.name)) {
            updatedSelectedFunctions = updatedSelectedFunctions.filter(itemName => itemName !== func.name);
        } else {
            // Push the function name instead of the whole function object
            updatedSelectedFunctions.push(func.name);
        }
        
        setSelectedFunctionNames(updatedSelectedFunctions);

        const functionsToUpload = availableFunctions.filter(f => updatedSelectedFunctions.includes(f.name));

        try {
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cellID}/update/params`, {
                params: { functions: functionsToUpload },
                config: paramDetails
            }, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`,
                    'Content-Type': 'application/json',
                }
            });
            setLocalCell(prevCell => ({ ...prevCell, config: { ...prevCell.config, params: response.data.params } }));
            setParams(response.data.params);
        } catch (error) {
            console.error(error);
        }
    };


    const handleSwitchedOff = async () => {
        if (!isSwitchedOn) {
            setSelectedFunctionNames([]); // Clearing selected functions
            setIsRoutesSwitchedOn(false);
            toggleRouters(false);
            const updatedParams = { ...params, functions: [] }; // Updating to have an empty functions array
            try {
                const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cellID}/update/params`,
                    {
                        params: updatedParams,
                        config: paramDetails,
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`,
                            'Content-Type': 'application/json',
                        }
                    }
                );
                setLocalCell(prevCell => ({ ...prevCell, config: { ...prevCell.config, params: response.data.params } }));
                setParams(response.data.params);
            } catch (error) {
                console.error(error);
            }
        }
    };
    

    const handleBlur = (index, field, value) => {
        handleSwitchedOff()
    };

    const handleOutputNameUpdate = (e) => {
        const updatedName = e.target.value.replace(/\s/g, "_");
        setOutputName(updatedName);
    };

    const toggleRouters = async (routerChecked) => {
        let multiRunConfig = {
            num_runs: routerChecked ? 2 : 1,
            append: false,
            logic: {
                routers: {
                    use_routers: routerChecked,
                    workspace_id: workspaceData._id,
                    collection_id: collectionForChain.id
                }
            }
        }
    
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cellID}/update/multi_run`,
                multiRunConfig,
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (response.data && response.data.multi_run) {
                setLocalCell(prevCell => ({
                    ...prevCell,
                    config: {
                        ...prevCell.config,
                        multi_run: response.data.multi_run
                    }
                }));
            }
        } catch (error) {
            console.error("Failed to update multi_run config:", error);
        }
    };
    



    return (
        <Stack spacing={4}>
            <FormControl display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <FormLabel mb="0" fontWeight="normal">Specify response format:</FormLabel>
                    <Switch 
                        size="sm" 
                        colorScheme="orange" 
                        isChecked={isSwitchedOn} 
                        onBlur={() => handleSwitchedOff()} 
                        onChange={(e) => {
                            setIsSwitchedOn(e.target.checked);
                        }} 
                        isDisabled={true}
                    />
                </Box>
            </FormControl>


            {!isSwitchedOn ? (
                <HStack mb={2}>
                    <FormLabel>Name of output:</FormLabel>
                    <Input maxW="30%" placeholder="Output name" value={outputName} onBlur={() => handleSwitchedOff()} onChange={(e) => handleOutputNameUpdate(e)} />
                </HStack>
            ) : (
                availableFunctions && (
                    <div>
                        <Stack spacing={4}>
                            {availableFunctions && availableFunctions.length > 0 ? (
                                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={-1} mb={2}>
                                    {availableFunctions.map(func => (
                                         <Box 
                                            key={func.name} 
                                            bg={selectedFunctionNames.includes(func.name) ? "orange.500" : "gray.200"} 
                                            p={2}  
                                            borderRadius="md"
                                            cursor="pointer"
                                            // _hover={{ bg: selectedFunctionNames.includes(func.name) ? "orange.600" : "gray.300" }}
                                            // onClick={() => handleFunctionSelection(func)}
                                            isDisabled={true}
                                        >
                                         <Flex align="center" justify="center">
                                             <Text color={selectedFunctionNames.includes(func.name) ? "white" : "black"}>{func.name}</Text>
                                         </Flex>
                                     </Box>
                                    ))}
                                </Grid>
                            ) : (
                                <Text fontWeight="bold" mt={"-2"} mb={"2"}>No functions available in this collection yet. Add one or allow one to start.</Text>
                            )}
                        </Stack>
                    </div>
                )
            )}


            {isSwitchedOn && collectionForChain && collectionForChain.routes && (
                <FormControl display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                    <FormLabel mb="0" fontWeight="normal">Use routes:</FormLabel>
                    <Switch 
                        size="sm" 
                        colorScheme="orange" 
                        isChecked={isRoutesSwitchedOn} 
                        onChange={(e) => {
                            setIsRoutesSwitchedOn(e.target.checked);
                            toggleRouters(e.target.checked);
                        }} 
                        isDisabled={true}
                    />
                    </Box>
                </FormControl>
                )}
        </Stack>
    );
}

export default ResponseFormatEditor;
