import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {
    Box,
    SimpleGrid,
    Text,
    Card,
    CardBody,
    CardHeader, Button,
} from '@chakra-ui/react';
import AddCustomModelFamilyModal from "./AddCustomModelFamilyModal";
import CustomModelCard from "./CustomModelCard";

const CustomModelsMenu = ({workspaceData, customModels, setCustomModels}) => {

    return (
        <>
            <Text fontWeight={'bold'} fontSize={'lg'} pb={4}>Custom Models</Text>
            <AddCustomModelFamilyModal setCustomModels={setCustomModels} workspaceID={workspaceData._id}/>
            <SimpleGrid columns={2} spacing={10}>
                {Object.keys(customModels).map((modelFamily, index) => (
                    <CustomModelCard key={index} customModels={customModels} modelFamily={modelFamily}
                                     setCustomModels={setCustomModels} workspaceID={workspaceData._id}/>
                ))}
            </SimpleGrid>
        </>
    );
};

export default CustomModelsMenu;
