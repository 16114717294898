import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
    Box,
    Flex,
    Text,
    useColorModeValue,
    Accordion,
    Button,
    Input,
    useToast, Heading,
    Divider,
    Tooltip
} from '@chakra-ui/react';

import {RefreshIcon} from "evergreen-ui";

import {
    AddIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';

import WorkspaceSidebarCollection from './WorkspaceSidebarCollection';
import VaultModal from "../Vault/VaultModal";
import {useUtilityFunctions} from "../UtilityFunctions";
import IntegrationsModal from "../Integrations/IntegrationsModal";
import ShareModal from '../Collaboration/ShareModal';
import FunctionEditorHandler from './FunctionEditorHandler'

const WorkspaceSidebar = ({
                              workspaceData,
                              setWorkspaceData,
                              allChainTabs,
                              setAllChainTabs,
                              selectedChain,
                              setSelectedChain,
                              userData,
                              ...rest
                          }) => {
    const {getAccessToken, getIsFreeUser} = useUtilityFunctions();
    const [isAddingCollection, setIsAddingCollection] = useState(false);
    const [newCollectionName, setNewCollectionName] = useState('');

    const [isAddingChain, setIsAddingChain] = useState(false);
    const [newChainName, setNewChainName] = useState('');

    const [refreshIsLoading, setRefreshIsLoading] = useState(false);
    const [hasEdits, setHasEdits] = useState(false);


    const toast = useToast();

    const freeUser = getIsFreeUser()

    const refreshWorkspaceData = async () => {
        window.location.reload();
        setHasEdits(false);
    }


    const handleAddCollection = async () => {
        if (newCollectionName !== '') {
            try {
                const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceData._id}/collections`, {
                    name: newCollectionName,
                    chains: [] // Assuming a new collection doesn't have any chains yet
                }, {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                    }
                });


                // Update local state with new collection
                setWorkspaceData(prevState => {
                    return {
                        ...prevState,
                        collections: [...prevState.collections, {
                            name: newCollectionName,
                            id: response.data.id,
                            chains: []
                        }]
                    };
                });

                setNewCollectionName('');
                setIsAddingCollection(false);
            } catch (err) {
                console.error(err);
                toast({
                    title: "Could not add collection.",
                    description: "Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            setNewCollectionName('');
            setIsAddingCollection(false);
        }
    }

    const handleKeyDownCollection = (event) => {
        if (event.key === 'Enter') {
            handleAddCollection();
        }
    }

    return (
        <Box
            bg={useColorModeValue('#f9f9f9', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{base: 'full', md: 60}}
            pos="relative"
            h="calc(100vh - 60px)"
            display="flex"
            flexDirection="column"
            {...rest}>
            <Flex mt={0} alignItems="center" ml="3" justifyContent="space-between">
                <Heading fontSize="sm" color="#212121" flex="1" fontWeight="600">
                    {workspaceData.name}
                </Heading>
                <Tooltip label="Create new collection" fontSize="sm" placement="right" openDelay={300}>
                    <Button
                        variant="ghost"
                        p={0}
                        h="2.5rem"
                        w="2.5rem"
                        onClick={() => setIsAddingCollection(true)}
                        _hover={{bg: "#EDEDED"}}
                        _active={{bg: "#EDEDED"}}
                        borderRadius="none"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <AddIcon boxSize={3}/>
                    </Button>
                </Tooltip>
            </Flex>
            <Divider borderColor="#ededed"/>
            {isAddingCollection &&
                <Flex align="center" pl={2}>
                    <ChevronRightIcon boxSize={4}/>
                    <Input
                        size="sm"
                        h="2.5rem"
                        placeholder="New collection name"
                        value={newCollectionName}
                        onChange={e => setNewCollectionName(e.target.value)}
                        onBlur={handleAddCollection}
                        onKeyDown={handleKeyDownCollection}
                        autoFocus
                        border="none"
                        boxShadow="none"
                        focusBorderColor="transparent"
                        variant="unstyled"
                        pl={2}
                    />
                </Flex>
            }

            <Box overflowY="auto" flex="1" pb={6}>
                <Accordion defaultIndex={[0]} allowMultiple>
                    {workspaceData.collections.map((collection, index) => (
                        <WorkspaceSidebarCollection
                            key={index}
                            collectionIdentifier={collection.id}
                            collection={collection}
                            workspaceData={workspaceData}
                            setWorkspaceData={setWorkspaceData}
                            allChainTabs={allChainTabs}
                            setAllChainTabs={setAllChainTabs}
                            selectedChain={selectedChain}
                            setSelectedChain={setSelectedChain}
                            userData={userData}
                        />
                    ))}
                </Accordion>
            </Box>

            <Box bottom="0" width="full" p={4}>
                {hasEdits && (
                    <Box p={4} backgroundColor="yellow.200" borderRadius="md" mb={4}>
                        We've noticed edits made, your version may be behind. Refresh to update.
                    </Box>
                )}
                <Button
                    w="100%"
                    mb={2}
                    isLoading={refreshIsLoading}
                    variant="outline"
                    borderColor="black"
                    backgroundColor="transparent"
                    _hover={{
                        bg: "rgba(0, 0, 0, 0.1)", // Slight shade on hover for feedback
                    }}
                    onClick={refreshWorkspaceData}
                    leftIcon={<RefreshIcon/>}
                >
                    Refresh
                </Button>

                {
                    userData.status && userData.status.role && userData.status.role !== 'developer' ? (
                        <>
                            <ShareModal workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}/>
                            {/*<FunctionEditorHandler workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}/>*/}
                            {/*<IntegrationsModal workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}/>*/}
                            <VaultModal workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}/>
                        </>
                    ) : (
                        <>
                            <ShareModal workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}/>
                            <FunctionEditorHandler workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}/>
                            <IntegrationsModal workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}/>
                            <VaultModal workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}/>
                        </>
                    )
                }
            </Box>
        </Box>
    )
}

export default WorkspaceSidebar;



