import React, {useState, useEffect} from 'react';
import {Box, Button, Flex, HStack, Input, Text, Textarea, Icon, Select} from '@chakra-ui/react';
import {ArrowUpDownIcon} from "@chakra-ui/icons";
import { LabTestIcon, TrashIcon } from 'evergreen-ui';

const VariableInput = ({index, defaultValue, onVarMappingChange, testId, varName, isInTestedCells, chatLogic, updateChatLogicInDb, cellID}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);


    const handleTestMessage = () => {
        const cellExists = chatLogic?.tested_cells?.some(cell => cell.cell_id === cellID);
        if (!(cellExists)) {
            const updatedTestedCells = [...chatLogic.tested_cells, {cell_id: cellID}];
            const updatedChatLogic = {
                ...chatLogic,
                tested_cells: updatedTestedCells
            };
            updateChatLogicInDb(updatedChatLogic);
        }
    }

    const handleRemoveFromTest = () => {
        const updatedTestedCells = chatLogic.tested_cells.filter(cell => cell.cell_id !== cellID);
        const updatedChatLogic = {
            ...chatLogic,
            tested_cells: updatedTestedCells
        };
        updateChatLogicInDb(updatedChatLogic);
    }

    const [chatRole, setChatRole] = useState(chatLogic?.message_properties?.find(prop => prop.cell_id === cellID)?.role || 'user');

    useEffect(() => {
        if (chatLogic?.message_properties) {
            setChatRole(chatLogic?.message_properties?.find(prop => prop.cell_id === cellID)?.role || 'user')
        }
    }, [chatLogic]);

    const handleChatRoleChange = (event) => {
        const newRole = event.target.value;
        setChatRole(newRole);

        const messageProperties = chatLogic.message_properties || [];

        const updatedMessageProperties = [...messageProperties];
        const existingProperty = updatedMessageProperties.find(prop => prop.cell_id === cellID);

        if (existingProperty) {
            existingProperty.role = newRole;
        } else {
            updatedMessageProperties.push({ cell_id: cellID, role: newRole });
        }

        const updatedChatLogic = {
            ...chatLogic,
            message_properties: updatedMessageProperties
        };
        updateChatLogicInDb(updatedChatLogic);
    };


    return (
        <Box mb={5}>
        {cellID && 
            <Select 
                defaultValue={"user"}
                value={chatRole} 
                onChange={handleChatRoleChange}
                size={"sm"}
                mb={2}
            >
                <option value="user">user</option>
                <option value="assistant">assistant</option>
                <option value="system">system</option>
                <option value="function">function</option>
            </Select>
        }

        <HStack key={index} spacing={4} mb={5} align="justify-start">
            <Flex align="justify-start" width="100%">


            <Text fontSize="sm" flexShrink={0} width="125px">{varName}</Text>
            <Box
                position="relative"
                flex="1"
                _hover={isInputFocused ? {} : {".expandButton": {opacity: 1}}}

            >
                {/* Expand Button */}
                <Button
                    className="expandButton"
                    position="absolute"
                    top="2"
                    right="2"
                    size="xs"
                    zIndex={1}
                    opacity={0}
                    onClick={() => setIsExpanded(!isExpanded)}
                    leftIcon={<ArrowUpDownIcon/>}
                >
                    {isExpanded ? "Collapse" : "Expand"}
                </Button>

                <Input
                    as="textarea"
                    defaultValue={defaultValue}
                    size="sm"
                    height={isExpanded ? "200px" : "50px"}
                    onBlur={(e) => {
                        onVarMappingChange(testId, varName, e.target.value);
                        setIsInputFocused(false);
                    }}
                    onFocus={() => {
                        setIsInputFocused(true);
                    }}
                    resize="none"
                    pl={2}
                    pt={1}
                />
            </Box>
        </Flex>
        {cellID && (isInTestedCells ? 
                <Button
                    onClick={handleRemoveFromTest}
                    variant="outline" 
                    borderColor="black" 
                    backgroundColor="transparent" 
                    _hover={{
                        bg: "rgba(0, 0, 0, 0.1)", // Slight shade on hover for feedback
                    }}
                    leftIcon={<TrashIcon/>}
                    size="xs"
                    p={5}
                >
                    Don't test
                </Button>
                :
                <Button
                    onClick={handleTestMessage}
                    colorScheme="purple"
                    size="xs"
                    leftIcon={<Icon as={LabTestIcon} />} 
                    p={5}
                >
                    Test message
                </Button>
            )}
        </HStack>
        </Box>
    );
}

export default VariableInput;
