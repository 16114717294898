import {
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Box,
    Text,
    Flex,
    Center,
    Icon,
    Divider,
    Spacer,
    VStack,
    Image,
    useToast
  } from "@chakra-ui/react";
  import { ChatIcon } from '@chakra-ui/icons'
  import { useState } from 'react';
  import axios from 'axios';

import validator from 'validator';


  const CustomChatIcon = (props) => (

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
  <path strokeLinecap="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
</svg>

  );

  
  const SignInModal = ( {setIsLoggedIn} ) => {
    const { isOpen, onOpen, onClose: baseOnClose } = useDisclosure();
    const toast = useToast();
    const [emailUserName, setEmailUserName] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [signInLoading, setSignInLoading] = useState(false)

    const onClose = () => {
        // Clear fields and error message when modal is closed
        setEmailUserName("");
        setPassword("");
        setErrorMessage(null);
        baseOnClose();
      };

    const signIn= () => {
        if (!emailUserName || !password) {
            setErrorMessage("Please fill in all fields.");
            return;
        }
        if (!validator.isEmail(emailUserName)) {
            setErrorMessage("Please enter a valid email.");
            return;
        }

        setSignInLoading(true)

        axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/auth/login`, {
            username: emailUserName,
            password: password,
        })
        .then(response => {
            if (response.data) {
                // If login was successful, close the modal and clear the form
                setSignInLoading(false)
                onClose()
                setErrorMessage(null);

                localStorage.setItem('user_data', JSON.stringify(response.data));
                
                // If you want to access individual properties, you can do so like this
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('token_type', response.data.token_type);

                toast({
                    title: "Login successful",
                    description: "You have successfully logged in.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setIsLoggedIn(true);
                sessionStorage.setItem('state', 'logged-in');
            } else {
                // If there is no data in the response, then the login failed
                setErrorMessage("Username and password not found, please try again.");
            }
        })
        .catch(error => {
            // If there is an error, it could be that the server is down or the request was denied.
            setErrorMessage("Username and password not found, please try again.");
            setSignInLoading(false)
        });
        setSignInLoading(false)
    }

    const inputBorderColorDefault = "#d4d4d4"
    const inputBorderColorFocus = "#e17348"
    const inputBGColor = "#fafafa"
    const inputBorderRadius = 0
  
    return (
      <>
       <Button
          onClick={onOpen}
          as={"a"}
          size="md" // Made the button smaller
          borderRadius="md"
          display={{ base: "none", md: "inline-flex" }}
          fontSize={"sm"}
          fontWeight={600}
          color={"#868686"}
          bg={"#F2F2F2"}
          href={"#"}
          _hover={{
            bg: "#E6E6E6",
            color: "#212121",
          }}
        >
          Sign In
        </Button>
  
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay />
            <ModalContent borderRadius="0">
                <ModalCloseButton />
                <ModalBody>
                    <Flex direction="row" minH="100%">
                        <Box w="45%" pr={3} position="relative" minH="100%">
                            <Flex direction="column" align="left" justify="center" height={"80%"} ml={1}>
                                <Image 
                                  src="https://i.ibb.co/1vXNFcc/symbol-text-dark.png"
                                  alt="Chatter"
                                  width="200px"
                                  height="auto"
                                  mb={10}
                                />
                                <Text fontSize="lg" fontWeight={600} mb={3}>Welcome Back!</Text>
                                <Text fontSize="md">Sign in to continue to Chatter.</Text>
                            </Flex>
                            <Box position="absolute" right="0" top="-2" bottom="-2" width="10px" 
                                  bgGradient="linear(to-r, rgba(255,255,255,1), rgba(234,234,234,1))" mixH="100%">
                            </Box>
                        </Box>
                        <Box w="55%" pl={5} position="relative" zIndex="1">
                            <ModalHeader mb={1} fontSize="lg" ml={-6} fontWeight={700}>Sign In</ModalHeader>
                            <VStack align="left" spacing={4} w="100%">
                                <FormControl w="100%">
                                    <FormLabel fontWeight="bold" fontSize="sm">Email</FormLabel>
                                    <Input
                                        type="email"
                                        placeholder="Your email"
                                        value={emailUserName}
                                        onChange={(e) => setEmailUserName(e.target.value)}
                                        w="100%"
                                        borderRadius={inputBorderRadius}
                                        borderColor={inputBorderColorDefault}
                                        focusBorderColor={inputBorderColorFocus}
                                        bg={inputBGColor}
                                        _focus={{
                                            boxShadow: "none",  
                                        }}
                                    />
                                </FormControl>

                                <FormControl w="100%">
                                    <FormLabel fontWeight="bold" fontSize="sm">Password</FormLabel>
                                    <Input
                                        placeholder="Your password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        w="100%"
                                        borderRadius={inputBorderRadius}
                                        borderColor={inputBorderColorDefault}
                                        focusBorderColor={inputBorderColorFocus}
                                        bg={inputBGColor}
                                        _focus={{
                                            boxShadow: "none",  
                                        }}
                                    />
                                </FormControl>

                                {errorMessage && <Text color="red.500">{errorMessage}</Text>}

                                <Button
                                    onClick={signIn}
                                    as={"a"}
                                    size="md"
                                    borderRadius="md"
                                    fontSize={"sm"}
                                    fontWeight={600}
                                    color={"white"}
                                    bg={"#ED7547"}
                                    href={"#"}
                                    _hover={{
                                        bg: "#D05D32",
                                    }}
                                    w="100%"
                                    mt={2}
                                    mb={5}
                                    isLoading={signInLoading}
                                >
                                    Sign In
                                </Button>
                            </VStack>
                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default SignInModal;