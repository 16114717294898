import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import NavBarComponent from './NavBar/NavBarComponent';
import {ChakraProvider, Spinner, Box} from '@chakra-ui/react';
import {BrowserRouter as Router} from 'react-router-dom';
import Workspace from './Workspace/Workspace';
import PlaygroundHandler from "./Playground/PlaygroundHandler";
import FunctionCallEditorTool from "./Tools/FunctionCallEditor/FunctionCallEditorTool";
import {PlaygroundContext} from "./Playground/PlaygroundContext";
import WorkspaceTutorialComponent from './Workspace/WorkspaceTutorialComponent';
import LoadingWorkspaces from './LoadingComponents/LoadingWorkspaces';



function App() {
    const [isPlayground, setIsPlayground] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const [workspaces, setWorkspaces] = useState([]);

    return (
        <div style={{ overflow: 'hidden', height: '100vh' }}>
      <PlaygroundContext.Provider value={isPlayground}>
          <Router>
              <ChakraProvider>
                  <NavBarComponent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}
                                   userData={userData} setUserData={setUserData} workspaces={workspaces} setWorkspaces={setWorkspaces}/>


                {(isLoggedIn && userData && workspaces.length === 0) ? (
                    // it is still loading the workspaces
                       <LoadingWorkspaces/>
                    ) : (
                        <Routes>
                            <Route path="/playground" element={<PlaygroundHandler setIsPlayground={setIsPlayground} setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />} />
                            <Route path="/workspace/:id" element={<Workspace userData={userData} setUserData={setUserData}/>} />
                            <Route path="/function-call-editor" element={<FunctionCallEditorTool/>} />
                        </Routes>
                    )}
              </ChakraProvider>
          </Router>
      </PlaygroundContext.Provider>
      </div>
  );
}


export default App;

