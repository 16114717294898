import {
    Modal, 
    Button, 
    ModalOverlay, 
    ModalHeader, 
    ModalBody,
    ModalContent,
    ModalCloseButton
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import CellHistories from "./CellHistory";
import ChainHistories from "./ChainHistory";
import {useUtilityFunctions} from "../UtilityFunctions";


const LastSuiteDiffViewer = ({isChat, recentHistories, isDiffViewerOpen, setIsDiffViewerOpen}) => {


    return (
        <Modal isOpen={isDiffViewerOpen} onClose={() => {setIsDiffViewerOpen(false)}} size={"full"}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Difference between suite runs</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {(JSON.stringify(recentHistories))}
            </ModalBody>
        </ModalContent>
    </Modal>
    );

};

export default LastSuiteDiffViewer;


