import React, {useEffect, useState} from 'react';
import {Spinner, Box, Image, Text} from '@chakra-ui/react';

const LoadingWorkspaces = () => {
    const messages = [
        "Please tell your AI not to take over the world.",
        "Remember to feed the H100s.",
        "Let us know if GPT asks for a raise.",
        "We're teaching your LLMs jokes, they're not too punny.",
        "Don't forget to turn down the sarcasm setting!",
        "Email if you pass the Turing test, we probably have to report it.", 
        "Make sure you back up your data before the Skynet simulations."
    ];

    const getRandomIndex = () => Math.floor(Math.random() * messages.length);

    
    const [currentIndex, setCurrentIndex] = useState(getRandomIndex());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 3000); // changes every 3 seconds

        return () => clearInterval(interval); // cleanup interval on component unmount
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="80vh">
            <Image src="https://i.ibb.co/T49sScY/symbol.png" alt="Chatter" marginBottom="4" maxWidth={"5%"} />
            <Box textAlign="center">
                <Text fontWeight="bold" marginBottom="2" fontSize={"40"} color={"gray.700"}>{messages[currentIndex]}</Text>
                <Text marginBottom="20" fontSize={"20"} color={"gray.500"}> - Your friends at Chatter</Text>
            </Box>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='orange.500'
                size='lg'
            />
        </Box>
    );
}


export default LoadingWorkspaces;

