import { Menu, MenuButton, MenuList, MenuItem, Box, Button } from "@chakra-ui/react";
import { AiOutlineUser } from 'react-icons/ai';
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useState } from "react";
import ApiKeyModal from "../SDK-Components/ApiKeyModal";
import UserSettingsModal from "../UserSettings/UserSettingsModal";

const UserProfile = ({ name, onLogout, userData, setUserData }) => {
  const [isApiOpen, setIsApiOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  return (
    <Box>
      <Menu>
        <MenuButton as={Button} leftIcon={<AiOutlineUser />} rightIcon={<ChevronDownIcon />}>
          {name}
        </MenuButton>
        <MenuList>
          <MenuItem mb={4} onClick={() => setIsApiOpen(true)}>ChatterSDK</MenuItem>
          <MenuItem mb={4} onClick={() => setIsSettingsOpen(true)}>Settings</MenuItem>
          <MenuItem onClick={onLogout}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
      <ApiKeyModal isOpen={isApiOpen} onClose={() => setIsApiOpen(false)} />
      <UserSettingsModal isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)}
                         userData={userData} setUserData={setUserData} />
    </Box>
  );
};

export default UserProfile;
