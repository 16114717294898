import {useEffect, useState} from 'react';
import { Input, Text } from "@chakra-ui/react";
import axios from "axios";
import {useUtilityFunctions} from "../UtilityFunctions";

const NameEdit = ({ name, setName, id, setLocalCell, workspaceDataID }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [input, setInput] = useState(name);
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    useEffect(() => {
        if(name.trim() === "") {
            setName("[Untitled Cell]")
        }
        setInput(name);
    }, [name]);

    const handleTextClick = () => {
        setIsEditing(true);
    }

    const handleInputChange = (e) => {
        setInput(e.target.value);
    }

    const updateCellName = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${id}/update/name`,
                { name: name },
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    }
                }
            );
            if (response.data) {
             
                setLocalCell(prevCell => ({...prevCell, name: name}));
            } else {
                console.error("Failed to update cell name");
            }
        } catch (err) {
            console.error("Failed to update cell name:", err);
        }
    };

    const handleInputBlur = () => {
        if (input !== name) {
            updateCellName();
            setName(input)
        }
        setIsEditing(false);
    }

    return isEditing ? (
        <Input
            value={input}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            size="sm"
            autoFocus
            w={'30%'}
        />
    ) : (
        <Text onClick={handleTextClick}>{input}</Text>
    )
}

export default NameEdit;
