import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Link,
    Input,
    useToast,
    Flex,
    Box
} from "@chakra-ui/react";
import axios from 'axios';
import { useState } from 'react';

function PlaygroundIntroModal({ isOpen, onClose, fetchPlaygroundData }) {

    const [email, setEmail] = React.useState('');
    const [error, setError] = React.useState('');
    const toast = useToast();
    const [playgroundOpenLoading, setPlaygroundOpenLoading] = useState(false)

    const handlePlaygroundOpen = async () => {
        setPlaygroundOpenLoading(true)
        try {
            const emailValidityResponse = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/auth/check_email_validity`, 
                { email: email }
            );
    
            if (!emailValidityResponse.data.isValid) {
                setError('Please enter a valid email address.');
                setPlaygroundOpenLoading(false)
                return;
            }
        } catch (error) {
            setError('Error during email validation, please try again.');
            console.error("Error during email validation:", error);
            setPlaygroundOpenLoading(false)
            return;
        }

        fetchPlaygroundData(email);
        setPlaygroundOpenLoading(false)
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="full"> {/* <-- Set the modal size to 'xl' for extra wide */}
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Welcome to <Text as="span" color="orange.400">Chatter Playground</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                Chatter is the easiest way for anyone to iterate, test, and collaborate on their 
                    LLM prompts and chains. This playground is a glimpse of that.
                    <br/>
                    <br/>
                   Here's a demo of the playground from our co-founder.  Want a custom demo of the full platform? <Link href="https://calendly.com/trychatter/30min" color="orange.400" isExternal>Book a time here.</Link>
                   <br/>
                   <br/>
                <iframe
                    title="Loom Video"
                    width="100%"
                    height="550px"
                    src="https://www.loom.com/embed/db9be9c2f1d94a87a7b9522ad8cb2ca9?sid=665e1e3f-9f37-41f3-a9c4-72c3a3afb179"
                    frameborder="0"
                    allowfullscreen
                ></iframe>
                <Box mb={5} mt={3} display="flex" flexDirection="column" alignItems="center">
                <Flex mt={3} alignItems="center" justifyContent="center" width="100%">
                    <Text mr={3}>Email:</Text>
                    <Input 
                        placeholder="bob@acme.com" 
                        value={email} 
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setError(''); // Reset error when the user types
                        }}
                        maxWidth="20%"
                        flex="1"
                        mr={4}
                    />
                    <Button colorScheme="orange" onClick={handlePlaygroundOpen} isDisabled={!email} isLoading={playgroundOpenLoading}>
                        Open Playground
                    </Button>
                </Flex>
                {error && <Text color="red.500" mt={2}>{error}</Text>}
            </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default PlaygroundIntroModal;


