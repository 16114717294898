import {
    Text, Code, Divider, Flex, Tooltip, HStack, Icon, Box
} from "@chakra-ui/react";
import {DollarIcon, SmallCrossIcon, SmallTickIcon, StopwatchIcon} from "evergreen-ui";
import React from "react";
import {CheckIcon, WarningIcon} from "@chakra-ui/icons";

const CellResultHistory = ({evals, test_prompt, result, usage, passed, isChat=false}) => {

    if (typeof result !== 'string') {
        // result of a function call
        result = JSON.stringify(result);
    }


    return (
        test_prompt && test_prompt[test_prompt.length - 1].content !== null && test_prompt[test_prompt.length - 1].content !== "" && <div>
            <Box pt={2} pb={2} borderLeft={isChat && result && result.trim() !== "" && result !== "null" ? "4px solid orange" : "none"}
    pl={isChat && result && result.trim() !== "" && result !== "null" ? 5 : 0}>
                {(!isChat || (isChat && result && result.trim() !== "" && result !== "null")) && <Flex>
                    <Text width="80px">Stats:</Text>
                    <HStack spacing={4} flex="1" whiteSpace="pre-wrap">
                        <Tooltip label="Test Status" fontSize="sm">
                            <HStack spacing={4}>
                                <HStack>
                                    <Icon as={passed ? SmallTickIcon : SmallCrossIcon}
                                          color={passed ? "green.500" : "red.500"}/>
                                    <Text color={passed ? "green.500" : "red.500"}>{passed ? "Passed" : "Failed"}</Text>
                                </HStack>
                            </HStack>
                        </Tooltip>
                        <Tooltip label="Total Time" fontSize="sm">
                            <HStack>
                                <Icon as={StopwatchIcon}/>
                                <Text>{parseFloat(usage.time).toFixed(5)}s</Text>
                            </HStack>
                        </Tooltip>
                        <Tooltip label="Prompt Tokens / Completion Tokens" fontSize="sm">
                            <HStack>
                                <Icon viewBox="0 0 24 24">
                                    <path fill="none" stroke="black" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"/>
                                </Icon>
                                <Text>{usage.prompt_tokens} / {usage.completion_tokens}</Text>
                            </HStack>
                        </Tooltip>
                        <Tooltip label="Total Cost" fontSize="sm">
                            <HStack>
                                <Icon as={DollarIcon}/>
                                <Text>{parseFloat(usage.cost).toFixed(5)}</Text>
                            </HStack>
                        </Tooltip>
                    </HStack>
                </Flex>}
                {<Flex mt={2}>
                    <Text width="80px">Prompt:</Text>
                    { 
                        (isChat && Array.isArray(test_prompt))
                        ? (
                            typeof test_prompt[test_prompt.length - 1] === 'object' && !Array.isArray(test_prompt[test_prompt.length - 1])
                            ? (
                                <Flex direction="column" flex="1">
                                    {Object.entries(test_prompt[test_prompt.length - 1]).slice(0, 2).map(([key, value], index) => (
                                        <HStack key={index}>
                                            {<Text>{key}:</Text>}
                                            <Code whiteSpace="pre-wrap">
                                                {key === 'role' 
                                                    ? <b>{JSON.stringify(value)}</b>
                                                    : JSON.stringify(value)
                                                }
                                            </Code>
                                        </HStack>
                                    ))}
                                </Flex>
                            ) 
                            : <Code flex="1" whiteSpace="pre-wrap">{JSON.stringify(test_prompt[test_prompt.length - 1])}</Code>
                        ) 
                        : (
                            typeof test_prompt === 'object' && !Array.isArray(test_prompt) && test_prompt !== null
                            ? (
                                <Flex direction="column" flex="1">
                                    {Object.entries(test_prompt).slice(0, 2).map(([key, value], index) => (
                                        <HStack key={index}>
                                            {<Text>{key}:</Text>}
                                            <Code whiteSpace="pre-wrap">
                                                {key === 'role' 
                                                    ? <b>{JSON.stringify(value)}</b>
                                                    : JSON.stringify(value)
                                                }
                                            </Code>
                                        </HStack>
                                    ))}
                                </Flex>
                            ) 
                            : <Code flex="1" whiteSpace="pre-wrap">
                                {typeof test_prompt === 'string' ? test_prompt : JSON.stringify(test_prompt)}
                            </Code>
                        )
                    }
                </Flex>}
                {(!isChat || (isChat && result && result.trim() !== "" && result !== "null")) && <Flex mt={2}>
                    <Text width="80px">Result:</Text>
                    <Code flex="1" whiteSpace="pre-wrap">{result}</Code>
                </Flex>}
                {evals.map((evalItem, index) => (
                    <Flex mt={2} key={index}>
                        <Text width="80px">Assert:</Text>
                        <Box flex="1">
                            <Text>{evalItem.assertion.assert_type}: {evalItem.assertion.assert_string}</Text>
                            <Tooltip label={evalItem.msg} fontSize="sm">
                                <Text color={evalItem.status === 'passed' ? 'green.500' : 'red.500'}>
                                    {evalItem.status === 'passed' ? <CheckIcon/> : <WarningIcon/>} {evalItem.status}
                                </Text>
                            </Tooltip>
                        </Box>
                    </Flex>
                ))}
            </Box>
            <Divider mb={2} orientation='horizontal' borderWidth={'2px'}/>
            </div>
    );
};

export default CellResultHistory;
