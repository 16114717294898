import { 
    Modal, ModalOverlay, ModalContent, ModalHeader, 
    ModalBody, ModalCloseButton, HStack, VStack, Box, 
    Button, Text, Image, Input, Spacer, useToast, Stack
} from "@chakra-ui/react";
import { useState } from "react";
import Papa from 'papaparse';
import ChatTestSelectModal from "./ChatTestSelectModal";



const ImportChatCSVModal = ({ isOpen, onClose, onUpload }) => {
    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState("");
    const toast = useToast();

    const [showChatTestModal, setShowChatTestModal] = useState(false);
    const [parsedData, setParsedData] = useState([]);

    const resetFile = () => {
        setFileData(null);
        setFileName("");
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setFileName(file.name);

        const reader = new FileReader();
        reader.onload = (e) => {
            setFileData(e.target.result);
        };
        reader.readAsText(file);
    };

    const handleSave = () => {
        if (fileData) {
            processCSV(fileData);
            setShowChatTestModal(true);
            resetFile();
        } else {
            toast({
                title: "No file uploaded.",
                description: "Please upload a CSV file first.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const processCSV = (content) => {
        let processedData = [];

        const validateRole = (role) => {
            switch (role.toLowerCase()) {
                case 'ai': return 'assistant';
                case 'sys': return 'system';
                case 'func':
                case 'fn': return 'function';
                default: return role.toLowerCase();
            }
        };

        let isFirstRow = true;
        let roleIndex = 2; // default to 2
        let contentIndex = 3; // default to 3

        Papa.parse(content, {
            header: false,
            skipEmptyLines: true,
            step: function(row) {
                // For the first row, detect the positions of "role" and "content"
                if (isFirstRow) {
                    roleIndex = row.data.indexOf("role");
                    contentIndex = row.data.indexOf("content");
                    isFirstRow = false;  // set the flag to false after the first row
                    return; // skip processing for the first row
                }
    
                // Extract data using the detected indices
                if (row.data && roleIndex !== -1 && contentIndex !== -1) {
                    const transformedRole = validateRole(row.data[roleIndex]);
                    processedData.push({
                        role: transformedRole,
                        content: row.data[contentIndex]
                    });
                }
            },
            complete: function() {
                setParsedData(processedData);
                resetFile();
            }
        });
      };

    return (
        <Modal isOpen={isOpen} onClose={() => { onClose(); resetFile(); }} size={"2xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Import Chat from CSV</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <HStack spacing={8} align="stretch">
                        <VStack flex={1} spacing={3} alignItems="start">
                            <Text>Format your csv rows as (in order):</Text>
                            <Text> - Chat ID</Text>
                            <Text> - index (in message list)</Text>
                            <Text> - role</Text>
                            <Text> - content</Text>
                        </VStack>
                        <VStack flex={2} alignItems="center" spacing={4}>
                            <Image 
                                src="https://i.ibb.co/h8NGHBm/Screen-Shot-2023-09-02-at-4-23-41-PM.png" 
                                alt="Example input" 
                                maxWidth="100%"
                                maxHeight="100%"
                                fit="contain"
                            />
                            <Spacer />
                            <Stack spacing={3} pb={4}>
                                <HStack>
                                    <Input 
                                        type="file" 
                                        accept=".csv" 
                                        hidden 
                                        onChange={handleFileChange} 
                                        id="csv-upload"
                                    />
                                    <Button onClick={() => document.getElementById('csv-upload').click()}>
                                        Upload
                                    </Button>
                                    <Button colorScheme="blue" onClick={handleSave}>
                                        Import 
                                    </Button>
                                </HStack>
                                <Text>{fileName}</Text>
                            </Stack>
                        </VStack>
                    </HStack>
                </ModalBody>
            </ModalContent>
            {parsedData && <ChatTestSelectModal 
                isOpen={showChatTestModal}
                onClose={() => {
                    setShowChatTestModal(false)
                    onClose()
                }}
                parsedData={parsedData}
                onUpload={onUpload}
            />}
        </Modal>
    );
};

export default ImportChatCSVModal;
