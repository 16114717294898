import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    HStack,
    Text, Select, Flex, Button
} from '@chakra-ui/react';
import {TrashIcon, PlusIcon, PlayIcon} from 'evergreen-ui';
import {useState, useEffect} from 'react';
import axios from "axios";
import variableConfig from "./VariableConfig";
import {useUtilityFunctions} from "../UtilityFunctions";
import RetrievalConfig from "../Integrations/Document-Retrieval/RetrievalConfig";

const VariableConfig = ({suiteData, setSuiteData, workspaceDataID}) => {
    const {getAccessToken} = useUtilityFunctions();

    const [variables, setVariables] = useState(suiteData.variables);
    const varTypes = ['float', 'int', 'bool', 'array', 'json', 'string', 'retrieval'];


    const handleTypeChange = (variableName, newType) => {
        const newVariables = {...variables};
        newVariables[variableName].type = newType;
        setVariables(newVariables);
        setSuiteData({
            ...suiteData,
            variables: newVariables
        });
        updateVariables();
    };

    useEffect(() => {
        setVariables(suiteData.variables);
    }, [suiteData]);


    const updateVariables = async () => {
        try {
         
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${suiteData._id}/variables`,
                {
                    variables: variables,
                }, {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    },
                });
        
        } catch (error) {
            console.error("Error updating the variables", error);
        }
    };


    return (
        <Box>
            <Accordion allowToggle>
                <AccordionItem key={'hi'}>
                    <AccordionButton>
                        <HStack justifyContent="start" w="100%">
                            <AccordionIcon/>
                            <Text fontSize="md">Variable Configuration</Text>
                        </HStack>
                    </AccordionButton>
                    <AccordionPanel>
                        {Object.keys(variables).map((variableName) => (
                            <Flex key={variableName} justify="space-between" align="center" mb={2}>
                                <Text>{variableName}</Text>
                                <HStack>
                                    {variables[variableName].type === 'retrieval' && (
                                        <RetrievalConfig
                                            variableName={variableName}
                                            variables={variables}
                                            setVariables={setVariables}
                                            suiteData={suiteData}
                                            setSuiteData={setSuiteData}
                                            updateVariables={updateVariables}
                                            workspaceDataID={workspaceDataID}
                                        />
                                    )}
                                    <Select
                                        size={'sm'}
                                        borderRadius="md"
                                        width="200px" // Set the desired width here
                                        value={variables[variableName].type || 'string'}
                                        onChange={(e) => handleTypeChange(variableName, e.target.value)}
                                    >
                                        {varTypes.map((type) => (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </Select>
                                </HStack>
                            </Flex>
                        ))}
                    </AccordionPanel>

                </AccordionItem>
            </Accordion>
        </Box>
    );
}

export default VariableConfig;
