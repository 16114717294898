import { Switch, FormLabel, FormControl, Flex, Button, Box } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from 'axios';
import {useUtilityFunctions} from "../UtilityFunctions";
import { UploadIcon } from 'evergreen-ui';

const OtherCollectionSettings = ({ collection, workspaceData, setWorkspaceData }) => {
    const [isSwitchOn, setSwitchOn] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const {getAccessToken} = useUtilityFunctions();
    const [saveIsLoading, setSaveIsLoading] = useState(false)

    useEffect(() => {
        if (workspaceData && workspaceData.collections) {
            const targetCollection = workspaceData.collections.find(col => col.id === collection.id);
            if (targetCollection && targetCollection.settings && targetCollection.settings.default_all_routers !== undefined) {
                setSwitchOn(targetCollection.settings.default_all_routers);
            }
        }
    }, [workspaceData, collection]);

    const handleToggleSwitch = () => {
        setSwitchOn(prevState => !prevState);
        setUnsavedChanges(true);
    };

    const handleSave = async () => {
        try {
            setSaveIsLoading(true)
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/update-default-all-routers`, {
                workspace_id: workspaceData._id,
                collection_id: collection.id,
                default_all_routers: isSwitchOn
            }, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                },
            });

            if (response.data.msg === "default_all_routers setting updated successfully") {
                const updatedWorkspaceData = { ...workspaceData };
                const targetCollection = updatedWorkspaceData.collections.find(col => col.id === collection.id);
                if (targetCollection) {
                    if (!targetCollection.settings) {
                        targetCollection.settings = {};
                    }
                    targetCollection.settings.default_all_routers = isSwitchOn;
                } 
                setWorkspaceData(updatedWorkspaceData);
                setUnsavedChanges(false);
                setSaveIsLoading(false)
            }
            setSaveIsLoading(false)
        } catch (error) {
            setSaveIsLoading(false)
            console.error("Error updating default_all_routers setting:", error);
        }
    };

    return (
        <Flex justify="space-between" align="center" mb={4} w="100%">
            <Flex align="center" flexGrow={1}>
                <FormLabel htmlFor="enable-routers-switch" mb="0" mr={2}>
                    Default new cells have use routers enabled, all routers selected, and GPT-4
                </FormLabel>
                <Switch 
                    id="enable-routers-switch"
                    isChecked={isSwitchOn}
                    onChange={handleToggleSwitch}
                    colorScheme="orange"
                />
            </Flex>
            <Button colorScheme="green" onClick={handleSave} disabled={!unsavedChanges} leftIcon={<UploadIcon />} size={"sm"} isLoading={saveIsLoading}>
                Save Settings
            </Button>
        </Flex>
    );
}

export default OtherCollectionSettings;
