import { Box, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, HStack, Input, Icon, useOutsideClick, Text, Spacer, Select, Flex, useToast, Heading, Circle, Tooltip, Button, Badge } from '@chakra-ui/react';
import { TrashIcon, PlusIcon, PlayIcon, LabTestIcon } from 'evergreen-ui';
import { useState, useEffect, useRef } from 'react';
import AssertionList from './AssertionList';
import axios from 'axios';
import CellTestOutput from './CellTestOutput'
import StatsPanel from './StatsPanel'
import {useUtilityFunctions} from "../UtilityFunctions";
import VariableInput from "./VariableInputUI";

const Test = ({ testId, chainID, cells, suiteData, setSuiteData, 
    onVarMappingChange, chainIsRunning, currRunState, 
    setCurrRunState, workspaceDataID, workspaceData, deleteTest, chainData, isLast, testIndex}) => {
    const testData = suiteData.tests.find(test => test.id === testId);
    const {getAccessToken, isColorDark, extractNumberFromVarName} = useUtilityFunctions();

    const [testName, setTestName] = useState(
        (testData.name && testData.name.trim() !== "") ? testData.name : "[Untitled Test]"
    );
  const [evaluation, setEvaluation] = useState("Run full test or suite to see evaluations.");
  const [isEditing, setIsEditing] = useState(false); 
  const nameInputRef = useRef();
  const toast = useToast();
  const [cellRunningStates, setCellRunningStates] = useState({ cell_ids: [] });
  const [testChainIsRunning, setTestChainIsRunning] = useState(false);

  const [chatLogic, setChatLogic] = useState({
    tested_cells: [],
    message_properties: []
  });

  const [isOpenIndex, setIsOpenIndex] = useState(false);

  const updateChatLogicInDb = async (updatedChatLogic) => {
      try {
          // Make the API call
          const response = await axios.put(
              `${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${suiteData._id}/tests/${testId}/chat-logic`, 
              updatedChatLogic, 
              {
                  headers: {
                      'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                  },
              }
          );

          // Update local state upon successful request
          if (response.status === 200) {
              const updatedSuiteData = { ...suiteData };
              const testToUpdate = updatedSuiteData.tests.find(test => test.id === testId);
              if (testToUpdate) {
                testToUpdate.chat_logic = updatedChatLogic;
              }
              setSuiteData(updatedSuiteData);
              setChatLogic(updatedChatLogic);
          }
      } catch (error) {
          console.error("Error updating the chat logic", error);
      }
  };

  const isChat = chainData && chainData.config && chainData.config.type && chainData.config.type === "chat"


  useEffect(() => {
    if (testData?.chat_logic && (chatLogic !== testData?.chat_logic)) {
        setChatLogic(testData.chat_logic);
    }
}, [testData]);


//   testData.var_mapping = {
//     "v": "Value 1",
//     "var2er": "Value 2",
//     "var3ewjowrfwrwrw": "Value 3",
//   };

const [currTestRunState, setCurrTestRunState] = useState(currRunState.test_results.find(result => result.testID === testId));
let collectionForChain = workspaceData.collections.find(collection =>
        collection.chains.some(chain => chain.id === chainID)
    );


  useOutsideClick({
    ref: nameInputRef,
    handler: () => {
      if(isEditing) {
        if (testName.trim() === "") {
          setTestName("[Untitled Test]");
        }
        setIsEditing(false);
      }
    },
  });

  useEffect(() => {
    if (currRunState.test_results) {
        if (currTestRunState && currTestRunState.test_stats && currTestRunState.test_stats.stats) {
            const percentSuccess = Number(currTestRunState.test_stats.stats.percent_success).toFixed(2);
            setEvaluation(String(percentSuccess));
        } else {
            setEvaluation("Run full test or suite to see evaluations.");
        }
    }
}, [currTestRunState]);



  useEffect(() => {
    setCurrTestRunState(currRunState.test_results.find(result => result.testID === testId))
  }, [currRunState]);


  const runChainOnTest = async () => {
    try {
        // update workspace, get vault ID
        setTestChainIsRunning(true)
        let updatedWorkspace = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/byID/${workspaceDataID}`, 
        {
            headers: {
                'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
            },
        });
        if (!updatedWorkspace.data.aux.vault_id) {
            toast({
                title: "No vault found",
                description: "Please choose an API key vault before running",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setCellRunningStates(prevState => ({
                ...prevState, 
                cell_ids: []
              }));
              setTestChainIsRunning(false)
            return;
        }
        const updatedWorkspaceVault = updatedWorkspace.data.aux.vault_id

        const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/chain/${chainID}/run/test/${testId}`, 
            {
                vault_id: updatedWorkspaceVault,
            }, 
            {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
            });

            const history = response.data


            if (history && history.timestamp && history.stats && history.tests && history.tests[0].cell_results) {
                 // Update currRunState here.
                 let updatedState = { ...currRunState };

                    // Initialize test_results to empty array if it's undefined
                    let test_results = currRunState.test_results || [];
                
                    // Check if a test_result with testId exists
                    const testExists = test_results.some(test_result => test_result.testID === testId);
                
                    if (!testExists) {
                        // If test_result does not exist, add a new one
                        test_results = test_results.concat({
                            testID: testId,
                            test_stats: {
                                timestamp: history.timestamp,
                                stats: history.stats
                            },
                            cell_results: history.tests[0].cell_results.map((cell_result, index) => ({
                                cell_id: cells[index]._id,
                                timestamp: history.timestamp,
                                timestampIsStandout: true,
                                result: cell_result.result,
                                usage: cell_result.usage,
                                evals: cell_result.evals,
                                passed: cell_result.passed
                            }))
                        });
                    } else {
                        // If test_result exists, update it
                        test_results = test_results.map(test_result => {
                            if (test_result.testID === testId) {
                                const updatedTestResult = {
                                    ...test_result,
                                    test_stats: {
                                        timestamp: history.timestamp,
                                        stats: history.stats
                                    },
                                    cell_results: history.tests[0].cell_results.map((cell_result, index) => ({
                                        cell_id: cells[index]._id,
                                        timestamp: history.timestamp,
                                        timestampIsStandout: true,
                                        result: cell_result.result,
                                        usage: cell_result.usage,
                                        evals: cell_result.evals,
                                        passed: cell_result.passed
                                    }))
                                };
                               
                                return updatedTestResult;
                            } else {
                                return test_result;
                            }
                        });
                    }

                    updatedState.test_results = test_results;

                    
                    setCurrRunState(updatedState);
                    let updatedSuiteData = {
                      ...suiteData,       
                      state: updatedState
                    };
                    setSuiteData(updatedSuiteData)

                setTestChainIsRunning(false)

                try {
                  const runStatePutResponse = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/suites/${suiteData._id}/state`, updatedState, {
                    headers: {
                      'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    },
                  });
  
              } catch (error) {
                console.error('Failed to update the current run state:', error);
                toast({
                  title: "Couldn't save.",
                  description: "Couldn't save the run state, please check your network connection.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }

            }
        } catch (e) {
          toast({
            title: "There was a problem.",
            description: "Please make sure your model is selected and your vault has correct keys for the selected models.",
            status: "error",
            duration: 5000,
            isClosable: true,
        });
            setTestChainIsRunning(false)
            console.error(e)
        }
  }

  const runTestOnCell = async (cell_id) => {
      try {
        // update workspace, get vault ID
        setCellRunningStates(prevState => ({
            ...prevState, 
            cell_ids: [...prevState.cell_ids, cell_id]
          }));
        let updatedWorkspace = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/byID/${workspaceDataID}`, 
        {
            headers: {
                'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
            },
        });
        if (!updatedWorkspace.data.aux.vault_id) {
            toast({
                title: "No vault found",
                description: "Please choose an API key vault before running",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setCellRunningStates(prevState => ({
                ...prevState, 
                cell_ids: []
              }));
            return;
        }
        const updatedWorkspaceVault = updatedWorkspace.data.aux.vault_id

        const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cell_id}/run/test/${testId}`, 
            {
                vault_id: updatedWorkspaceVault,
            }, 
            {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
            });

            const history = response.data


            const newCellResult = {
                cell_id: history.cell_id,
                timestamp: history.timestamp,
                timestampIsStandout: true,
                result: history.tests[0].result,
                usage: history.tests[0].usage,
                evals: history.tests[0].evals,
                passed: history.tests[0].passed,
            };

            let newState = {...currRunState};

            if (newState && newState.test_results) {
                const testResult = newState.test_results.find((result) => result.testID === testId);
                if (testResult && testResult.cell_results) {
                    const cellResult = testResult.cell_results.find((result) => result.cell_id === cell_id);
                    if (cellResult) {
                        // update existing cell_result with new data
                        Object.assign(cellResult, newCellResult);
                    } else {
                        // add new cell_result
                        testResult.cell_results.push(newCellResult);
                    }
                } else if (testResult) {
                     // add new cell_results array with newCellResult as its first element
                     testResult.cell_results = [newCellResult];
                }
                 else {
                    const newTestResult = {
                        testID: testId,
                        cell_results: [newCellResult],
                        stats: history.stats
                    }
                    newState.test_results.push(newTestResult)
                }
            } else {
                // create new currRunState with test_results containing the new cell result object
                newState = {
                    chain_stats: newState.chain_stats,
                    test_results: [
                        {
                            testID: testId,
                            cell_results: [newCellResult],
                            stats: history.stats
                        },
                    ],
                };
            }

            // update currRunState using the setCurrRunState method
            setCurrRunState(newState);
            let updatedSuiteData = {
              ...suiteData,       
              state: newState
            };
            setSuiteData(updatedSuiteData)

            try {
                const runStatePutResponse = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/suites/${suiteData._id}/state`, newState, {
                  headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                  },
                });


             
            } catch (error) {
              console.error('Failed to update the current run state:', error);
              toast({
                title: "Couldn't save.",
                description: "Couldn't save the run state, please check your network connection.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }

            
            setCellRunningStates(prevState => ({
                ...prevState, 
                cell_ids: prevState.cell_ids.filter(id => id !== cell_id)
              }));
        } catch (e) {
            setCellRunningStates(prevState => ({
                ...prevState, 
                cell_ids: prevState.cell_ids.filter(id => id !== cell_id)
              }));
              toast({
                title: "There was a problem.",
                description: "Please make sure your model is selected and your vault has correct keys for the selected models.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.error(e)
        }
    }

  
  const handleNameChange = (e) => {
    setTestName(e.target.value);
  }

  // Async function to update the test name in the DB.
    const updateTestNameInDb = async () => {
        let newName = String(testName)
        if(testName.trim() === "") {
            setTestName("[Untitled Test]")
            newName = "[Untitled Test]"
        }
        try {

              const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${suiteData._id}/tests/${testId}/name`, 
              {
                  name: newName,
              }, {
                headers: {
                  'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
              });

              if (response.status === 200) {  // Assuming a 200 status code means success
                // Create a new updated suiteData object
                const updatedSuiteData = { ...suiteData };
                const testToUpdate = updatedSuiteData.tests.find(test => test.id === testId);
                if (testToUpdate) {
                    testToUpdate.name = newName;
                }
                setSuiteData(updatedSuiteData);  // Update the local state with the new suiteData
            }
        } catch (error) {
            console.error("Error updating the test name", error);
        }
    };

    function getLabelById(label_id, collection) {
      const label = collection?.labels?.find(label => label.id === label_id);
      return label || { id: "", label_name: "No label", label_color: "#e6e6e6" };
    }


    const [testLabel, setTestLabel] = useState((testData && testData.label_id) ? getLabelById(testData.label_id, collectionForChain) : { id: "", label_name: "No label", label_color: "#e6e6e6" });

    const [showLabelDropdown, setShowLabelDropdown] = useState(false);

    const labelDropdownRef = useRef(null);

    const updateTestLabelInDb = async (newLabelId) => {
      try {
          const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${suiteData._id}/tests/${testId}/label`, 
          {
              id: newLabelId,
          }, {
            headers: {
              'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
            },
          });
  
          if (response.status === 200) {
              const updatedSuiteData = { ...suiteData };
              const testToUpdate = updatedSuiteData.tests.find(test => test.id === testId);
              if (testToUpdate) {
                  testToUpdate.label_id = newLabelId;
              }
              setSuiteData(updatedSuiteData);
          }
      } catch (error) {
          console.error("Error updating the test label", error);
      }
  };

    useEffect(() => {
        function handleClickOutside(event) {
            if (labelDropdownRef.current && !labelDropdownRef.current.contains(event.target)) {
              setShowLabelDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [labelDropdownRef]);


    if (isOpenIndex) {
      console.log(isOpenIndex)
    }

    const addAllUserCellsToTestedCells = () => {
      const userCells = chatLogic?.message_properties?.filter(prop => prop.role === 'user') || [];
      
      const newTestedCells = userCells.map(prop => ({ cell_id: prop.cell_id }));
  
      const updatedTestedCells = [
          ...chatLogic.tested_cells,
          ...newTestedCells.filter(newCell => 
              !chatLogic.tested_cells.some(testedCell => 
                  testedCell.cell_id === newCell.cell_id))
      ];
  
      const updatedChatLogic = {
          ...chatLogic,
          tested_cells: updatedTestedCells
      };
  
      updateChatLogicInDb(updatedChatLogic);
  };

  return (
    <Box mb={isLast ? "30%" : 0}>
      <Accordion allowToggle onChange={(index) => setIsOpenIndex(index !== null)}>
        <AccordionItem key={testId}>
          <AccordionButton>
            <HStack justifyContent="space-between" w="100%">
                <AccordionIcon/>

                <Text color="gray.500" fontSize={"80%"} ml={-1}>{testIndex + 1}</Text>

                {collectionForChain && collectionForChain.labels && !showLabelDropdown &&
                <Badge 
                  colorScheme="gray" 
                  backgroundColor={testLabel.label_color} 
                  color={isColorDark(testLabel.label_color) ? "white" : "black"} 
                  mr={2}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowLabelDropdown(true);
                  }}>
                  {testLabel.label_name}
                </Badge>
                }


                {showLabelDropdown &&
                <div ref={labelDropdownRef}>
                  <Select 
                    size="sm"
                    value={testLabel.id}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      const selectedLabel = collectionForChain.labels.find(label => label.id === e.target.value);
                      if (selectedLabel) {
                        setTestLabel(selectedLabel);
                        updateTestLabelInDb(selectedLabel.id);
                      } else {
                        setTestLabel({ id: "", label_name: "No label", label_color: "#e6e6e6" });
                        updateTestLabelInDb("");
                      }
                      setShowLabelDropdown(false);
                    }}
                  >
                    <option value="" style={{ backgroundColor: "#e6e6e6" }}>No label</option>
                    {collectionForChain.labels.map(label => (
                     <option 
                          key={label.id} 
                          value={label.id} 
                     >
                     {label.label_name}
                     </option>
                    ))}
                  </Select>
                </div>
                }

              {isEditing ? (
                <Input
                  ref={nameInputRef}
                  value={testName}
                  onChange={handleNameChange}
                  onBlur={() => {setIsEditing(false); updateTestNameInDb()}}
                  size="sm"
                  autoFocus
                  maxW="10%" // Restricting width of input field
                />
              ) : (
                <Text onClick={(e) => {
                    e.stopPropagation();
                    setIsEditing(true);
                  }}>
                  {testName}
                </Text>
              )}
              <Spacer />
              
              <Tooltip label={currTestRunState && currTestRunState.test_stats && currTestRunState.test_stats.timestamp ? 
           `% Passed, as of ${new Date(currTestRunState.test_stats.timestamp * 1000).toLocaleString()}` : 
           "Run full test or suite to see evaluations."}  fontSize="md">
                    <HStack spacing="8px">
                    <Icon viewBox="0 0 24 24">
                    <path fill="none" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                    </Icon>
                    <Text>{currTestRunState && currTestRunState.test_stats && currTestRunState.test_stats.timestamp ? 
                        Number(evaluation).toFixed(1) + '%' : 
                        "Run full test or suite to see evaluations."}
                    </Text>
                    </HStack>
                </Tooltip>
              <Spacer />
              <Box>
                <Icon as={TrashIcon} color="danger" onClick={(e) => {
                    e.stopPropagation();
                    deleteTest(testId);
                  }}/>
              </Box>
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            {isOpenIndex ? (
              <div>
              <Button 
                  leftIcon={<Icon as={PlayIcon} />} 
                  mb={4}
                  fontSize={'sm'}
                  size="sm"
                  fontWeight={600}
                  colorScheme="green"
                  borderRadius="4px"
                  isLoading={testChainIsRunning}
                  onClick={(e) => {
                      e.stopPropagation();
                      runChainOnTest();
                  }}>
                  Run Test on Chain
              </Button>


                  {(currTestRunState && currTestRunState.test_stats && currTestRunState.test_stats.stats && currTestRunState.test_stats.timestamp) && (
                      <>
                      <StatsPanel runTime={currTestRunState.test_stats.stats.time} promptTokens={currTestRunState.test_stats.stats.prompt_tokens} completionTokens={currTestRunState.test_stats.stats.completion_tokens} 
                      passPercent={currTestRunState.test_stats.stats.percent_success} dollarCost={currTestRunState.test_stats.stats.cost} isCollapsed={false}/>
                      <Text color="gray.500" fontSize="sm" mb={5} >From run at: {new Date(currTestRunState.test_stats.timestamp * 1000).toLocaleString()}</Text>
                      </>
                  )}

                {isChat && <Button 
                  leftIcon={<Icon as={LabTestIcon} />} 
                  mb={4}
                  fontSize={'sm'}
                  size="sm"
                  fontWeight={600}
                  colorScheme="blue"
                  borderRadius="4px"
                  onClick={(e) => {
                      e.stopPropagation();
                      addAllUserCellsToTestedCells()
                  }}>
                  Test all "User" messages.
              </Button>}

              {isChat && suiteData && suiteData.variables && cells && 
                    (() => {
                      const unsortedVariableKeys = suiteData && suiteData.variables ? Object.keys(suiteData.variables) : [];
                      // sometimes added in a weird order by the bulk add, just ensure in correct order
                      const variableKeys = unsortedVariableKeys.sort((a, b) => extractNumberFromVarName(a) - extractNumberFromVarName(b));
                      const maxLength = Math.max(variableKeys.length, cells ? cells.length : 0);
                      const results = [];
                      
                      for (let i = 0; i < maxLength; i++) {
                        let isInTestedCells = false;
                        
                        if (i < cells.length && chatLogic && chatLogic.tested_cells && cells[i]._id && chatLogic.tested_cells.some(cell => cell.cell_id === cells[i]._id)) {
                          isInTestedCells = true;
                        }

                        if (i < variableKeys.length) {
                          const varName = variableKeys[i];
                          const cell_id = cells[i]?._id

                          results.push(
                            testData.var_mapping[varName] && cell_id &&  <VariableInput
                                defaultValue={testData.var_mapping[varName] || ''}
                                onVarMappingChange={onVarMappingChange}
                                testId={testId}
                                varName={varName}
                                index={i}
                                isInTestedCells={isInTestedCells}
                                chatLogic={chatLogic}
                                updateChatLogicInDb={updateChatLogicInDb}
                                cellID={cell_id}
                              />
                          );
                        }

                        if (cells && i < cells.length) {
                          const cell = cells[i];
                          const cellID = cell._id;
                          if(isInTestedCells) {
                            results.push(
                              <Box key={i} mb={10}>
                                <Heading size="sm" fontWeight="normal" mb={4}>Output of Message {i+1}</Heading>
                                <CellTestOutput cell={cell} cellIndex={i} chainID={chainID} chainIsRunning={chainIsRunning} testId={testId} 
                                currRunState={currRunState} setCurrRunState={setCurrRunState}/>
                                <AssertionList workspaceDataID={workspaceDataID} workspaceData={workspaceData} cell={cell} cellIndex={i} testData={testData} testId={testId} suiteId={suiteData._id} 
                    currRunState={currRunState} setCurrRunState={setCurrRunState} suiteData={suiteData} setSuiteData={setSuiteData} chainIsRunning={chainIsRunning} chainID={chainID}/>
                              </Box>
                            );
                          }
                        }
                      }
                      return results;
                    })()
                  }


              {!(isChat) && suiteData && suiteData.variables && Object.keys(suiteData.variables).map((varName, index) => (
                    <VariableInput
                        defaultValue={testData.var_mapping[varName] || ''}
                        onVarMappingChange={onVarMappingChange}
                        testId={testId}
                        varName={varName}
                        index={index}
                        chatLogic={chatLogic}
                        updateChatLogicInDb={updateChatLogicInDb}
                        cellID={null}
                    />))}
              
              

            {!(isChat) && cells && cells.map((cell, index) => (
                <Box key={index} mb={10}>
                  <Heading size="sm" fontWeight="normal" mb={4}>Output of Cell {index+1}</Heading>
                  <CellTestOutput cell={cell} cellIndex={index} chainID={chainID} chainIsRunning={chainIsRunning} testId={testId} 
                  currRunState={currRunState} setCurrRunState={setCurrRunState}/>

                  <AssertionList workspaceDataID={workspaceDataID} workspaceData={workspaceData} cell={cell} cellIndex={index} testData={testData} testId={testId} suiteId={suiteData._id} 
                  currRunState={currRunState} setCurrRunState={setCurrRunState} suiteData={suiteData} setSuiteData={setSuiteData} chainIsRunning={chainIsRunning} chainID={chainID}/>

                  <Button 
                      leftIcon={<Icon as={PlayIcon} />} 
                      mt={4}
                      fontSize={'xs'}
                      size="xs"
                      fontWeight={600}
                      color={'white'}
                      bg={'#ED7547'}
                      href={'#'}
                      _hover={{
                      bg: '#D05D32',
                      }}
                      borderRadius="4px"
                      isLoading={cellRunningStates.cell_ids.includes(cell._id)}
                      onClick={(e) => {
                      e.stopPropagation();
                          runTestOnCell(cell._id)
                      }}>
                    Run Test on Cell {index+1}
                  </Button>

                </Box>
              ))}
              </div>
            ): (
              <Text>Loading...</Text>  // Or replace with a loading spinner or another indicator
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}

export default Test;
