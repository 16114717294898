import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, useToast, UnorderedList, ListItem, Text, 
Stat, StatLabel, StatNumber, Badge, Textarea, Accordion, 
AccordionItem, 
AccordionButton, 
AccordionPanel, 
AccordionIcon, 
Input, Box, Alert, AlertIcon } from "@chakra-ui/react";
import {PlayIcon, EyeOpenIcon} from 'evergreen-ui';
import React, { useState } from 'react';
import axios from "axios";
import {useUtilityFunctions} from "../UtilityFunctions";


const CollectionRunModal = ({ collectionID, workspaceData, setWorkspaceData, updateStartingMessages }) => {
    const [isRunModalOpen, setIsRunModalOpen] = useState(false);
    const toast = useToast();
    const {getAccessToken} = useUtilityFunctions();
    const [collectionRunIsLoading, setCollectionRunIsLoading] = useState(false)
    


    const getSystemPrompt = () => {
        // Find the first message with a role of "system" from starting_messages
        if (workspaceData.collections.find(col => col.id === collectionID)) {
            const systemMessage = workspaceData.collections.find(col => col.id === collectionID).starting_messages.find(msg => msg.role === 'system');
    
            // If the systemMessage exists, return its content, otherwise, return an empty string
            return systemMessage ? systemMessage.content : '';
        }
        return ''
    };

    const handleRunClick = async () => {
        setCollectionRunIsLoading(true)
        await updateStartingMessages();


        try {
            // Get the vault_id from workspaceData
            const vault_id = workspaceData.aux.vault_id;
    
            // Call the API endpoint to run the collection
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceData._id}/run/${collectionID}`,
                {
                    vault_id: vault_id,
                    system_prompt: getSystemPrompt()
                },
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                    },
                }
            );
    
            // Check the response or do some handling here if needed
            if (response.data) {
                setCollectionRunIsLoading(false);
                fetchCompareRuns()
            }
        } catch (error) {
            console.error("Error running the collection: ", error);
            toast({
                title: "Could not run.",
                description: "Ensure your vault and all cells are configured correctly.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setCollectionRunIsLoading(false)
            // Handle the error, maybe show a notification to the user
        }
    };

    const [compareData, setCompareData] = useState(null);

    const fetchCompareRuns = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceData._id}/compare/${collectionID}`,
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                    },
                }
            );
            setCompareData(response.data);
            setIsRunModalOpen(true);
        } catch (error) {
            console.error("Error fetching comparison data: ", error);
            toast({
                title: "No comparison data found.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleClose = () => {
        setIsRunModalOpen(false);
        setCollectionRunIsLoading(false);
        setCompareData(null);  // Reset the compareData when closing the modal
    };

    const getBoxShade = (percent) => {
        if (percent >= 90) return "green.300";
        if (percent >= 50) return "yellow.300";
        return "red.300";
    }

    const renderEvals = (evals) => (
        evals.map((evalObj, idx) => (
            <AccordionItem key={idx}>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        <Badge colorScheme={evalObj.status === 'passed' ? 'green' : 'red'}>
                            {evalObj.status} eval
                        </Badge>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    Type: <Badge colorScheme="grey">{evalObj.assertion.assert_type}</Badge>
                    <br/>
                    <br/>
                    Reasoning: <Textarea value={evalObj.msg} isReadOnly={true} variant="filled" my={3} rounded="md" maxHeight="100px" overflowY="auto" />
                    <br/>
                    Eval string: <Textarea value={evalObj.assertion.assert_string} isReadOnly={true} variant="filled" my={3} rounded="md" maxHeight="100px" overflowY="auto" />
                    <br/>
                    Eval number: <Input value={evalObj.assertion.assert_num} isReadOnly={true} variant="filled" my={3} />
                </AccordionPanel>
            </AccordionItem>
        ))
    );

    return (
        <>
            {/* The new button to view the most recent diff */}
            <Button
                onClick={fetchCompareRuns}
                colorScheme="blue"
                marginRight={4}
                leftIcon={<EyeOpenIcon />}
            >
                View most recent diff
            </Button>

            <Button leftIcon={<PlayIcon />} onClick={handleRunClick} colorScheme={"green"} isLoading={collectionRunIsLoading}>
                Run and get difference between collection runs
            </Button>
            
            <Modal isOpen={isRunModalOpen} onClose={handleClose} size={"full"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Collection run A/B comparison</ModalHeader>
                    <ModalCloseButton />
                    
                    {compareData && compareData.message ? (
                            <Box display="flex" flexDirection="column" alignItems="center" py={5}>
                                <Stat p="4" bg={getBoxShade(compareData.success_percent_run_1)} rounded="md" textAlign="center" mb={4}>
                                    <StatLabel>Success Percent</StatLabel>
                                    <StatNumber>{compareData.success_percent_run_1}%</StatNumber>
                                </Stat>
                                <Alert status="info" maxWidth="100%" textAlign="center">
                                    <AlertIcon />
                                    Nothing to compare to! Run the collection one more time to get a comparison view.
                                </Alert>
                             </Box>
                        ) : compareData && compareData.timestamp_run_1 && compareData.success_percent_run_1 && compareData.differences && compareData.system_prompt_run_1 ? (
                                                
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {/* Most Recent */}
                            <div style={{ flex: "1", padding: "1rem", borderRight: "1px solid #e2e8f0" }}>
                                <Text fontSize="lg" fontWeight="bold" mb="4">
                                    Most recent <span style={{fontSize: 'small'}}>({new Date(compareData.timestamp_run_1 * 1000).toLocaleString()})</span>
                                </Text>
                                <Stat p="4" bg={getBoxShade(compareData.success_percent_run_1)} rounded="md" mb={5}>
                                    <StatLabel>Success Percent</StatLabel>
                                    <StatNumber>{compareData.success_percent_run_1}%</StatNumber>
                                </Stat>
                                System prompt: <Textarea value={compareData.system_prompt_run_1} isReadOnly={true} variant="filled" my={3} rounded="md" maxHeight="100px" overflowY="auto" />
                                <Accordion allowMultiple  mt={5}>
                                    {compareData.differences.map((diff, index) => (
                                        <AccordionItem key={index}>
                                            <AccordionButton>
                                                <Box flex="1" textAlign="left">
                                                    Found difference in test: <Badge colorScheme="grey">{diff.test_1.name || "Untitled Test"}</Badge>
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                            <AccordionPanel pb={4}>
                                                Output: <Textarea value={diff.test_1.result} isReadOnly={true} variant="filled" my={3} rounded="md" maxHeight="100px" overflowY="auto" />
                                                {renderEvals(diff.test_1.evals)}
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>
                            
                            {/* Previous */}
                            <div style={{ flex: "1", padding: "1rem" }} mt={5}>
                                <Text fontSize="lg" fontWeight="bold" mb="4">
                                    Previous <span style={{fontSize: 'small'}}>({new Date(compareData.timestamp_run_2 * 1000).toLocaleString()})</span>
                                </Text>
                                <Stat p="4" bg={getBoxShade(compareData.success_percent_run_2)} rounded="md" mb={5}>
                                    <StatLabel>Success Percent</StatLabel>
                                    <StatNumber>{compareData.success_percent_run_2}%</StatNumber>
                                </Stat>
                                System prompt: <Textarea value={compareData.system_prompt_run_2} isReadOnly={true} variant="filled" my={3} rounded="md" maxHeight="100px" overflowY="auto" />
                                <Accordion allowMultiple  mt={5}>
                                    {compareData.differences.map((diff, index) => (
                                        <AccordionItem key={index}>
                                            <AccordionButton>
                                                <Box flex="1" textAlign="left">
                                                    Found difference in test: <Badge colorScheme="grey">{diff.test_2.name || "Untitled Test"}</Badge>
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                            <AccordionPanel pb={4}>
                                                <Textarea value={diff.test_2.result} isReadOnly={true} variant="filled" my={3} rounded="md" maxHeight="100px" overflowY="auto" />
                                                {renderEvals(diff.test_2.evals)}
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    ) : (
                        <p>Loading comparison data...</p>
                    )}
                </ModalContent>

            </Modal>
        </>
    );
};

export default CollectionRunModal;
