import { Button } from "@chakra-ui/react";
import axios from "axios";
import {DeleteIcon} from "@chakra-ui/icons";
import {useUtilityFunctions} from "../UtilityFunctions";

const DeleteCellButton = ({ cellID, deleteCell, workspaceDataID }) => {
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    const handleDeleteCell = async () => {
        const url = `${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cellID}`;

        try {
            const response = await axios.delete(url, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                }
            });

            if (response.data.msg === "Cell deleted") {
                // After the cell is deleted from the backend, remove it from the local state
                deleteCell();
            } else {
                throw new Error("Cell deletion failed");
            }

        } catch (error) {
            console.error('Error deleting cell:', error);
        }
    }


    return (
        <Button variant="outline" colorScheme="red" size="sm" borderRadius={4} leftIcon={<DeleteIcon />} onClick={handleDeleteCell}>
            Delete Cell
        </Button>
    );
}

export default DeleteCellButton;
