import {Box, HStack, Button, useColorModeValue, Icon, Spacer} from "@chakra-ui/react";
import {PlayIcon, PanelTableIcon} from "evergreen-ui";
import axios from "axios";
import ImportChatCSVModal from "../ChatImport/ImportChatCSVModal";

const ConvertedPlayIcon = () => <Icon as={PlayIcon}/>;

const ChainBar = ({chainID, allOpen, setAllOpen, runChainOnSuite, 
    chainIsRunning, setChainIsRunning}) => {

    
    return (
        <Box p={2}>
            <HStack spacing={2}>
                <Button
                    size={"sm"}
                    leftIcon={<ConvertedPlayIcon />}
                    colorScheme="green"
                    onClick={runChainOnSuite}
                    isLoading={chainIsRunning}
                >
                    Run Chain
                </Button>
                <Button size={"sm"} onClick={() => setAllOpen(prevState => !prevState)}>{allOpen ? "Close All" : "Open All"}</Button>
                <Spacer />
            </HStack>
        </Box>
    )
}

export default ChainBar;
