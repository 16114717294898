import {
    Code,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Heading,
    HStack,
    Icon,
    Text,
    VStack,
    Divider,
    Flex,
    Button
} from "@chakra-ui/react";
import {FaClock, FaPercentage} from "react-icons/fa";
import StatDisplay from "./StatDisplay";
import CellResultHistory from "./CellResultHistory";
import React from "react";
import Papa from 'papaparse';

const ChainHistoryDetails = ({history, isChat}) => {


    return (<VStack align="start" spacing={4}>
        {/* Stats Card */}
        <StatDisplay stats={history.stats}/>

        {/* Tests Accordion */}
        <Accordion allowToggle w={'100%'}>
            <Heading size="sm">Tests</Heading>
            {history.tests.map((test, index) => (<AccordionItem key={index} border={'none'}>
                <AccordionButton>
                    <Box flex="1" textAlign="left">{test.name || "Unnamed Test"}</Box>
                    <AccordionIcon/>
                </AccordionButton>
                <AccordionPanel>
                    <StatDisplay stats={test.stats}/>
                    {!isChat && <Box mt={4}>
                        <Text as={'b'}>Variable Mapping:</Text><br/>
                        {Object.entries(test.var_mapping).map(([variable, value]) => (<Code key={variable}>
                            {variable}: {value}
                        </Code>))}
                    </Box>}
                    <Box mt={4}>
                        <Text mt={2} as={'b'}>Cell Results:</Text>
                        <Divider mb={2} orientation='horizontal' borderWidth={'2px'}/>
                        {test.cell_results.map((cell, index) => (<>
                            <CellResultHistory passed={cell.passed} usage={cell.usage} evals={cell.evals}
                                               test_prompt={cell.test_prompt} result={cell.result} isChat={isChat}/>
                        </>))}
                    </Box>
                </AccordionPanel>
            </AccordionItem>))}
        </Accordion>

        {/* Summary of Cells */}
        <Box>
            <Text as={'b'}>{isChat ? 'Chat Cell Configuration:' : 'Cell Configurations:'}</Text><br/>

            {(isChat ? history.cell_details.slice(0, 1) : history.cell_details).map((cell, index) => (<Box key={index} m={4}>
                <Flex>
                    <Text width="80px">Prompt:</Text>
                    <Code flex="1" whiteSpace="pre-wrap">
                        {typeof cell.prompt === 'string' ? cell.prompt : JSON.stringify(cell.prompt)}
                    </Code>
                </Flex>
                <Flex>
                    <Text width="80px">Model:</Text>
                    <Text flex="1" whiteSpace="pre-wrap">{cell.model_family} / {cell.model}</Text>
                </Flex>
                {cell.params && Object.keys(cell.params).length > 0 && (<Flex>
                    <Text width="80px">Params:</Text>
                    <Text flex="1" whiteSpace="pre-wrap">
                        {Object.entries(cell.params).map(([key, value], index) => (
                            <span key={index}>
                                {key}: {typeof value === 'string' ? value : JSON.stringify(value)}
                                {index < Object.keys(cell.params).length - 1 ? ', ' : ''}
                            </span>
                        ))}

                    </Text>
                </Flex>)}

            </Box>))}
        </Box>
    </VStack>);
};

export default ChainHistoryDetails;
