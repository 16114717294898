import {
    Box,
    Flex,
    Text,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    Button,
    Stack,
    ButtonGroup, Link, Tag,
    Image,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    CloseButton
} from '@chakra-ui/react'
import {ChevronDownIcon} from '@chakra-ui/icons'
import WorkspaceDropdownComponent from './WorkspaceDropdownComponent'
import SignInModal from '../UserAuth/SignInModal'
import CreateAccountModal from '../UserAuth/CreateAccountModal'
import UserProfile from '../UserAuth/UserProfile'
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {Heading} from '@chakra-ui/react';
import {useUtilityFunctions} from "../UtilityFunctions";
import {usePlayground} from "../Playground/PlaygroundContext";

export default function NavBarComponent({isLoggedIn, setIsLoggedIn, setWorkspaceSelected,
                                        userData, setUserData, workspaces, setWorkspaces}) {
    const {isOpen, onToggle} = useDisclosure()
    const {getAccessToken, getUserData, logoutStorage, getIsFreeUser} = useUtilityFunctions();
    // const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState();
    const isPlayground = usePlayground();
    const isFreeUser = getIsFreeUser()

    const [isFreeUserBarVisible, setIsFreeUserBarVisible] = useState(true);


    useEffect(() => {
        // Update user data and login status from local storage
        const storedUserData = JSON.parse(getUserData());
        setIsLoggedIn(!!storedUserData);
        setUserData(storedUserData);
    }, [isLoggedIn]);

    useEffect(() => {
        // get the workspace data
        const fetchWorkspaceData = async (currToken) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/summary`, {
                    headers: {
                        'Authorization': `Bearer ${currToken}`
                    }
                });
                setWorkspaces(response.data);

            } catch (err) {
                console.error(err);
            }
        };
        // get most recent access token

        if (getAccessToken()) {
            const currToken = getAccessToken()
            fetchWorkspaceData(currToken);
        }

    }, [userData]);


    const onLogout = () => {
        // Remove user data and tokens from local storage
        logoutStorage();

        // You might need to force a re-render or redirect user to the home page
        // navigate('/');

        setIsLoggedIn(false);
        setUserData(null);
        setSelectedWorkspaceId(null);
        setWorkspaces([]);
        

        navigate('/')
    };

    return (
        <div>
            {isFreeUser && isFreeUserBarVisible && ( // Conditional rendering of the orange bar based on the isBarVisible state
                <Flex 
                    bg="gray.500" 
                    color="white" 
                    justify="center" 
                    align="center" 
                    position="relative"
                    p={1}>
                    <Text>You are on the free tier, with a limit of 100 free LLM calls to OpenAI.</Text>
                    <CloseButton 
                        position="absolute" 
                        right="0" 
                        mr={2} 
                        size={"sm"}
                        onClick={() => setIsFreeUserBarVisible(false)} /> {/* Event handler to set isBarVisible to false */}
                </Flex>
            )}
            
            <Box fontFamily={'Inter, sans-serif'}>
                <Flex
                    bg={useColorModeValue('white', 'gray.800')}
                    color={useColorModeValue('gray.600', 'white')}
                    minH={'60px'}
                    py={{base: 2}}
                    px={{base: 4}}
                    borderBottom={"1px solid"}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('#ededed', 'gray.900')}
                    align={'center'}>
                    <Flex flex={{base: 1}} justify={{base: 'center', md: 'start'}} align={'center'}>
                        <Image
                            src="https://i.ibb.co/1vXNFcc/symbol-text-dark.png"
                            alt="Chatter"
                            width="100px"
                            objectFit="contain"
                            textAlign={useBreakpointValue({base: 'center', md: 'left'})}
                        />

                        <Flex display={{base: 'none', md: 'flex'}} ml={10}>

                            <Button
                                as={'a'}
                                fontSize={'sm'}
                                fontWeight={600}
                                bg={'#FFFFFF'}
                                color={'#555555'}
                                href={'#'}
                                _hover={{
                                    bg: '#E6E6E6',
                                    color: '#212121'
                                }}
                            >
                                Home
                            </Button>
                            <Popover trigger={'hover'} placement={'bottom-start'}>
                                <PopoverTrigger>
                                    <Button
                                        as={'a'}
                                        fontSize={'sm'}
                                        fontWeight={600}
                                        bg={'#FFFFFF'}
                                        color={'#555555'}
                                        href={'#'}
                                        _hover={{
                                            bg: '#E6E6E6',
                                            color: '#212121'
                                        }}
                                        rightIcon={<ChevronDownIcon/>}
                                    >
                                        Workspaces
                                    </Button>
                                </PopoverTrigger>
                                <WorkspaceDropdownComponent isLoggedIn={isLoggedIn} workspacesData={workspaces}
                                                            selectedWorkspaceId={selectedWorkspaceId}
                                                            setSelectedWorkspaceId={setSelectedWorkspaceId}
                                /> </Popover>
                        </Flex>
                    </Flex>

                    <ButtonGroup
                        flex={{base: 1, md: 0}}
                        justify={'flex-end'}
                        direction={'row'}
                        spacing={2}>
                        <Menu>
                            <MenuButton as={Button} mr={2}>
                                Tools
                            </MenuButton>
                            <MenuList>
                                <MenuItem>
                                    <a href="/function-call-editor" target="_blank" rel="noopener noreferrer">Function
                                        Call Editor</a>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        {isLoggedIn && userData ? (
                            (isPlayground) ? (
                                <Button fontSize={'sm'} colorScheme={'orange'}
                                        onClick={() => window.open('https://calendly.com/trychatter/30min', '_blank')}>
                                    Want more?
                                </Button>
                            ) : (
                                <>
                                {isFreeUser && (
                                    <Button fontSize={'sm'} colorScheme={'orange'}
                                            onClick={() => window.open('https://calendly.com/trychatter/30min', '_blank')}>
                                        Upgrade
                                    </Button>
                                )}
                                <UserProfile name={userData.user.first_name} userData={userData}
                                             setUserData={setUserData} onLogout={onLogout}/>
                                </>
                            )
                        ) : (
                            <>
                                <Button
                                    size="md" // Made the button smaller
                                    borderRadius="md"
                                    fontSize={"sm"}
                                    fontWeight={600}
                                    mr={8}
                                    colorScheme='telegram'
                                    onClick={() => navigate('/playground')}
                                >
                                    <Tag mr={2} size={'sm'} colorScheme='telegram'>New</Tag>
                                    Playground
                                </Button>
                                <SignInModal setIsLoggedIn={setIsLoggedIn}/>
                                <CreateAccountModal setIsLoggedIn={setIsLoggedIn}/>
                            </>
                        )}
                    </ButtonGroup>
                </Flex>
            </Box>

            {!isLoggedIn && <SplashScreen/>}

        </div>

    )
}

const NavBarItem = ({label, href}) => {
    return (
        <Box as="a" p={2} href={href} fontSize={'sm'} fontWeight={500} color={useColorModeValue('gray.600', 'white')}>
            {label}
        </Box>
    )
}

function SplashScreen() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            height="calc(100vh - 60px)"
            paddingTop="20vh"
        >
            <Heading size="4xl">
                <Text as="span">Welcome to </Text>
                <Text as="span" color="#ED7547">Chatter Platform</Text>
            </Heading>
            <Text fontSize="xl" marginTop="10px">Lightweight yet powerful LLM iteration and testing.</Text>
        </Box>
    );
}