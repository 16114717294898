import {
    Box,
    HStack,
    Input,
    Icon,
    Select,
    Heading,
    Circle,
    IconButton,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    VStack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useToast,
    Spinner, MenuGroup, MenuDivider
} from '@chakra-ui/react';
import {TrashIcon, PlusIcon, PlayIcon, ChevronDownIcon} from 'evergreen-ui';
import {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {useUtilityFunctions} from "../UtilityFunctions";
import SemanticInput from "./Assertions/SemanticInput";
import FunctionSelectInput from "./Assertions/FunctionSelectInput";
import BooleanGroupInput from "./Assertions/BooleanGroupInput";
import StandardEvaluationInput from "./Assertions/StandardEvaluationInput";
import BinaryInput from "./Assertions/BinaryInput";

const AssertionList = ({
                           cell,
                           cellIndex,
                           testId,
                           suiteId,
                           suiteData,
                           setSuiteData,
                           currRunState,
                           setCurrRunState,
                           chainIsRunning,
                           workspaceDataID,
                           workspaceData,
                           chainID
                       }) => {
    let testData = suiteData.tests.find(test => test.id === testId);
    const [cellAssertions, setCellAssertions] = useState(testData.assertions[cell._id]);
    const [cellAssertionResults, setCellAssertionResults] = useState([]);
    const {getAccessToken, getAssertionTypes} = useUtilityFunctions();
    const [explanationIsOpen, setExplanationIsOpen] = useState(false);

    const userName = "YourUserName";

    const open = () => setExplanationIsOpen(true);
    const close = () => setExplanationIsOpen(false);

    const assertionTypes = getAssertionTypes()

    const collectionForChain = workspaceData.collections.find(collection =>
        collection.chains.some(chain => chain.id === chainID)
    );


    const toast = useToast()

    const [isTemplateAddLoading, setIsTemplateAddLoading] = useState(false)


    useEffect(() => {
        let currTestRunState = currRunState.test_results.find(result => result.testID === testId);
        if (currTestRunState && currTestRunState.cell_results && currTestRunState.cell_results[cellIndex]
            && currTestRunState.cell_results[cellIndex].evals) {
            if (Array.isArray(currTestRunState.cell_results[cellIndex].evals)) {
                setCellAssertionResults(currTestRunState.cell_results[cellIndex].evals)
            } else {
                setCellAssertionResults([])
            }
        }
    }, [currRunState]);


    useEffect(() => {
        testData = suiteData.tests.find(test => test.id === testId);
        setCellAssertions(testData.assertions[cell._id]);
    }, [testData, suiteData]);


    // if functions changes
    useEffect(() => {
        if (workspaceData.aux.functions && workspaceData.aux.functions.length > 0 && cellAssertions) {
            // Find all assertions of type 'called-function' or 'called-router' and update their assert_string
            const updatedAssertions = cellAssertions.map(assertion => {
                if (assertion.assert_type === 'called-function' || assertion.assert_type === 'called-router') {
                    if (assertion.assert_string_addl_optional) {
                        let new_assert_string = assertion.assert_string
                        const matchingFunction = workspaceData.aux.functions.find(func => func._id === assertion.assert_string_addl_optional);
                        if (matchingFunction && matchingFunction.function) {
                            new_assert_string = matchingFunction.function.name
                        } 
                        return {
                            ...assertion,
                            assert_string: new_assert_string
                        };
                    } else if (!assertion.assert_string_addl_optional || assertion.assert_string_addl_optional === "") {
                        // need to add one
                        const matchingFunctionByName = workspaceData.aux.functions.find(func => func.function.name === assertion.assert_string);
                        if (matchingFunctionByName) {
                            return {
                                ...assertion,
                                assert_string_addl_optional: matchingFunctionByName._id
                            };
                        }
                    }
                }
                return assertion;
            });

            // Check if any assertion was updated
            if (JSON.stringify(updatedAssertions) !== JSON.stringify(cellAssertions)) {
                // Update the local state
                setCellAssertions(updatedAssertions);

                // Update in the backend (you might need to adjust the API call)
                // For simplicity, I'm reusing the updateAssertion function here, though you may want to create a separate function for bulk updates.
                updatedAssertions.forEach(assertion => {
                    if (assertion.assert_type === 'called-function' || assertion.assert_type === 'called-router') {
                        handleAssertionBlur(updatedAssertions);
                    }
                });
            }
        }
    }, [workspaceData.aux.functions, cellAssertions]);

    const addAssertionsFromTemplate = async (assertions) => {
        setIsTemplateAddLoading(true)
        for (let assertion of assertions) {
            await addAssertion(assertion)
        }
        setIsTemplateAddLoading(false)
    }


    const addAssertion = async (preset_assertion) => {
        let newAssertion = {
            assert_type: 'note',
            assert_string: '',
            assert_num: '',
            assert_list: [],
            assert_string_addl_optional: ''
        };

        if (preset_assertion && 'assert_type' in preset_assertion && 'assert_string' in preset_assertion) {
            newAssertion = {
                ...newAssertion,
                assert_type: preset_assertion.assert_type,
                assert_string: preset_assertion.assert_string,
                assert_num: preset_assertion.assert_num,
                assert_string_addl_optional: preset_assertion.assert_string_addl_optional || '',
            };
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${suiteId}/tests/${testId}/assertions/${cell._id}`, newAssertion, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
            });


            if (response.data && response.data.assertion) {
                // Update the suiteData state
                setSuiteData(prevSuiteData => {
                    const updatedSuiteData = {...prevSuiteData};
                    const test = updatedSuiteData.tests.find(test => test.id === testId);
                    if (test) {
                        if (!test.assertions[cell._id]) {
                            test.assertions[cell._id] = [];
                        }
                        test.assertions[cell._id].push(response.data.assertion);
                    }
                    return updatedSuiteData;
                });
            }
        } catch (error) {
            console.error('Failed to add assertion:', error);
            toast({
                title: "Failed to add assertion",
                description: "Please try again in a moment.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleAssertionMenuClick = (id, updatedAssertion) => {
        const updated = handleAssertionChange(id, updatedAssertion);
        handleAssertionBlur(updated);
    }

    const handleAssertionChange = (id, updatedAssertion) => {
        let updatedAssertions;

        if (cellAssertions) {
            const index = cellAssertions.findIndex(assertion => assertion.id === id);

            if (index !== -1) {
                updatedAssertions = cellAssertions.map((assertion, i) => i === index ? updatedAssertion : assertion);
    
                let testData = suiteData.tests.find(test => test.id === testId);
                setCellAssertions(updatedAssertions)
    
                setCellAssertionResults(prevResults =>
                    prevResults.filter(result => result.assertion.id !== id)
                );
                if (updatedAssertion && updatedAssertion.assert_type && ['boolean-and', 'boolean-or'].includes(updatedAssertion.assert_type)) {
                    handleAssertionBlur(updatedAssertions)
                }
    
            }
    
            return updatedAssertions;
        }

    };

    const handleAssertionBlur = async (updatedAssertions) => {
            let newAssertions = cellAssertions || []
            if (updatedAssertions && !updatedAssertions.target) {
                newAssertions = updatedAssertions;
            }

            try {
                const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/suites/${suiteId}/tests/${testId}/assertions/${cell._id}`, newAssertions, {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    },
                });

                // Update the suiteData state
                setSuiteData(prevSuiteData => {
                    const updatedSuiteData = {...prevSuiteData};
                    const test = updatedSuiteData.tests.find(test => test.id === testId);
                    if (test) {
                        test.assertions[cell._id] = newAssertions;
                    }
                    return updatedSuiteData;
                });

                // You can check if the response was successful, handle errors, etc. here.
            } catch (error) {
                console.error('Failed to persist assertion:', error);
            }
    };

    const deleteAssertion = async (id) => {
        if (!cellAssertions) {
            return
        }
        try {
            const updatedAssertions = cellAssertions.filter(assertion => assertion.id !== id);


            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/suites/${suiteId}/tests/${testId}/assertions/${cell._id}`, updatedAssertions, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
            });

            if (response.data) {
                // Update the suiteData state
                setSuiteData(prevSuiteData => {
                    const updatedSuiteData = {...prevSuiteData};
                    const test = updatedSuiteData.tests.find(test => test.id === testId);
                    if (test) {
                        test.assertions[cell._id] = updatedAssertions;
                    }
                    return updatedSuiteData;
                });
                setCellAssertionResults(prevResults =>
                    prevResults.filter(result => result.assertion.id !== id)
                );
            }
        } catch (error) {
            console.error('Failed to delete assertion:', error);
        }
    };


    return (
        <>
            <HStack justifyContent="flex-start" w="100%" alignItems="center" mt={4}>
                <Heading size="sm" fontWeight="normal">Assertions for Cell {cellIndex + 1}</Heading>
                <IconButton
                    aria-label="Add assertion"
                    icon={<Icon as={PlusIcon}/>}
                    size="sm"
                    variant="ghost"
                    _hover={{bg: 'gray.100'}}
                    onClick={(e) => {
                        e.stopPropagation();
                        addAssertion()
                    }}
                />
                {collectionForChain && collectionForChain.assertion_templates && collectionForChain.assertion_templates.length > 0 && (
                    <>
                        <Menu>
                            <MenuButton as={Button} rightIcon={<ChevronDownIcon/>} size="sm" variant="ghost">
                                Add from Template
                            </MenuButton>
                            <MenuList>
                                {collectionForChain.assertion_templates.map((template) => (
                                    <MenuItem key={template.id} onClick={() => {
                                        // Call addAssertion here and pass the template's assertions as parameter
                                        addAssertionsFromTemplate(template.assertions);
                                    }}>
                                        {template.template_name}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                        {isTemplateAddLoading && <Spinner size="sm"/>}
                    </>
                )}
            </HStack>
            {cellAssertions && cellAssertions.map((currAssertion, index) => {
                const result = cellAssertionResults.length && cellAssertionResults.find(res => res.assertion.id === currAssertion.id);
                const color = result && result.status === "passed" ? "green.400" : "red.400";
                const borderColor = result && result.status === "failed" ? "red.400" : "gray.200";

                return (
                    (currAssertion) && (
                        <HStack key={currAssertion.id} spacing={4} align="center" mb={2}>
                            {result && (
                                <Box>
                                    {result.msg ? (
                                        <Popover trigger="hover">
                                            <PopoverTrigger>
                                                <Circle size="10px" bg={color}/>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow/>
                                                <PopoverBody>{result.msg}</PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                    ) : (
                                        <Circle size="10px" bg={color}/>
                                    )}
                                </Box>
                            )}

                            <Box flex="1">
                                <HStack spacing={2}>
                                    <Menu>
                                        <MenuButton as={Button} size="sm" width="50%">
                                            {currAssertion.assert_type}
                                        </MenuButton>
                                        <MenuList style={{ maxHeight: '300px' }} overflowY="auto">
                                            {Object.keys(assertionTypes).map((groupKey, idx) => (
                                                <>
                                                    <MenuGroup title={groupKey}>
                                                        {assertionTypes[groupKey].map((type, i) => (
                                                            <MenuItem
                                                                key={i}
                                                                onClick={() => {
                                                                    handleAssertionMenuClick(currAssertion.id, {
                                                                        ...currAssertion,
                                                                        assert_type: type,
                                                                        assert_string: currAssertion.assert_string,
                                                                    });
                                                                }}
                                                            >
                                                                {type}
                                                            </MenuItem>
                                                        ))}
                                                    </MenuGroup>
                                                    {idx < Object.keys(assertionTypes).length - 1 && <MenuDivider/>}
                                                </>
                                            ))}
                                        </MenuList>

                                    </Menu>
                                    {
                                        (currAssertion.assert_type === 'called-function' || currAssertion.assert_type === 'called-router') ? (
                                                <FunctionSelectInput currAssertion={currAssertion}
                                                                     handleAssertionChange={handleAssertionChange}
                                                                     handleAssertionBlur={handleAssertionBlur}
                                                                     workspaceData={workspaceData}/>
                                            )
                                            : ['semantic-ada-v2-euclidean', 'semantic-ada-v2-cosine'].includes(currAssertion.assert_type) ? (
                                                    <SemanticInput currAssertion={currAssertion}
                                                                   handleAssertionChange={handleAssertionChange}
                                                                   handleAssertionBlur={handleAssertionBlur}/>
                                                )
                                                : ['boolean-and', 'boolean-or'].includes(currAssertion.assert_type) ? (
                                                        <BooleanGroupInput currAssertion={currAssertion}
                                                                           handleAssertionChange={handleAssertionChange}
                                                                           handleAssertionBlur={handleAssertionBlur}
                                                                           cellAssertions={cellAssertions}/>
                                                    )
                                                : ['binary-grade'].includes(currAssertion.assert_type) ? (
                                                    <BinaryInput currAssertion={currAssertion}
                                                                   handleAssertionChange={handleAssertionChange}
                                                                   handleAssertionBlur={handleAssertionBlur}/>
                                                )
                                                    : assertionTypes['moderation'].includes(currAssertion.assert_type) ? null : (
                                                        <StandardEvaluationInput currAssertion={currAssertion}
                                                                                 handleAssertionChange={handleAssertionChange}
                                                                                 handleAssertionBlur={handleAssertionBlur}
                                                                                 userName={userName}/>
                                                    )
                                    }

                                    <Icon as={TrashIcon} color="danger" onClick={(e) => {
                                        e.stopPropagation()
                                        deleteAssertion(currAssertion.id)
                                    }}/>
                                </HStack>
                            </Box>
                        </HStack>)
                )
            })}
        </>
    );
}

export default AssertionList;
