import { CodeBlock, dracula } from "react-code-blocks";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    AnthropicCurl,
    AnthropicPythonLibrary, AnthropicPythonRequests,
    OpenAICurl,
    OpenAIPythonLibrary,
    OpenAIPythonRequests
} from "./code_gen_helper";
import {Box, Button, Divider, Text, useToast} from "@chakra-ui/react";
import {CopyIcon} from "@chakra-ui/icons";

const CellCodeBlock = ({nameIn, configIn, language, modelData}) => {
    const [modelConfig, setModelConfig] = useState(null);
    const [text, setText] = useState("");
    const toast = useToast();
    const [config, setConfig] = useState(configIn);
    const [name, setName] = useState(nameIn);

    useEffect(() => {
        setName(nameIn);
    }, [nameIn]);


    useEffect(() => {
        setConfig(configIn);
    }, [configIn]);


    useEffect(() => {
        if (modelConfig) {
            if (config.model_family === "OpenAI") {
                if (language.lang === "python") {
                    if (language.type === "library") {
                        setText(OpenAIPythonLibrary(config));
                    } else if (language.type === "requests") {
                        setText(OpenAIPythonRequests(config, modelConfig));
                    }
                } else if (language.lang === "cURL") {
                    setText(OpenAICurl(config, modelConfig));
                }
            }

            if (config.model_family === "Anthropic") {
                if (language.lang === "python") {
                    if (language.type === "library") {
                        setText(AnthropicPythonLibrary(config, modelConfig));
                    } else if (language.type === "requests") {
                        setText(AnthropicPythonRequests(config, modelConfig));
                    }
                } else if (language.lang === "cURL") {
                    setText(AnthropicCurl(config, modelConfig));
                }
            }
        }
    }, [config, language, modelConfig]);


    useEffect(() => {
        // Ensure model_family and model are provided
        if (!config || !config.model_family || !config.model) {
            console.error('Invalid config, missing model_family or model.');
            return;
        }

        try {
            setModelConfig(modelData[config.model_family][config.model]);
        } catch (error) {
            // console.error('Failed to fetch model config:', error);
            setModelConfig(null);
        }

    }, [modelData, config]);

    const handleCopy = () => {
        navigator.clipboard.writeText(text).then(() => {
            toast({
                title: "Copied to clipboard",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }, () => {
            toast({
                title: "Failed to copy",
                description: "An error occurred while trying to copy text to clipboard.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    };


    return (
        <>
            {modelData && modelConfig &&
                <Box w={'100%'} pb={4} borderBottom={'1px solid #EDEDED'}>
                    <Box w={'100%'} display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                        <Text as="span" mx={2}>{name}</Text>
                        <Button size={'sm'} leftIcon={<CopyIcon />} onClick={handleCopy}>Copy to clipboard</Button>
                    </Box>
                    <CodeBlock
                        text={text}
                        language={language.lang}
                        showLineNumbers={true}
                        theme={dracula}
                        wrapLines={true}
                    />
                </Box>
            }
        </>
    );

}

export default CellCodeBlock;