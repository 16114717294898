import {Button, HStack} from "@chakra-ui/react";

const BinaryInput = ({currAssertion, handleAssertionChange, handleAssertionBlur}) => {

    const handleThumbClick = (assertType) => {
        const newValue = currAssertion.assert_string === assertType ? "" : assertType;
        const updated = handleAssertionChange(currAssertion.id, {
            ...currAssertion,
            assert_string: newValue
        });
        handleAssertionBlur(updated);
    };


    return (
        <HStack>
            <Button
                w={'125px'}
                size={'sm'}
                bg={currAssertion.assert_string === "up" ? "green.400" : "gray.200"}
                _hover={{bg: "green.400"}}
                onClick={() => handleThumbClick("up")}
            >
                👍
            </Button>
            <Button
                w={'125px'}
                size={'sm'}
                bg={currAssertion.assert_string === "down" ? "red.400" : "gray.200"}
                _hover={{bg: "red.400"}}
                onClick={() => handleThumbClick("down")}
            >
                👎
            </Button>
        </HStack>
    );
};

export default BinaryInput;
