import { 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalFooter, 
    ModalBody, 
    ModalCloseButton, 
    Button, 
    Input, 
    Text,
    HStack,
    Box,
    Tooltip,
    Flex
} from "@chakra-ui/react";
import { useState, useEffect } from 'react';
import {PeopleIcon} from "evergreen-ui";
import axios from 'axios';
import {useUtilityFunctions} from "../UtilityFunctions";
import { useToast } from "@chakra-ui/react";

const ShareModal = ({ workspaceData, setWorkspaceData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [recipientEmail, setRecipientEmail] = useState('');
    const [error, setError] = useState(null);
    const [listOfSharedUsers, setListOfSharedUsers] = useState([]);

    const onClose = () => setIsOpen(false);
    const {getAccessToken, getUserData, logoutStorage, getIsFreeUser} = useUtilityFunctions();
    const toast = useToast();
    const [shareIsLoading, setShareIsLoading] = useState(false)

    useEffect(() => {
        const fetchSharedUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/sharedUsers/${workspaceData._id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                    }
                });
                
                if (response.data.sharedUsers) {
                    setListOfSharedUsers([...response.data.sharedUsers]);
                }
            } catch (error) {
                console.error("Error fetching shared users:", error);
            }
        };
        
        fetchSharedUsers();
    }, [workspaceData._id]);

    const shareWorkspace = async () => {
        try {
            setShareIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/share`, {
                workspace_id: workspaceData._id,
                email: recipientEmail
            }, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                }
            });
    
            if (response.data.msg) {
                // Update listOfSharedUsers with the new email
                setListOfSharedUsers(prevList => [...prevList, recipientEmail]);
                setError(null);  // Reset error state
                setRecipientEmail('');  // Clear the input
                toast({
                    title: "Success!",
                    description: "Workspace shared successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                });
                setShareIsLoading(false);
            } else {
                setError("Failed to share the workspace. Please try again.");
                setShareIsLoading(false);
            }
        } catch (error) {
            setError(error.response?.data?.detail || "Error sharing the workspace, please try again.");
            setShareIsLoading(false);
        }
    }

    const isPlayground = sessionStorage.getItem('state') === 'playground';
    const isFreeUser = getIsFreeUser()

    return (
        <>  
                {console.log("IS PLAYGROUND ", isPlayground, " FREE ", isFreeUser)}
                <Tooltip w="100%" label={(isPlayground || isFreeUser) ? "Collaboration is a premium feature" : ""} placement="right">
                    <Box w="100%">  
                        <Button w="100%" onClick={() => setIsOpen(true)} variant={'solid'} colorScheme={'green'}
                                leftIcon={<PeopleIcon/>} mb={2}
                                isDisabled={(isPlayground || isFreeUser)}>
                            Share
                        </Button>
                    </Box>
                </Tooltip>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent minWidth={"50%"}>
                    <ModalHeader>Share Workspace</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HStack spacing={3} marginBottom={3}>
                            <Input 
                                placeholder="Recipient email address" 
                                value={recipientEmail}
                                onChange={(e) => setRecipientEmail(e.target.value)}
                                flex={1}
                            />
                            <Button 
                                onClick={() => shareWorkspace()} colorScheme={"green"} isLoading={shareIsLoading} isDisabled={listOfSharedUsers.includes(recipientEmail)}>
                                Share
                            </Button>
                        </HStack>
                        {error && <Text color="red.500" marginBottom={3}>{error}</Text>}
                        <HStack spacing={3} flexWrap="nowrap">
                            <Text color="gray.500">Shared with:</Text>
                            <Box maxW={"80%"} flexWrap="nowrap">
                                {listOfSharedUsers.flatMap((user, index, array) => 
                                    index === array.length - 1 ? 
                                    [<Text key={user} display="inline" color="gray.600">{user}</Text>] :
                                    [<Text key={user} display="inline" color="gray.600">{user}</Text>, <Text key={user + '-comma'} display="inline" color="gray.800" fontSize="sm">, </Text>]
                                )}
                            </Box>
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ShareModal;
