import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {
    Box,
    Text,
    Tabs, TabList, Tab, TabPanels, TabPanel, Flex
} from '@chakra-ui/react';
import WorkspaceSidebar from './WorkspaceSidebar';
import {CloseButton} from '@chakra-ui/react';
import WorkspaceTabList from './WorkspaceTabList';
import {useUtilityFunctions} from "../UtilityFunctions";
import {useNavigate} from "react-router-dom";

function Workspace({userData, setUserData}) {
    const {id} = useParams();
    const [workspaceData, setWorkspaceData] = useState(null);
    const [workspaceDataID, setWorkspaceDataID] = useState(id);
    const [selectedChain, setSelectedChain] = useState(null);
    const [allChainTabs, setAllChainTabs] = useState([]);
    const {getAccessToken} = useUtilityFunctions();
    const navigate = useNavigate();

    console.log("ID IS ", id)


    const fetchWorkspaceData = async () => {
      try {

          const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/byID/${id}`, {
              headers: {
                  'Authorization': `Bearer ${getAccessToken(id)}`
              }
          });
          setWorkspaceData(response.data);
          setWorkspaceDataID(response.data._id)

      } catch (err) {
          console.error(err);
          navigate('/')
      }
    }

    
    useEffect(() => {
        if (workspaceData) {
            setWorkspaceDataID(workspaceData._id)
        }
    }, [workspaceData]);


    const fetchWorkspaceState = async () => {
        try {

          const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${id}/state`, {
              headers: {
                  'Authorization': `Bearer ${getAccessToken(id)}`
              }
          });
          if (response.data) {


            let currChainsOpen = []

            if (response.data.currChainsOpen) {
                currChainsOpen = response.data.currChainsOpen
            } else if (response.data.chainsOpen) {
                currChainsOpen = response.data.chainsOpen 
            }

            console.log("GOT CHAINS OPEN AS ", response.data)

            setAllChainTabs(currChainsOpen); 
              setSelectedChain(response.data.currChainOpen); 

          }
        } catch (error) {
            console.error('Error fetching workspace state STATE11 EXIT:', error);
        }
      }

    useEffect(() => {
        console.log("CALLED GET STATE")
        fetchWorkspaceData();
        fetchWorkspaceState();
    }, [id]);

    if (!workspaceData) {
        return <Text>Loading...</Text>
    }

    const selectedChainIndex = allChainTabs.findIndex(chain => chain.id === selectedChain.id);

    return (
        <Flex style={{ overflow: 'hidden', height: '95vh' }}>
            <WorkspaceSidebar workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}
                              allChainTabs={allChainTabs}
                              setAllChainTabs={setAllChainTabs}
                              selectedChain={selectedChain} setSelectedChain={setSelectedChain} 
                              flexShrink={0} userData={userData}/>
            <Box overflowX={"auto"} flexGrow={1} style={{ overflowY: 'hidden' }}>
                <WorkspaceTabList workspaceData={workspaceData} allChainTabs={allChainTabs}
                                  selectedChain={selectedChain} setSelectedChain={setSelectedChain}
                                  setAllChainTabs={setAllChainTabs}
                                  userData={userData} setUserData={setUserData}
                />
            </Box>
        </Flex>
    );
}


export default Workspace;
