import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, Box, Button, HStack, Select, Stack, Grid, Flex } from "@chakra-ui/react";
import MonacoEditor from 'react-monaco-editor';
import React, { useState, useRef, useEffect } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import axios from 'axios';
import {useUtilityFunctions} from "../UtilityFunctions";
import CollectionRunModal from "./CollectionRunModal";
import ConfigureCollectionRoutes from "./ConfigureCollectionRoutes";
import OtherCollectionSettings from "./OtherCollectionSettings"
import LabelsSettingsComponent from "./LabelsSettingsComponent"
import AssertionTemplateComponent from "./AssertionTemplateComponent"
import { TrashIcon, UploadIcon } from 'evergreen-ui';

const WorkspaceCollectionSettingsModal = ({ isOpen, onClose, workspaceData, setWorkspaceData, collectionID }) => {
    const collection = workspaceData.collections.find(col => col.id === collectionID) || { starting_messages: [] };
    const [editorContentArrayDouble, setEditorContentArrayDouble] = useState(collection.starting_messages || [{ role: 'system', content: '' }]);
    const {getAccessToken} = useUtilityFunctions();
    
    const [isSaveStartingMessageLoading, setSaveStartingMessageLoading] = useState(false)
    const [isSaveFunctionsLoading, setSaveFunctionsLoading] = useState(false)


    
    const editorRef = useRef(null);
    const [editorHeight, setEditorHeight] = useState(200); // Starting height
    const [modelData, setModelData] = useState({})
    const [possibleRoles, setPossibleRoles] = useState([])

    const [workspaceFunctions, setWorkspaceFunctions] = useState([]);
    const [selectedFunctionNames, setSelectedFunctionNames] = useState(null);

    useEffect(() => {
        if (workspaceData.aux && workspaceData.aux.functions) {
            const extractedFunctions = workspaceData.aux.functions.map(item => item.function);
            setWorkspaceFunctions(extractedFunctions);
            
            // Extract the function names based on collection's available functions
            if (collection.available_functions) {
                const selectedFunctionNamesFromData = collection.available_functions.map(availObj => {
                    const functionObj = workspaceData.aux.functions.find(f => f._id === availObj.function_id);
                    return functionObj ? functionObj.function.name : null;
                }).filter(Boolean);  // Filters out any nulls
        
                setSelectedFunctionNames(selectedFunctionNamesFromData);
            } else {
                const allFunctionNames = workspaceData.aux.functions.map(func => func.function.name);
                setSelectedFunctionNames(allFunctionNames);
            }
        }
    }, [workspaceData?.aux?.functions]);

    const handleFunctionSelection = async (func) => {
        let updatedSelectedFunctions = [...selectedFunctionNames];
        if (updatedSelectedFunctions.includes(func.name)) {
            updatedSelectedFunctions = updatedSelectedFunctions.filter(itemName => itemName !== func.name);
        } else {
            updatedSelectedFunctions.push(func.name);
        }
        setSelectedFunctionNames(updatedSelectedFunctions);
    };

    const getSelectedFunctionIds = () => {
        if (selectedFunctionNames && workspaceData.aux && workspaceData.aux.functions) {
            return selectedFunctionNames.map(name => {
                const funcObj = workspaceData.aux.functions.find(func => func.function.name === name);
                return funcObj ? funcObj._id : null;
            }).filter(Boolean);
        } else {
            return ([])
        }
    };

    const handleSaveFunctionsAccessible = async () => {
            setSaveFunctionsLoading(true)
            // Get the selected function IDs
            const selectedIds = getSelectedFunctionIds();
            const functionsList = selectedIds.map(id => ({ function_id: id }));
    
            try {
                const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/update-available-functions`, {
                    workspace_id: workspaceData._id,
                    collection_id: collectionID,
                    list_function_ids: selectedIds
                }, {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                    },
                });
    
                const updatedWorkspace = { ...workspaceData };
                const targetCollection = updatedWorkspace.collections.find(col => col.id === collectionID);
    
                if (targetCollection) {
                    targetCollection.available_functions = functionsList;
                }
    
                setWorkspaceData(updatedWorkspace);
    
                if (response.data.msg !== "Available functions updated successfully") {
                    console.error("Failed to update available functions:", response.data.msg);
                }
                setSaveFunctionsLoading(false)
            } catch (error) {
                console.error('Failed to update available functions:', error);
                setSaveFunctionsLoading(false)
            }
            setSaveFunctionsLoading(false)
    }




    const handleModalClose = async () => {
        onClose(); // This will close the modal after saving data.
    };

    const updateStartingMessagesWorkspaceAndDB = async (updatedEditorContentArray) => {
        const updatedCollections = workspaceData.collections.map(col => {
            if (col.id === collectionID) {
                col.starting_messages = updatedEditorContentArray;
            }
            return col;
        });
        setWorkspaceData({ ...workspaceData, collections: updatedCollections });
        await updateStartingMessagesInDB(updatedEditorContentArray);
    };


    const handleRoleChange = async (index, value) => {
        const newArray = [...editorContentArrayDouble];
        newArray[index].role = value;
        setEditorContentArrayDouble(newArray);
    };

    const handleEditorArrayChange = async (index, value) => {
        const newArray = [...editorContentArrayDouble];
        newArray[index].content = value;
        setEditorContentArrayDouble(newArray);
    };

    const addMessage = async () => {
        const newArray = [...editorContentArrayDouble, { role: possibleRoles[0], content: '' }];
        setEditorContentArrayDouble(newArray);
    };

    const deletePrompt = async (index) => {
        const newArray = [...editorContentArrayDouble];
        newArray.splice(index, 1);
        setEditorContentArrayDouble(newArray);
    };

    const fetchModelData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/model`, {});

            setModelData(response.data);
            setPossibleRoles(response.data['OpenAI']['gpt-4']['roles'])
        } catch (error) {
            console.error('Failed to fetch model data', error);
        }
    }

    useEffect(() => {
        fetchModelData();
    }, []);

    const updateStartingMessagesInDB = async (startingMessages) => {
        try {
            setSaveStartingMessageLoading(true)
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/update-starting-messages`, {
                workspace_id: workspaceData._id,
                collection_id: collectionID,
                starting_messages: startingMessages
            }, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                },
            });

        
    
            if (response.data.msg !== "Starting messages updated successfully") {
                console.error("Failed to update starting messages in DB:", response.data.msg);
            }
            setSaveStartingMessageLoading(false)
        } catch (error) {
            console.error('Failed to update starting messages in DB:', error);
            setSaveStartingMessageLoading(false)
        }
        setSaveStartingMessageLoading(false)
    }

    const autoResize = () => {
        try {
            if (editorRef.current) {
                const model = editorRef.current.getModel();
                const newLineCount = model.getLineCount(); 
                const layoutInfo = editorRef.current.getLayoutInfo();
                const approxCharWidth = 7.5;  
                const paddingSpaces = 50; 
                const charactersPerLine = (layoutInfo.width - (2 * paddingSpaces)) / approxCharWidth;

                let wrappedLines = 0;
                for (let i = 1; i <= newLineCount; i++) {
                    const lineContent = model.getLineContent(i);
                    wrappedLines += Math.floor(lineContent.length / charactersPerLine);
                }

                const newHeight = Math.max((newLineCount + wrappedLines) * 19, 200); 
                setEditorHeight(newHeight);
            }
        } catch (err) {
            console.error("Failed to auto-resize editor:", err);
        }
    };

    const editorDidMount = (editor) => {
        editorRef.current = editor;
    };

    useEffect(() => {
        autoResize();
    }, [editorContentArrayDouble]);



    return (
        <Modal isOpen={isOpen} onClose={handleModalClose} size={"5xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Configure collection</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Accordion allowToggle>
                        <AccordionItem>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    Set predefined starting system message (OpenAI only)
                                </Box>
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                {/* <Button leftIcon={<AddIcon />} onClick={addMessage} mb={4}>
                                    Add Message
                                </Button> */}
                                {editorContentArrayDouble.map((promptObj, index) => (
                                    <Box key={index} mt={"5"}>
                                        <HStack mb={2}>
                                            <Select
                                                value={promptObj.role}
                                                onChange={(e) => handleRoleChange(index, e.target.value)}
                                                w={'10%'}
                                                minW={'150px'}
                                                size={'sm'}
                                                borderRadius={'md'}
                                                onBlur={() => updateStartingMessagesWorkspaceAndDB(editorContentArrayDouble)}
                                            >
                                                {/* {possibleRoles.map((role) => ( 
                                                    <option key={role} value={role}>{role}</option>
                                                ))} */}
                                                 <option key={"system"} value={"system"}>{"system"}</option>
                                            </Select>
                                            <Button size={"sm"} onClick={() => updateStartingMessagesWorkspaceAndDB(editorContentArrayDouble)} colorScheme="green" leftIcon={<UploadIcon />} isLoading={isSaveStartingMessageLoading}>Save</Button>
                                            {editorContentArrayDouble.length > 1 && (
                                                <Button onClick={() => deletePrompt(index)}
                                                    size={'sm'}
                                                    ml={2}
                                                    w={'10%'}
                                                    minW={'100px'}
                                                >
                                                    Delete
                                                </Button>
                                            )}
                                        </HStack>
                                        <MonacoEditor
                                            width="100%"
                                            height={editorHeight + "px"}
                                            language="chatter-ninja"
                                            theme="vs-dark"
                                            options={{
                                                wordWrap: 'on',
                                                automaticLayout: true,
                                                scrollBeyondLastLine: false, 
                                            }}
                                            editorDidMount={editorDidMount}
                                            value={promptObj.content}
                                            onChange={(value) => handleEditorArrayChange(index, value)}
                                        />
                                        <br/>
                                        <CollectionRunModal collectionID={collectionID} workspaceData={workspaceData} updateStartingMessages={() => updateStartingMessagesWorkspaceAndDB(editorContentArrayDouble)} />
                                    </Box>
                                ))}
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    Set which functions are directly accessible in this collection
                                </Box>
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                {workspaceFunctions && (
                                    <div>
                                        <Button size={"sm"} onClick={() => handleSaveFunctionsAccessible()} colorScheme="green" leftIcon={<UploadIcon />} isLoading={isSaveFunctionsLoading}>Save</Button>
                                        <Stack spacing={4} mt={5}>
                                            {workspaceFunctions && workspaceFunctions.length > 0 ? (
                                                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={-1} mb={2}>
                                                    {selectedFunctionNames && workspaceFunctions.map(func => (
                                                        <Box 
                                                            key={func.name} 
                                                            bg={selectedFunctionNames.includes(func.name) ? "orange.500" : "gray.200"} 
                                                            p={2}  
                                                            borderRadius="md"
                                                            cursor="pointer"
                                                            _hover={{ bg: selectedFunctionNames.includes(func.name) ? "orange.600" : "gray.300" }}
                                                            onClick={() => handleFunctionSelection(func)}
                                                        >
                                                            <Flex align="center" justify="center">
                                                                <Text color={selectedFunctionNames.includes(func.name) ? "white" : "black"}>{func.name}</Text>
                                                            </Flex>
                                                        </Box>
                                                    ))}
                                                </Grid>
                                            ) : (
                                                <Text fontWeight="bold" mt={"-2"} mb={"2"}>No functions in your workspace yet. Add one to start.</Text>
                                            )}
                                        </Stack>
                                    </div>
                                )}
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    Configure routes
                                </Box>
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <ConfigureCollectionRoutes workspaceFunctions={workspaceFunctions} collection={collection} workspaceData={workspaceData} setWorkspaceData={setWorkspaceData} />
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Other settings
                            </Box>
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <OtherCollectionSettings collection={collection} workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}/>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Labels
                            </Box>
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <LabelsSettingsComponent collection={collection} workspaceData={workspaceData} setWorkspaceData={setWorkspaceData}/>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem isHidden={!collection}>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Assertion templates
                            </Box>
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <AssertionTemplateComponent collection={collection} workspaceData={workspaceData} setWorkspaceData={setWorkspaceData} assertionTemplates={collection.assertion_templates} />
                        </AccordionPanel>
                    </AccordionItem>
                    </Accordion>
                </ModalBody>
                <ModalFooter>
                <Button onClick={handleModalClose}>Close</Button> 
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default WorkspaceCollectionSettingsModal;
