import React, {useState} from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Menu,
    MenuButton,
    MenuList,
    MenuItem, ModalFooter
} from '@chakra-ui/react';
import AzureForm from "./Model-Forms/AzureForm";
import {useUtilityFunctions} from "../../UtilityFunctions";
import axios from "axios";

const AddCustomModelFamilyModal = ({setCustomModels, workspaceID}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modelFamilyType, setModelFamilyType] = useState("");
    const modelFamilyOptions = ["azure"]; // Replace with your predefined options
    const [attributes, setAttributes] = useState({});  // New state to hold unique attributes
    const [modelFamilyName, setModelFamilyName] = useState("");
    const [isValid, setIsValid] = useState(false);
    const {getAccessToken} = useUtilityFunctions();


    const handleSubmit = async () => {
        try {
            const payload = {
                model_family: modelFamilyName,
                type: modelFamilyType,
                attributes: attributes,
            };

            // Update URL and headers
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceID}/models/family`,
                payload,
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceID)}`
                    }
                }
            );

            if (response.data) {
                // Update customModels state
                setCustomModels(prevCustomModels => ({
                    ...prevCustomModels,
                    [modelFamilyName]: payload
                }));

                setIsOpen(false);  // Close modal
            }


        } catch (error) {
            console.error("Error creating model:", error);
            // Handle error as needed
        }
    };


    return (
        <>
            <Button colorScheme="blue" size={'sm'} mb={4} onClick={() => setIsOpen(true)}>
                Add Custom Model
            </Button>

            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Add Custom Model</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Menu>
                            <MenuButton as={Button}>
                                {modelFamilyType || "Choose Model Family Type"}
                            </MenuButton>
                            <MenuList>
                                {modelFamilyOptions.map((option, index) => (
                                    <MenuItem key={index} onClick={() => setModelFamilyType(option)}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                        {modelFamilyType === "azure" &&
                            <AzureForm setAttributes={setAttributes} setModelFamilyName={setModelFamilyName}
                                       setIsValid={setIsValid}/>}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" isDisabled={!isValid} onClick={handleSubmit}>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddCustomModelFamilyModal;
