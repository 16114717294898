import {Box, Card, CardBody, CardHeader, Heading, HStack, Icon, Text, Tooltip, VStack} from "@chakra-ui/react";
import {FaClock, FaPercentage} from "react-icons/fa";
import {DollarIcon, StopwatchIcon, PercentageIcon, SmallTickIcon, SmallCrossIcon} from "evergreen-ui";
import React from "react";

const StatDisplay = ({stats}) => {

    return (
        <Box w={'100%'} p={4} borderWidth={1} borderRadius="lg">
            <Box pb={2}>
                <Heading size='sm'>Summary Statistics</Heading>
            </Box>
            <Box>
                <VStack>
                <HStack spacing={4}>
                    <Tooltip label="Total Time" fontSize="sm">
                        <HStack>
                            <Icon as={StopwatchIcon}/>
                            <Text>{parseFloat(stats.time).toFixed(5)}s</Text>
                        </HStack>
                    </Tooltip>
                    <Tooltip label="Prompt Tokens / Completion Tokens" fontSize="sm">
                        <HStack>
                            <Icon viewBox="0 0 24 24">
                                <path fill="none" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                            </Icon>
                            <Text>{stats.prompt_tokens} / {stats.completion_tokens}</Text>
                        </HStack>
                    </Tooltip>
                    <Tooltip label="Total Cost" fontSize="sm">
                        <HStack>
                            <Icon as={DollarIcon}/>
                            <Text>{parseFloat(stats.cost).toFixed(5)}</Text>
                        </HStack>
                    </Tooltip>
                </HStack>
                    <HStack spacing={4} justify="start">
                        <Tooltip label="# Passed" fontSize="sm">
                            <HStack>
                                <Icon as={SmallTickIcon}/>
                                <Text>{stats.passed}</Text>
                            </HStack>
                        </Tooltip>
                        <Tooltip label="# Failed" fontSize="sm">
                            <HStack>
                                <Icon as={SmallCrossIcon}/>
                                <Text>{stats.failed}</Text>
                            </HStack>
                        </Tooltip>
                        <Tooltip label="% Passed" fontSize="sm">
                            <HStack>
                                <Icon as={PercentageIcon}/>
                                <Text>{stats.percent_success}</Text>
                            </HStack>
                        </Tooltip>
                    </HStack>
                </VStack>
            </Box>
        </Box>
    );
}

export default StatDisplay;