import {useEffect, useState} from 'react';
import {
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter, Tag, Divider,
    Table, Thead, Tbody, Tr, Th, Td,
    Box
} from '@chakra-ui/react';

const ResultBreakdown = ({currRunState}) => {

    const calculateCellStats = () => {
        const cellStats = {};

        currRunState.test_results.forEach(test => {
            test.cell_results.forEach(cell => {
                const cellId = cell.cell_id;
                const usage = cell.usage;

                if (!cellStats[cellId]) {
                    cellStats[cellId] = {
                        totalTime: 0,
                        totalPromptTokens: 0,
                        totalCompletionTokens: 0,
                        totalCost: 0,
                        totalTests: 0,
                        passedTests: 0,
                    };
                }

                cellStats[cellId].totalTime += usage.time;
                cellStats[cellId].totalPromptTokens += usage.prompt_tokens;
                cellStats[cellId].totalCompletionTokens += usage.completion_tokens;
                cellStats[cellId].totalCost += usage.cost;
                cellStats[cellId].totalTests += 1;
                cellStats[cellId].passedTests += cell.passed ? 1 : 0;
            });
        });

        return cellStats;
    };

    const formatToFiveDecimals = (num) => {
        const truncated = Math.floor(num);
        return truncated === num ? truncated.toString() : num.toFixed(5);
    };


    return (
        <>
            <Popover placement="right">
                <PopoverTrigger>
                    <Button size={'xs'}> More Details </Button>
                </PopoverTrigger>
                <PopoverContent w="400px">
                    <PopoverHeader><Tag size={'lg'}>Result Breakdown</Tag></PopoverHeader>
                    <PopoverBody h={"300px"} style={{overflowY: 'auto'}}>
                        {
                            Object.entries(calculateCellStats()).map(([_, stats], index, array) => {
                                const isLastItem = index === array.length - 1;
                                return (
                                    <div key={index}>
                                        <Tag size="sm" colorScheme="blue">Cell {index + 1}</Tag>
                                        <p>Total Time: {formatToFiveDecimals(stats.totalTime)}s</p>
                                        <p>Total Prompt Tokens: {formatToFiveDecimals(stats.totalPromptTokens)}</p>
                                        <p>Total Completion
                                            Tokens: {formatToFiveDecimals(stats.totalCompletionTokens)}</p>
                                        <p>Total Cost: {formatToFiveDecimals(stats.totalCost)}</p>
                                        <p>Percent of Tests
                                            Passed: {formatToFiveDecimals((stats.passedTests / stats.totalTests) * 100)}%</p>
                                        {!isLastItem && <Divider mt={2} mb={2}/>}
                                    </div>
                                )
                            })
                        }
                        <Divider mt={2} mb={2}/>
                        <div style={{overflowX: 'auto'}}>
                            <Table variant="simple" size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>Cell</Th>
                                        <Th>Tests Passed (%)</Th>
                                        <Th>Total Time (s)</Th>
                                        <Th>Total Cost</Th>
                                        <Th>Total Prompt Tokens</Th>
                                        <Th>Total Completion Tokens</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {Object.entries(calculateCellStats()).map(([_, stats], index) => (
                                        <Tr key={index}>
                                            <Td><Tag size="sm" colorScheme="blue">{index + 1}</Tag></Td>
                                            <Td>{formatToFiveDecimals((stats.passedTests / stats.totalTests) * 100)}%</Td>
                                            <Td>{formatToFiveDecimals(stats.totalTime)}</Td>
                                            <Td>{formatToFiveDecimals(stats.totalCost)}</Td>
                                            <Td>{formatToFiveDecimals(stats.totalPromptTokens)}</Td>
                                            <Td>{formatToFiveDecimals(stats.totalCompletionTokens)}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </div>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    );
};

export default ResultBreakdown;
