import {
    Box,
    PopoverContent,
    Text,
    Button,
    Center,
    VStack,
    useColorModeValue,
    Stack,
    Divider,
    Icon,
    HStack,
    Spacer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Input,
    useDisclosure,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';

import {useLocation, useNavigate} from 'react-router-dom';


import {Link as RouterLink} from 'react-router-dom';

import SignInModal from '../UserAuth/SignInModal'

import {WarningTwoIcon, CheckIcon, AddIcon} from '@chakra-ui/icons';
import CreateAccountModal from '../UserAuth/CreateAccountModal';
import {useState, useRef, useEffect, Fragment} from 'react';
import axios from 'axios';
import {useUtilityFunctions} from "../UtilityFunctions";

const WorkspaceDropdownComponent = ({isLoggedIn, workspacesData, selectedWorkspaceId, setSelectedWorkspaceId}) => {
    const [currWorkspacesData, setCurrWorkspacesData] = useState(workspacesData)
    const location = useLocation();


    const navigate = useNavigate();

    useEffect(() => {
        setCurrWorkspacesData(workspacesData);
        if (isLoggedIn && workspacesData && workspacesData.length > 0) {
            if (!selectedWorkspaceId) {
                const firstWorkspaceId = workspacesData[0].id;
                setSelectedWorkspaceId(firstWorkspaceId);
                if (location.pathname === '/' || location.pathname === '/#' || location.pathname === '') {
                    setTimeout(() => {
                        navigate(`/workspace/${firstWorkspaceId}`);
                    }, 100); // short delay before navigating
                }
            }
        }
    }, [workspacesData, isLoggedIn, navigate, selectedWorkspaceId, location]);


    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <PopoverContent
            border={0}
            boxShadow={'xl'}
            bg={useColorModeValue('white', 'gray.800')}
            rounded={'xl'}
            minW={isLoggedIn ? 'xs2' : 'md'}
            minH={isLoggedIn ? 'xs2' : 'md'}
            display="flex"
            justifyContent={isLoggedIn ? "flex-start" : "center"}
            alignItems={isLoggedIn ? "flex-start" : "center"}
            fontFamily={'Inter, sans-serif'}
            maxH="50vh"
            overflowY="scroll"
        >
            {!isLoggedIn ? (
                <Center h="100%">
                    <VStack>
                        <Icon as={WarningTwoIcon} w={10} h={10} mb={4} color="orange.500"/>
                        <Text fontSize={'md'} fontWeight={500} mb={8} textAlign="center">
                            You need an account to continue exploring Chatter.
                        </Text>
                        <HStack spacing={4}>
                            <SignInModal/>
                            <Text width="30px" textAlign="center">OR</Text>
                            <CreateAccountModal/>
                        </HStack>
                    </VStack>
                </Center>
            ) : (
                <VStack w="100%" spacing={0}>
                    <Button
                        onClick={onOpen}
                        fontSize="sm"
                        fontWeight="regular"
                        color="black"
                        width="100%"
                        borderRadius={0}
                        bg={"#FFFFFF"}
                        _hover={{bg: '#F2F2F2'}}
                        _active={{bg: '#F2F2F2'}}
                        justifyContent="flex-start"
                        pl={2}
                    >
                        <HStack w="100%" spacing={3} pl={3}>
                            <AddIcon w={3} color="black"/> :
                            <Text>Add Workspace</Text>
                        </HStack>
                    </Button>
                    <Divider borderColor="gray.300"/>
                    {currWorkspacesData.length === 0 ?
                        <Center w="100%" p={20}>
                            <Text>Add a workspace <br/> to get started.</Text>
                        </Center>
                        :
                        currWorkspacesData.map((workspace, index) => (
                            <Fragment key={workspace.id}>
                                <Button as={RouterLink} to={`/workspace/${workspace.id}`}
                                        onClick={() => setSelectedWorkspaceId(workspace.id)}
                                        fontSize="sm"
                                        fontWeight="regular"
                                        color="black"
                                        width="100%"
                                        borderRadius={0}
                                        bg={"#FFFFFF"}
                                        _hover={{bg: '#F2F2F2'}}
                                        _active={{bg: '#F2F2F2'}}
                                        justifyContent="flex-start"
                                        pl={2}
                                >
                                    <HStack w="100%" spacing={3} pl={3}>
                                        {workspace.id === selectedWorkspaceId ?
                                            <CheckIcon w={3} color="black"/> :
                                            <Box w={3} h={3}/> // empty box of the same size as CheckIcon
                                        }
                                        <Text>{workspace.name}</Text>
                                    </HStack>
                                </Button>

                            </Fragment>
                        ))
                    }
                    <AddWorkspaceModal isOpen={isOpen} onClose={onClose} setCurrWorkspacesData={setCurrWorkspacesData}
                                       setSelectedWorkspaceId={setSelectedWorkspaceId} currWorkspacesData={currWorkspacesData}/>
                </VStack>
            )}
        </PopoverContent>
    );
};

export default WorkspaceDropdownComponent;


const AddWorkspaceModal = ({isOpen, onClose, setCurrWorkspacesData, setSelectedWorkspaceId, currWorkspacesData}) => {
    const initialRef = useRef();
    const [workspaceName, setWorkspaceName] = useState(''); // Add this line
    const navigate = useNavigate();
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    useEffect(() => {
        // when closes, clear
        if (!isOpen) {
            setWorkspaceName('');
        }
    }, [isOpen]);

    const handleAddWorkspace = async () => {
        if (getAccessToken()) {
            const currToken = getAccessToken();
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace`,
                    {
                        name: workspaceName || "Initial Workspace",
                        init_collection: 'initial_collection' // This can be changed based on your requirement
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${currToken}`
                        }
                    }
                );
                setCurrWorkspacesData(prevState => [...prevState, response.data.workspace]);
                setSelectedWorkspaceId(response.data.workspace.id)
                navigate(`/workspace/${response.data.workspace.id}`);
                onClose();
            } catch (err) {
                console.error(err);
            }
        } else {
            navigate("/")
        }
    };

    return (
        <>
            <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Create Workspace</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Workspace name</FormLabel>
                            <Input ref={initialRef} placeholder="Workspace name" value={workspaceName}
                                   onChange={e => setWorkspaceName(e.target.value)}/>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button bg={'#ED7547'} _hover={{
                            bg: '#D05D32'
                        }} mr={3} color={'white'} onClick={handleAddWorkspace}>
                            Create
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
