import React, {useEffect, useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button, Select,
} from "@chakra-ui/react";
import axios from "axios";
import {useUtilityFunctions} from "../../UtilityFunctions";


const getModelFamilyFilters = (modelFamily) => {
    if (modelFamily.startsWith('azure-')) {
        return {
            allowedProviders: ['OpenAI'],
            allowedModels: ['gpt-3.5-turbo', 'gpt-3.5-turbo-16k', 'gpt-4', 'gpt-4-32k'],
        };
    }

    return {
        allowedProviders: ['OpenAI', 'Anthropic'],
        allowedModels: ['gpt-3', 'gpt-4'],
    };
};

const AddEngineModal = ({isOpen, onClose, workspaceID, modelFamily, setCustomModels}) => {
    const [engineName, setEngineName] = useState("");
    const [engineConfig, setEngineConfig] = useState("");
    const {getAccessToken} = useUtilityFunctions();
    const {allowedProviders, allowedModels} = getModelFamilyFilters(modelFamily);


    const [modelData, setModelData] = useState({});
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/model`, {});
            setModelData(response.data);
        };

        if (isOpen) {
            fetchData();
        }
    }, [isOpen]);

    const handleProviderSelect = (e) => {
        setSelectedProvider(e.target.value);
    };


    const handleModelSelect = (e) => {
        setSelectedModel(e.target.value);
        setEngineConfig(modelData[selectedProvider] ? modelData[selectedProvider][e.target.value] : "");
    };


    const handleAddEngine = async () => {
        try {
            // Perform your API call here
            await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceID}/models/family/${modelFamily}/engines`,
                {
                    name: engineName,
                    config: engineConfig,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceID)}`,
                    },
                }
            );

            // Update your local state here if necessary
            setCustomModels((prevState) => {
                const newCustomModels = {...prevState};
                if (!newCustomModels[modelFamily]['engines']) {
                    newCustomModels[modelFamily]['engines'] = {};
                }
                newCustomModels[modelFamily]['engines'][engineName] = engineConfig;
                return newCustomModels;
            });

            onClose();

        } catch (error) {
            console.error("Error adding engine:", error);
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Add Engine</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <FormControl isRequired>
                        <FormLabel>Engine Name</FormLabel>
                        <Input placeholder="Engine Name" value={engineName}
                               onChange={(e) => setEngineName(e.target.value)}/>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Select Provider</FormLabel>
                        <Select placeholder="Select Provider" onChange={handleProviderSelect}>
                            {Object.keys(modelData).filter(provider => allowedProviders.includes(provider))
                                .map(provider => <option key={provider} value={provider}>{provider}</option>)}
                        </Select>
                    </FormControl>
                    {selectedProvider && (
                        <FormControl isRequired>
                            <FormLabel>Select Model</FormLabel>
                            <Select placeholder="Select Model" onChange={handleModelSelect}>
                                {Object.keys(modelData[selectedProvider]).filter(model => allowedModels.includes(model))
                                    .map(model => <option key={model} value={model}>{model}</option>)}
                            </Select>
                        </FormControl>
                    )}
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    <Button colorScheme="blue" ml={3} onClick={handleAddEngine}>Add</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddEngineModal;
