import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box, Button, Text, Select, Textarea, Flex, Stack, Grid, IconButton, useToast } from "@chakra-ui/react";
import { AddIcon} from "@chakra-ui/icons";
import { TrashIcon, UploadIcon } from 'evergreen-ui';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useUtilityFunctions} from "../UtilityFunctions";

const ConfigureCollectionRoutes = ({ workspaceFunctions, workspaceData, collection }) => {
    const [routes, setRoutes] = useState([]);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const {getAccessToken} = useUtilityFunctions();
    const toast = useToast();

    const saveRoutesToDatabase = async () => {
        setSaveIsLoading(true);

        try {
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/update-collection-routes`, {
                workspace_id: workspaceData._id,
                collection_id: collection.id,
                routes: routes
            }, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                },
            });

            // 3. Update the local state (`workspaceData`)
            const updatedWorkspace = { ...workspaceData };
            const targetCollection = updatedWorkspace.collections.find(col => col.id === collection.id);
            
            if (targetCollection) {
                if (!targetCollection.routes) {
                    targetCollection.routes = [];
                }
                targetCollection.routes = routes;
            }

            setSaveIsLoading(false);
            // Handle successful response or update your state accordingly
        } catch (error) {
            setSaveIsLoading(false);
            // Handle error response
            console.error("Failed to update routes", error);
        }
    };

    useEffect(() => {
        if (collection && collection.routes) {
           setRoutes(collection.routes)
        }
    }, [collection]);

    const addRoute = () => {
        if (workspaceFunctions.length > 0) {
            setRoutes([...routes, {
                route_entrance_function_id: getFunctionId(workspaceFunctions[0].name),
                system_message: '',
                available_implementation_functions: []
            }]);
        } else {
            toast({
                title: "No workspace functions.",
                description: "Add a function in your workspace first!",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSelectChange = (index, key, value) => {
        const updatedRoutes = [...routes];
        updatedRoutes[index][key] = value;
        setRoutes(updatedRoutes);
    };

    const getFunctionId = (functionName) => {
        if (workspaceData && workspaceData.aux && workspaceData.aux.functions) {
            const funcObj = workspaceData.aux.functions.find(func => func.function.name === functionName);
            return funcObj ? funcObj._id : null;
        } else {
            return null
        }
    }

    const handleFunctionSelection = (index, funcName) => {
        const funcId = getFunctionId(funcName);
        const updatedRoutes = [...routes];
        const isSelected = updatedRoutes[index].available_implementation_functions.some(obj => obj.function_id === funcId);
        if (isSelected) {
            updatedRoutes[index].available_implementation_functions = updatedRoutes[index].available_implementation_functions.filter(obj => obj.function_id !== funcId);
        } else {
            updatedRoutes[index].available_implementation_functions.push({ function_id: funcId });
        }
        setRoutes(updatedRoutes);
    };

    const deleteRoute = (indexToDelete) => {
        const updatedRoutes = routes.filter((_, index) => index !== indexToDelete);
        setRoutes(updatedRoutes);
    };

    return (
        <div>
            {workspaceFunctions && workspaceFunctions.length ? (
            <>
            <Flex justifyContent="space-between" mb={4}>
                <Button leftIcon={<AddIcon />} onClick={addRoute}>Add Route</Button>
                <Button leftIcon={<UploadIcon />} isLoading={saveIsLoading} onClick={saveRoutesToDatabase} colorScheme="green">Save Routes</Button>
            </Flex>
            <Accordion allowToggle>
                {routes.map((route, index) => (
                    <AccordionItem key={index}>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Route {index + 1}
                            </Box>
                            <IconButton 
                                icon={<TrashIcon />} 
                                size="sm" 
                                aria-label="Delete route"
                                onClick={(e) => {
                                    e.stopPropagation();  // To prevent the Accordion from toggling
                                    deleteRoute(index);
                                }} 
                            />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            Function called to enter this route:
                            <Select value={route.route_entrance_function_id} onChange={(e) => handleSelectChange(index, 'route_entrance_function_id', e.target.value)} mb={4}>
                                {workspaceFunctions.map(func => (
                                    <option key={getFunctionId(func.name)} value={getFunctionId(func.name)}>{func.name}</option>
                                ))}
                            </Select>
                            System message:
                            <Textarea placeholder="Enter this route's system message" value={route.system_message} onChange={(e) => handleSelectChange(index, 'system_message', e.target.value)} mb={4} />
                            
                            Choose Implementation Functions:
                            {workspaceFunctions && workspaceFunctions.length > 0 && route && route.available_implementation_functions &&  (
                            <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2} mb={4}>
                                {workspaceFunctions.map(func => {
                                    const functionId = getFunctionId(func.name);
                                    const isSelected = route.available_implementation_functions.some(obj => obj.function_id === functionId);
                                    return (
                                        <Box 
                                            key={functionId} 
                                            bg={isSelected ? "orange.500" : "gray.200"} 
                                            p={2}  
                                            borderRadius="md"
                                            cursor="pointer"
                                            _hover={{ bg: isSelected ? "orange.600" : "gray.300" }}
                                            onClick={() => handleFunctionSelection(index, func.name)}
                                        >
                                            <Flex align="center" justify="center">
                                                <Text color={isSelected ? "white" : "black"}>{func.name}</Text>
                                            </Flex>
                                        </Box>
                                    );
                                })}
                            </Grid>
                        )}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
            </>
            ) : (
                <Text fontSize="xl" fontWeight="bold" textAlign="center" mt={5}>
                    Add workspace functions first
                </Text>
            )}
        </div>
    );
};

export default ConfigureCollectionRoutes;
