import { 
    Modal, ModalOverlay, ModalContent, ModalHeader, 
    ModalBody, ModalCloseButton, ModalFooter,
    Button, Text
} from "@chakra-ui/react";

import React, { useState } from 'react';

const ControllerAlertModal = ({ isOpen, onClose, updateChainController, chainID, editorRef }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleTakeControl = async () => {
        setIsLoading(true);
        await updateChainController(chainID);
        setIsLoading(false);
        onClose();
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Not Active Editor</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>You are not the active editor of this prompt chain and may have to reload to see the most updated version. </Text>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        colorScheme="blue" 
                        onClick={handleTakeControl} 
                        isLoading={isLoading}
                        loadingText="Taking Control"
                    >
                        Take Control
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ControllerAlertModal;