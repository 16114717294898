import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Menu, MenuButton, MenuList, MenuItem, Text} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import axios from "axios";
import {useUtilityFunctions} from "../UtilityFunctions";


const ModelMenu = ({modelData, setLocalCell, cellID, modelFamily, setModelFamily, setModel, model, setModelDetails, workspaceDataID}) => {
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (!initialLoad) {
            setModel(null);
        } else {
            setInitialLoad(false);
        }
    }, [modelFamily]);


    const updateModelData = async (newModelFamily, newModel) => {
        if (newModelFamily && newModel) {
            setModelFamily(newModelFamily)
            setModel(newModel)
            try {
                const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cellID}/update/models`,
                    { model_family: newModelFamily, model: newModel },
                    {
                        headers: {
                            'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                        }
                    });
                setLocalCell(prevCell => ({...prevCell, config: {...prevCell.config, model_family: newModelFamily, model: newModel}}));
                // console.log(response.data);
            } catch (error) {
                console.error("Failed to update model: ", error);
            }
        }
    }

    return (
        <Box>
            <Menu>
                {({isOpen}) => (
                    <>
                        <MenuButton isActive={isOpen} as={Button} size={'sm'}>
                            {modelFamily || 'Select Model Family'}
                        </MenuButton>
                        <MenuList>
                            {modelData && Object.keys(modelData).map((family) => (
                                <MenuItem key={family} onClick={() => updateModelData(family, model)}>
                                    {family}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </>
                )}
            </Menu>

            {modelFamily && modelData[modelFamily] && (
                <>
                    <Text as="span" mx={2}>:</Text>
                    <Menu>
                        {({isOpen}) => (
                            <>
                                <MenuButton isActive={isOpen} as={Button} size={'sm'}>
                                    {model || 'Select Model'}
                                </MenuButton>
                                <MenuList>
                                    {Object.keys(modelData[modelFamily]).map((currModel) => (
                                        <MenuItem key={currModel} onClick={() => updateModelData(modelFamily, currModel)}>
                                            {currModel}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </>
                        )}
                    </Menu>
                </>
            )}
        </Box>
    );
};

export default ModelMenu;
