import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    HStack,
    Icon,
    Input,
    Circle,
    useToast,
    Spacer,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import { AddIcon} from "@chakra-ui/icons";
import { UploadIcon } from 'evergreen-ui';
import { SketchPicker } from 'react-color'; 
import {useUtilityFunctions} from "../UtilityFunctions";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


const LabelsSettingsComponent = ( {collection, workspaceData, setWorkspaceData} ) => {
    const [labels, setLabels] = useState([{ id: uuidv4(), label_color: '#e6e6e6', label_name: '' }]);
    const [colorPicker, setColorPicker] = useState({ isOpen: false, activeColorIndex: null });
    const colorPickerRef = useRef(null);
    const {getAccessToken} = useUtilityFunctions();
    const toast = useToast();
    const [isLoadingSave, setIsLoadingSave] = useState(false)
    const uuid = uuidv4();


    useEffect(() => {
        // // Initialize labels from workspaceData when it changes
        if (workspaceData) {
            const collectionLabels = workspaceData.collections.find((col) => col.id === collection.id)?.labels || [];
            setLabels(collectionLabels);
        }
    }, [workspaceData, collection]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            // If the color picker is open and the clicked target is outside the color picker, close it
            if (colorPicker.isOpen && colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                closeColorPicker();
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleDocumentClick);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [colorPicker.isOpen]);  // Re-run the effect only when colorPicker.isOpen changes

    const handleAddLabel = () => {
        const newLabel = { id: uuidv4(), label_color: '#e6e6e6', label_name: '' };
        setLabels([...labels, newLabel]);
    };
    

    const handleDeleteLabel = (id) => {
        setLabels(labels.filter((label, idx) => label.id !== id));
    };

    const handleNameChange = (id, value) => {
        const newLabels = labels.map((label) => {
            if (label.id === id) {
                return { ...label, label_name: value };
            }
            return label;
        });
        setLabels(newLabels);
    };
    
    const handleColorChange = (color) => {
        const newLabels = [...labels];
        newLabels[colorPicker.activeColorIndex].label_color = color.hex;
        setLabels(newLabels);
    };

    const openColorPicker = (index) => {
        setColorPicker({ isOpen: true, activeColorIndex: index });
    };

    const closeColorPicker = () => {
        setColorPicker({ isOpen: false, activeColorIndex: null });
    };

    const handleSaveLabels = async () => {
        
        try {
            setIsLoadingSave(true)
            // 1. Create a request to your backend endpoint to save the labels
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/update-labels`, {
                workspace_id: workspaceData._id,
                collection_id: collection.id,
                labels: labels.map(label => ({
                    id: label.id,
                    label_name: label.label_name,
                    label_color: label.label_color,
                }))
            }, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                },
            });

            // 2. Check for a successful response
            if (response.status === 200) {
                // 3. Update the local state (`workspaceData`)
                const updatedWorkspace = { ...workspaceData };
                const targetCollection = updatedWorkspace.collections.find(col => col.id === collection.id);

                if (targetCollection) {
                    if (!targetCollection.labels) {
                        targetCollection.labels = [];
                    }
                    targetCollection.labels = labels.map(label => ({
                        id: label.id,
                        label_name: label.label_name,
                        label_color: label.label_color,
                    }));
                }
                setWorkspaceData(updatedWorkspace);  // Update the main workspace data state
                setIsLoadingSave(false)
            }
        } catch (error) {
            // Handle error response
            console.error("Failed to update labels", error);
            toast({
                title: "Failed to update labels.",
                description: "Could not update labels, please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setIsLoadingSave(false)
        }
    };

    return (
        <Box>
            <HStack mb={4} spacing={4}>
                <Button leftIcon={<Icon as={AddIcon} />} onClick={handleAddLabel} size={"sm"}>
                    Add Row
                </Button>
                <Spacer />
                <Button leftIcon={<Icon as={UploadIcon} />} colorScheme="green" onClick={handleSaveLabels} size={"sm"} isLoading={isLoadingSave}>
                    Save labels
                </Button>
            </HStack>

            {labels.map((label, index) => (
                <HStack key={label.id} spacing={4} mb={2}>
                    <Circle size="25px" bg={label.label_color} onClick={() => openColorPicker(index)} cursor="pointer" />
                    <Input value={label.label_name} onChange={(e) => handleNameChange(label.id, e.target.value)} placeholder="Label Name" />
                    <Button variant="ghost" onClick={() => handleDeleteLabel(label.id)}>
                        <Icon as={FaTrash} />
                    </Button>
                </HStack>
            ))}

            {colorPicker.isOpen && (
               <div ref={colorPickerRef} style={{ display: 'inline-block' }}>
               <SketchPicker
                   color={labels[colorPicker.activeColorIndex]?.label_color}
                   onChangeComplete={handleColorChange}
               />
           </div>
            )}
        </Box>
    );
};

export default LabelsSettingsComponent;
