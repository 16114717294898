import {useState} from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Input,
    Stack, Box, Select, FormControl, IconButton, MenuList, Menu, MenuButton, MenuItem, ButtonGroup,
    useToast
} from "@chakra-ui/react";
import {ChevronDownIcon, PlusSquareIcon} from '@chakra-ui/icons';
import axios from 'axios';
import {MinusIcon} from "evergreen-ui";
import {useUtilityFunctions} from "../UtilityFunctions";
import { useEffect } from 'react';

const CreateVaultModal = ({modelData, setVaults, workspaceDataID}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [vaultName, setVaultName] = useState('');
    const [keys, setKeys] = useState([{model_family: '', key: ''}]);
    const {getAccessToken} = useUtilityFunctions();
    const [availableModelFamilies, setAvailableModelFamilies] = useState([])
    
    const toast = useToast()
    
    useEffect(() => {
        setAvailableModelFamilies(Object.keys(modelData).filter(family => !keys.map(key => key.model_family).includes(family)))
      }, [modelData, keys]);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    const handleAddKey = () => {
        if (keys.length < Object.keys(modelData).length) {
            setKeys([...keys, { model_family: '', key: '' }]);
        } else {
            toast({
                title: "Max number of keys reached.",
                description: "Can only add keys for " + Object.keys(modelData).join(', '),
                status: "warning",
                duration: 5000,
                isClosable: true,
              });
        }
    };

    const handleRemoveKey = (index) => {
        setKeys(keys.filter((_, keyIndex) => keyIndex !== index));
    };

    const handleKeyChange = (index, field, value) => {
        const newKeys = [...keys];
        newKeys[index][field] = value;
        setKeys(newKeys);
    };

    const handleCreateVault = async () => {
        if (!keys.every(key => key.model_family.trim() !== '' && key.key.trim() !== '')) {
            toast({
                title: "Empty pairs found",
                description: "All vault key value pairs must be non-empty.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const payload = {
            name: vaultName,
            keys: keys.map(key => ({...key, org_id: "null"})), // adding org_id field to each key object
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/vault`, payload, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                }
            });

            if (response.status === 200) {
                console.log('Vault created successfully!');
                const newVault = response.data.vault;
                setVaults(prevVaults => [...prevVaults, newVault]);
                setVaultName('');
                setKeys([{model_family: '', key: ''}]);
                handleClose();
            }
        } catch (error) {
            console.error('Error creating vault:', error);
        }
    };



    

    return (
        <>
            <Button colorScheme="teal" onClick={handleOpen} leftIcon={<PlusSquareIcon/>}>
                Create Vault
            </Button>

            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Create a new Vault</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Stack spacing={3}>
                            <Input placeholder="Vault Name" value={vaultName}
                                   onChange={(e) => setVaultName(e.target.value)}/>
                            {keys.map((key, index) => (
                                <Box key={index} display="flex" alignItems="center" w={'100%'}>
                                    <FormControl>
                                        <Menu>
                                            <MenuButton w={"100%"} as={Button} rightIcon={<ChevronDownIcon />} placeholder="Select Model Family" >
                                                {key.model_family || "Select Model Family"}
                                            </MenuButton>
                                            <MenuList>
                                                {availableModelFamilies.map((family) => (
                                                    <MenuItem key={family}
                                                              onClick={() => handleKeyChange(index, 'model_family', family)}>
                                                        {family}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </Menu>
                                    </FormControl>
                                    <Input ml={2} mr={2} placeholder="Key" value={key.key}
                                           onChange={(e) => handleKeyChange(index, 'key', e.target.value)}/>
                                    <IconButton aria-label="Remove key" icon={<MinusIcon/>}
                                                onClick={() => handleRemoveKey(index)}/>
                                </Box>
                            ))}
                            <Button onClick={handleAddKey} variant="outline" colorScheme="teal">Add Key</Button>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleCreateVault}>
                            Create
                        </Button>
                        <Button variant="ghost" onClick={handleClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default CreateVaultModal;
