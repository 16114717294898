import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    HStack,
    VStack,
    Select,
    Textarea,
    Button,
    Icon,
    useDisclosure,
    Flex
  } from "@chakra-ui/react";
import { AddIcon, ArrowDownIcon, PanelTableIcon} from "evergreen-ui";
import {useEffect, useState} from 'react';
import axios from "axios";

import { PlayIcon, CodeIcon, DocumentShareIcon } from 'evergreen-ui';

import Cell from '../Cell/Cell'
import {useUtilityFunctions} from "../UtilityFunctions";

const ConvertedPlayIcon = () => <Icon as={PlayIcon}/>;
const ConvertedCodeIcon = () => <Icon as={CodeIcon}/>;
const ConvertedArrowDownIcon = () => <Icon as={ArrowDownIcon} color="#ED7547"/>;


const Chain = ({
                   chainName, workspaceDataID, workspaceData, chainID,
                   handleIconClick, currRunState, setCurrRunState,
                   cells, setCells, suiteData, setSuiteData, modelData,
                   allOpen, fetchSuiteData, mergedModelData, chainData, addCell
               }) => {
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    const checkChainController = async (chain_ID) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/chain/${chain_ID}/check-update-controller`, {
              chainID: chain_ID
          }, {
              headers: {
                  'Authorization': `Bearer ${getAccessToken()}`
              }
          });
          return response.data.is_controller;

      } catch (error) {
          console.error('Failed to check chain controller:', error);
          return true;
      }
  };

  const updateChainController = async (chain_ID) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/chain/${chain_ID}/update-controller`, {
            chainID: chain_ID
        }, {
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`
            }
        });

    } catch (error) {
        console.error('Failed to change chain controller:', error);
        return true;
    }
};




    // Find the collection for the given chain ID
    const collectionForChain = workspaceData.collections.find(collection =>
        collection.chains.some(chain => chain.id === chainID)
    );




    const updateCell = (updatedCell, index) => {
        setCells(prevCells => prevCells.map((cell, i) => i === index ? updatedCell : cell));
    };

    const deleteCell = (cellId) => {
        setCells(prevCells => prevCells.filter(cell => cell._id !== cellId));
        fetchSuiteData();
    };


    return (
      <Box>
        {cells.map((cell, index) => {
          // If it's "chat" type and not the first cell, skip rendering
          if (chainData && chainData.config && chainData.config.type === "chat" && index > 0) {
              return null; // Skip rendering
          }

          return (
            <VStack key={cell._id}>
            <Cell cell={cell} cellIndex={index} workspaceDataID={workspaceDataID} workspaceData={workspaceData} chainID={chainID} currRunState={currRunState} setCurrRunState={setCurrRunState} allOpen={allOpen} modelData={modelData} 
            suiteData={suiteData} setSuiteData={setSuiteData} checkChainController={checkChainController} updateChainController={updateChainController}
            deleteCell={() => deleteCell(cell._id)} 
            updateCell={cell => updateCell(cell, index)} handleIconClick={handleIconClick}
            mergedModelData={mergedModelData} chainData={chainData}/>
            {index < cells.length - 1 && !(chainData && chainData.config && chainData.config.type === "chat") && (
              <VStack align="center" py={5}>
                <ConvertedArrowDownIcon />
              </VStack>
            )}
          </VStack>
          );
      })}
        <br/>
        {(!(chainData && chainData.config && chainData.config.type && chainData.config.type === "chat")) && <Button 
                leftIcon={<AddIcon boxSize="3" />} 
                variant="ghost" 
                justifyContent="center" 
                width="full" 
                mb={3}
                fontSize="sm" 
                fontWeight="normal" 
                onClick={addCell}
                >
                Add Cell
            </Button>}
      </Box>
    );
  };

  export default Chain; 
