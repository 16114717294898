import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Flex,
    Tooltip,
    IconButton, Input
} from "@chakra-ui/react";
import {CopyIcon, DeleteIcon} from "@chakra-ui/icons";
import axios from "axios";
import {useToast} from "@chakra-ui/react";
import {useState, useEffect} from "react";
import {useUtilityFunctions} from "../UtilityFunctions";

const ApiKeyModal = ({isOpen, onClose}) => {
    const [apiKeys, setApiKeys] = useState([]);
    const [apiKeyName, setApiKeyName] = useState('');
    const toast = useToast();
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    useEffect(() => {
        if (isOpen) {
            axios
                .get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/keys/api-keys`, {
                    headers: {Authorization: `Bearer ${getAccessToken()}`},
                })
                .then((response) => setApiKeys(response.data.api_keys))
                .catch((error) => console.error(error));
        }
    }, [isOpen]);

    const handleCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        toast({
            title: "Copied",
            description: "API Key has been copied to clipboard",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    const handleDelete = async (apiKey) => {
        // Call to delete API key
        await axios.delete(
            `${process.env.REACT_APP_ROUTE_PREFIX}/api/keys/api-keys`,
            {
                data: {api_key: apiKey.key},
                headers: {Authorization: `Bearer ${getAccessToken()}`}
            }
        );
        setApiKeys(apiKeys.filter((key) => key !== apiKey));
    };

    const handleCreateApiKey = async () => {
        if (apiKeyName === "") {
            toast({
                title: "Error",
                description: "API key name cannot be empty",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/keys/api-keys`,
                { api_key_name: apiKeyName },
                {headers: {Authorization: `Bearer ${getAccessToken()}`}}
            );

            const newApiKey = response.data.api_key;
            setApiKeys([...apiKeys, newApiKey]);

            toast({
                title: "API Key Created",
                description: "New API key has been created successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "An error occurred while creating the API key",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>ChatterSDK</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Flex mb={3}>
                        <Input
                            placeholder="API key name"
                            value={apiKeyName}
                            onChange={(e) => setApiKeyName(e.target.value)}
                            mr={4}
                        />
                        <Button onClick={handleCreateApiKey} colorScheme="blue" pl={8} pr={8}>
                            Create API Key
                        </Button>
                    </Flex>
                    {apiKeys.length === 0 ? (
                        <Text>No API keys exist. Please create one.</Text>
                    ) : (
                        apiKeys.map((apiKey, index) => (
                            <Flex
                                key={index}
                                p={2}
                                mb={2}
                                borderRadius="md"
                                bgColor="gray.200"
                                alignItems="center"
                                justifyContent="space-between"
                                _hover={{bgColor: "gray.300"}}
                            >
                                <Tooltip label={apiKey.key} placement="top">
                                    <Text onClick={() => handleCopy(apiKey.key)}>{apiKey.name}</Text>
                                </Tooltip>
                                <Flex>
                                    <IconButton
                                        icon={<CopyIcon/>}
                                        onClick={() => handleCopy(apiKey.key)}
                                        size="xs"
                                        mr={2}
                                    />
                                    <IconButton
                                        icon={<DeleteIcon/>}
                                        onClick={() => handleDelete(apiKey)}
                                        size="xs"
                                    />
                                </Flex>
                            </Flex>
                        ))
                    )}
                </ModalBody>
                <ModalFooter>

                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ApiKeyModal;
