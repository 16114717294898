import {
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Box,
    Text,
    Flex,
    Center,
    Icon,
    Divider,
    Spacer,
    VStack,
    useToast,
    Image,
    UnorderedList,
    ListItem,
} from "@chakra-ui/react";
import {ChatIcon} from '@chakra-ui/icons'
import {useState} from 'react';
import validator from 'validator';
import axios from 'axios';


const CustomChatIcon = (props) => (

    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.8}
         stroke="currentColor" {...props}>
        <path strokeLinecap="round"
              d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"/>
    </svg>

);


const CreateAccountModal = ({setIsLoggedIn}) => {
    const {isOpen, onOpen, onClose: baseOnClose} = useDisclosure();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [secretPass, setSecretPass] = useState("");

    const toast = useToast();

    const userData = JSON.parse(localStorage.getItem('user_data'));
    const isLoggedIn = !!userData;

    const createAccount = async () => {
        if (!username || !email || !password) {
            setErrorMessage("Please fill in all fields.");
            return;
        }
        if (!validator.isEmail(email)) {
            setErrorMessage("Please enter a valid email.");
            return;
        }

        try {
            const emailValidityResponse = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/auth/check_email_validity`, 
                { email: email }
            );
    
            if (!emailValidityResponse.data.isValid) {
                setErrorMessage("The provided email is not valid.");
                return;
            }
        } catch (error) {
            console.error("Error during email validation:", error);
            setErrorMessage("Error validating the email, please try again.");
            return;
        }

        let org_name = secretPass

        // Check the secret pass with the new endpoint
        if (secretPass !== "") {
            try {
                const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/auth/check_secret_pass`, { secret_pass: secretPass });
                if (!response.data.status) {
                    setErrorMessage(response.data.msg);  // This will be "Invalid secret pass." if the pass is wrong
                    return;
                }
                org_name = response.data.org_name
            } catch (error) {
                setErrorMessage("Error checking the secret pass, please try again.");
                return;
            }
        } else {
            // free user
            org_name = 'free-user'
        }


        axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/auth/register`, {
            username: username,
            password: password,
            first_name: fName,
            last_name: lName,
            email: email,
            org_id: org_name
        })
            .then(response => {
                if (response.data) {
                    // If login was successful, close the modal and clear the form
                    onClose()
                    setErrorMessage(null);

                    // Save all the data returned from the server
                    localStorage.setItem('user_data', JSON.stringify(response.data));

                    // If you want to access individual properties, you can do so like this
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('token_type', response.data.token_type);

                    toast({
                        title: "Account creation successful",
                        description: "You have successfully created your account.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    setIsLoggedIn(true)
                    sessionStorage.setItem('state', 'logged-in');
                } else {
                    setErrorMessage("Something went wrong, please try again.");
                }
            })
            .catch(error => {
                // Check if the error response has a status of 400 and contains our error messages
                if (error.response && error.response.status === 400) {
                  if (error.response.data.detail === "Username already exists") {
                      setErrorMessage("The provided username is already taken.");
                  } else if (error.response.data.detail === "Email already exists") {
                      setErrorMessage("An account with this email already exists.");
                  } else {
                      setErrorMessage("Something went wrong, please try again.");
                  }
                } else {
                    setErrorMessage("Something went wrong, please try again.");
                }
            });

        setErrorMessage(null);
    }

    const onClose = () => {
        // Clear fields and error message when modal is closed
        setUsername("");
        setEmail("");
        setPassword("");
        setFName("");
        setLName("");
        setSecretPass("");
        setErrorMessage(null);
        baseOnClose();
    };

    const inputBorderColorDefault = "#d4d4d4"
    const inputBorderColorFocus = "#e17348"
    const inputBGColor = "#fafafa"
    const inputBorderRadius = 0

    return (
        <>
            <Button
                onClick={onOpen}
                as={'a'}
                size='md' // Made the button smaller
                borderRadius='md' // Less rounded corners
                display={{base: 'none', md: 'inline-flex'}}
                fontSize={'sm'}
                fontWeight={600}
                color={'white'}
                bg={'#ED7547'}
                href={'#'}
                _hover={{
                    bg: '#D05D32',
                }}
            >
                Create Account
            </Button>


            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay/>
                <ModalContent borderRadius="0">
                    <ModalCloseButton/>
                    <ModalBody>
                        <Flex direction="row" minH="100%">
                            <Box w="45%" pr={3} position="relative" minH="100%">
                                <Flex direction="column" align="left" justify="center" height={"80%"} ml={1}>
                                    <Image 
                                        src="https://i.ibb.co/1vXNFcc/symbol-text-dark.png"
                                        alt="Chatter"
                                        width="250px"
                                        height="auto"
                                        mb={10}
                                    />
                                    <Text fontSize="lg" fontWeight={600} mb={3}>Why sign up?</Text>
                                    <UnorderedList fontSize="md" spacing={3}>
                                        <ListItem>Organize your LLM development in Chatter workspaces.</ListItem>
                                        <ListItem>Quickly prototype, test, and iterate.</ListItem>
                                        <ListItem>It's free!</ListItem>
                                    </UnorderedList>
                                </Flex>
                                <Box position="absolute" right="0" top="-2" bottom="-2" width="10px" 
                                    bgGradient="linear(to-r, rgba(255,255,255,1), rgba(234,234,234,1))" mixH="100%">
                                </Box>
                            </Box>
                            <Box w="55%" pl={5} position="relative" zIndex="1">
                                <ModalHeader mb={1} fontSize="lg" ml={-6} fontWeight={700}>Create Chatter Account</ModalHeader>
                                <VStack align="left" spacing={4}>
                                    <FormControl>
                                        <FormLabel fontWeight="bold" fontSize="sm">First Name</FormLabel>
                                        <Input placeholder="Your first name" value={fName}
                                            onChange={(e) => setFName(e.target.value)}
                                            borderRadius={inputBorderRadius}
                                            borderColor={inputBorderColorDefault}
                                            focusBorderColor={inputBorderColorFocus}
                                            bg={inputBGColor}
                                            _focus={{
                                                boxShadow: "none",  
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel fontWeight="bold" fontSize="sm">Last Name</FormLabel>
                                        <Input placeholder="Your last name" value={lName}
                                            onChange={(e) => setLName(e.target.value)}
                                            borderRadius={inputBorderRadius}
                                            borderColor={inputBorderColorDefault}
                                            focusBorderColor={inputBorderColorFocus}
                                            bg={inputBGColor}
                                            _focus={{
                                                boxShadow: "none",  
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel fontWeight="bold" fontSize="sm">Username</FormLabel>
                                        <Input placeholder="Your username" value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            borderRadius={inputBorderRadius}
                                            borderColor={inputBorderColorDefault}
                                            focusBorderColor={inputBorderColorFocus}
                                            bg={inputBGColor}
                                            _focus={{
                                                boxShadow: "none",  
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel fontWeight="bold" fontSize="sm">Email</FormLabel>
                                        <Input type="email" placeholder="Your email" value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            borderRadius={inputBorderRadius}
                                            borderColor={inputBorderColorDefault}
                                            focusBorderColor={inputBorderColorFocus}
                                            bg={inputBGColor}
                                            _focus={{
                                                boxShadow: "none",  
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel fontWeight="bold" fontSize="sm">Password</FormLabel>
                                        <Input placeholder="Your password" type="password" value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            borderRadius={inputBorderRadius}
                                            borderColor={inputBorderColorDefault}
                                            focusBorderColor={inputBorderColorFocus}
                                            bg={inputBGColor}
                                            _focus={{
                                                boxShadow: "none",  
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel fontWeight="bold" fontSize="sm">Secret Word (optional)</FormLabel>
                                        <Input placeholder="Your org secret" type="password" value={secretPass}
                                            onChange={(e) => setSecretPass(e.target.value)}
                                            borderRadius={inputBorderRadius}
                                            borderColor={inputBorderColorDefault}
                                            focusBorderColor={inputBorderColorFocus}
                                            bg={inputBGColor}
                                            _focus={{
                                                boxShadow: "none",  
                                            }}
                                        />
                                    </FormControl>

                                    {errorMessage && <Text color="red.500">{errorMessage}</Text>}


                                    <Button
                                        onClick={createAccount}
                                        as={"a"}
                                        size="md"
                                        borderRadius="md"
                                        fontSize={"sm"}
                                        fontWeight={600}
                                        color={"white"}
                                        bg={"#ED7547"}
                                        href={"#"}
                                        _hover={{
                                            bg: "#D05D32",
                                        }}
                                        mt={2}
                                        mb={5}
                                    >
                                        Create Account
                                    </Button>
                                </VStack>
                            </Box>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateAccountModal;