import {useEffect, useState} from 'react';
import axios from 'axios';
import Test from './Test'
import {
    Button,
    useToast,
    Box,
    Text,
    HStack,
    NumberDecrementStepper, NumberInputStepper, ButtonGroup, IconButton, Spinner
} from '@chakra-ui/react';
import {PlusIcon, DollarIcon, PlayIcon, ComparisonIcon, ChatIcon, GraphIcon, ImportIcon, CloudDownloadIcon} from 'evergreen-ui';
import {AddIcon, TimeIcon, TriangleDownIcon, DownloadIcon} from '@chakra-ui/icons';
import StatsPanel from './StatsPanel'
import ImportTestsModal from './ImportTestsModal';
import VariableConfig from "./VariableConfig";
import {useUtilityFunctions} from "../UtilityFunctions";
import ImportChatCSVModal from "../ChatImport/ImportChatCSVModal";
import LastSuiteDiffViewer from "../RunHistory/LastSuiteDiffViewer"
import TestCaseGenerationModal from './TestCaseGeneration/TestCaseGenerationModal';
import ResultBreakdown from "./ResultBreakdown";
import StreamRunSuiteButton from "./StreamRunSuiteButton";

const TestingPanel = ({
                          workspaceDataID,
                          workspaceData,
                          currRunState,
                          setCurrRunState,
                          chainName,
                          chainID,
                          chainData,
                          cells,
                          suiteData,
                          setSuiteData,
                          runChainOnSuite,
                          chainIsRunning,
                          setChainIsRunning,
                          isCSVModalOpen,
                          openCSVModal,
                          closeCSVModal,
                          addCellBulk,
                          handleIconClick
                      }) => {
    const {getAccessToken, extractNumberFromVarName} = useUtilityFunctions();

    const toast = useToast();

    const [isImportModalOpen, setIsImportModalOpen] = useState(false);

    const [chainDiffHistories, setChainDiffHistories] = useState([])

    const [isDiffViewerOpen, setIsDiffViewerOpen] = useState(false)

    const [isGenerationModalOpen, setIsGenerationModalOpen] = useState(false)

    const [isAddRedTeamLoading, setIsAddRedTeamLoading] = useState(false)
    
    useEffect(() => {
        if (suiteData && suiteData.state && suiteData.state.chain_stats && suiteData.state.test_results) {
            setCurrRunState({chain_stats: suiteData.state.chain_stats, test_results: suiteData.state.test_results});
        }
        // if (chainDiffHistories.length === 0) {
        //     console.log("GOT HERE")
        //     fetchChainHistories()
        // }
    }, []);

    const fetchChainHistories = async () => {
        try {

            // get the two most recent histories
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/history/chain/${chainID}`,
                {
                    page: 1,
                    per_page: 2,
                }, {headers: {'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`}}
            );

            setChainDiffHistories(response.data)
            console.log("DATA IS ", response.data)

        } catch (error) {
            console.error('Failed to fetch cell histories:', error);
        }
    };

    const openImportModal = () => {
        setIsImportModalOpen(true);
    };

    const closeImportModal = () => {
        setIsImportModalOpen(false);
    };


    const handleVarMappingChange = (testId, varName, value) => {
        // Use the setSuiteData callback to update the suiteData state.
        setSuiteData(prevSuiteData => {
            const updatedSuiteData = {...prevSuiteData}; // Make a shallow copy of the suiteData.
            // Find the test whose id matches testId.
            const test = updatedSuiteData.tests.find(test => test.id === testId);
            if (test) {
                // Update the var_mapping for the test.
                test.var_mapping[varName] = value;


                // Make a PUT request to update the var_mapping in the DB.
                axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${updatedSuiteData._id}/tests/${testId}/var_mapping/replace`,
                    {
                        var_mapping: test.var_mapping
                    }, {
                        headers: {
                            'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                        },
                    }).then(response => {
                }).catch(error => {
                    console.error("Error updating the var_mapping", error);
                });
            }
            return updatedSuiteData;
        });
    };


    const addTest = async (preloaded_var_mapping = {}, preloaded_chat_logic = {}) => {
        try {
            let new_var_mapping = preloaded_var_mapping
            if (new_var_mapping && typeof new_var_mapping === 'object' && 'nativeEvent' in new_var_mapping && 'currentTarget' in new_var_mapping) {
                new_var_mapping = {}
            }
            const test = {
                var_mapping: new_var_mapping,
                assertions: []
            };

            if (Object.keys(preloaded_chat_logic).length !== 0) {
                test.chat_logic = preloaded_chat_logic;
            }


            const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${suiteData._id}/tests`, test, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
            });


            if (response.data.updated_suite) {
                // Update the state with the new test
                setSuiteData(response.data.updated_suite);
            }
        } catch (error) {
            console.error('Failed to add test:', error);
        }
    };


    const deleteTest = async (testID) => {
        try {
            // Perform a DELETE HTTP request to the appropriate endpoint
            const response = await axios.delete(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${suiteData._id}/tests/${testID}`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
            });

            if (response.data) {
                // Filter out the deleted test and update the state
                const updatedTests = suiteData.tests.filter(test => test.id !== testID);
                setSuiteData(prevSuiteData => ({...prevSuiteData, tests: updatedTests}));
            }
        } catch (error) {
            console.error('Failed to delete test:', error);
        }
    };

    const handleCSVUpload = async (data) => {
        // assuming data is organized as list of lists of objects

        let var_mapping = {};
        let chat_logic = {
            tested_cells: [],
            message_properties: []
        };

        const newCells = [];
        const oldCells = cells

        if (data.length > oldCells.length) {
            const numberOfNewCells = data.length - oldCells.length;

            // Using the updated addCellBulk function
            const newAddedCells = await addCellBulk(numberOfNewCells, [], oldCells.length);

            if (newAddedCells && newAddedCells.length > 0) {
                newCells.push(...newAddedCells);
            }
        }

        const allCells = [...oldCells, ...newCells];

        await new Promise(resolve => setTimeout(resolve, 5000));

        for (const [index, ent] of data.entries()) {
            var_mapping[`Message_${index + 1}_Input`] = ent.content;


            if (index < allCells.length) {
                chat_logic.message_properties.push({
                    cell_id: allCells[index]._id,
                    role: ent.role
                });
            }
        }

        // issue is this needs to wait for update prompt but thats onMount in a diff component, very tricky
        // TODO: Fix
        await addTest(var_mapping, chat_logic);
    };

     // Handler for the onClick event of the export tests button
     const handleDownloadClick = (tests, cells, unSortedTestKeys, isChat) => {
        const sortedTestKeys = unSortedTestKeys.sort((a, b) => extractNumberFromVarName(a) - extractNumberFromVarName(b));
        let csvData = ""
        if (isChat) {
            csvData = generateExportCSVforChat(tests, cells, sortedTestKeys);
        } else {
            csvData = generateExportCSVforNonChat(tests, sortedTestKeys);
        }
        downloadCSV(csvData, 'exported_tests.csv');
    };

    const downloadCSV = (data, filename = 'data.csv') => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

    
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.style.visibility = 'hidden';
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    

    const generateExportCSVforChat = (tests, cells, sortedTestKeys) => {
        const csvRows = [];
        const header = ['Test Name', 'Variable', 'Value', 'Role (for chats)', 'Assertion Type', 'Assertion String', 'Assertion Number', 'Assertion IDs List (Boolean)'];
        csvRows.push(header.join(','));
    
        tests.forEach(test => {
            cells.forEach((cell, cellIndex) => {
                if (cellIndex >= sortedTestKeys.length) {
                    return; // Condition 2
                }
    
                const key = sortedTestKeys[cellIndex];
                const value = test.var_mapping[key];
                if (!value || value.trim().length === 0 || value === "") {
                    return; // Condition 3
                }
    
                let role = "";
                if (test.chat_logic && test.chat_logic.message_properties) {
                    const messageProperty = test.chat_logic.message_properties.find(mp => mp.cell_id === cell._id);
                    role = messageProperty ? messageProperty.role : "";
                }

                let assertions = [];
                if (!test.chat_logic || test.chat_logic.tested_cells === null || (test.chat_logic.tested_cells && test.chat_logic.tested_cells.some(tc => tc.cell_id === cell._id))) {
                    assertions = test.assertions[cell._id] || [];
                }

                const row = [test.name || "[Untitled Test]", key, value, role].map(field => `"${field.toString().replace(/"/g, '""')}"`);

                assertions.forEach(assertion => {
                    row.push(`"${assertion.assert_type}"`, `"${assertion.assert_string.replace(/"/g, '""')}"`, `"${assertion.assert_num}"`);

                    // Handle assertion.assert_list
                    if (assertion.assert_list && Array.isArray(assertion.assert_list) && assertion.assert_list.length > 0) {
                        const assertListString = assertion.assert_list.join('; ');
                        row.push(`"${assertListString}"`);
                    } else {
                        row.push('""'); // Empty column if assert_list does not exist or is not an array
                    }
                });

                csvRows.push(row.join(','));
            });
        });
    
        return csvRows.join('\n');
    };

    const generateExportCSVforNonChat = (tests, sortedTestKeys) => {
        const csvRows = [];
    
        // Generate the header dynamically based on sortedTestKeys and maximum assertions
        const variableHeaders = sortedTestKeys.flatMap(key => [`Variable: ${key}`, `Value`]);
        const assertionHeaders = ['Assertion Cell ID', 'Assertion Type', 'Assertion String', 'Assertion Number'];
        const header = ['Test Name', ...variableHeaders, ...assertionHeaders];
        csvRows.push(header.map(h => `"${h}"`).join(','));
    
        tests.forEach(test => {
            const row = [test.name || "[Untitled Test]"];
    
            // Add variable-value pairs
            sortedTestKeys.forEach(key => {
                const value = test.var_mapping[key] || "";
                row.push(`"${key}"`, `"${value.toString().replace(/"/g, '""')}"`);
            });
    
            // Add assertions
            let assertionsCombined = [];
            Object.entries(test.assertions).forEach(([cellID, assertions]) => {
                assertions.forEach(assertion => {
                    // Insert cellID before each assertion's details
                    row.push(`"${cellID}"`, `"${assertion.assert_type.toString().replace(/"/g, '""')}"`, `"${assertion.assert_string.replace(/"/g, '""')}"`, `"${assertion.assert_num}"`);
                });
            });
    
            // Add assertions to the row, ensure escaping of double quotes
            assertionsCombined.forEach(assertion => {
                row.push(`"${assertion.toString().replace(/"/g, '""')}"`);
            });
    
            csvRows.push(row.join(','));
        });
    
        return csvRows.join('\n');
    };
    

    const addRedTeam = async (quantity, type) => {
        let toastId = null;
        setIsAddRedTeamLoading(true)

        try {
            toastId = toast({
                title: "Test case generation loading",
                description: "Please wait while the generated tests are being loaded.",
                status: "info",
                position: "bottom-right",
                duration: null, // Keeps the toast open indefinitely
                isClosable: true,
                render: () => (
                    <Box color="white" p={3} bg="blue.600" borderRadius={6}>
                        <Spinner
                            color="white"
                            size={"sm"}
                            mr={4}
                        />
                        Generating tests ...
                    </Box>
                ),
            });

            const rdt = {
                quantity: quantity,
                vault_id: workspaceData.aux.vault_id,
                type: type
            };

            const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/${suiteData._id}/red_team`, rdt, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
            });


            if (response.data) {
                // Update the state with the new tests
                setSuiteData(prevSuiteData => ({
                    ...prevSuiteData,
                    tests: [...prevSuiteData.tests, ...response.data.tests]
                }));
            }
            setIsAddRedTeamLoading(false)
            setIsGenerationModalOpen(false)
        } catch (error) {
            console.error('Failed to add test:', error);
            setIsAddRedTeamLoading(false)
            setIsGenerationModalOpen(false)
        } finally {
            if (toastId) {
                // Close the toast once the response is received
                toast.close(toastId);
            }
            setIsAddRedTeamLoading(false)
            setIsGenerationModalOpen(false)
        }
        setIsAddRedTeamLoading(false)
        setIsGenerationModalOpen(false)
    };





    return (
        <Box>
            <ButtonGroup size={'sm'} mb={3}>
                {/*<Button*/}
                {/*    leftIcon={<PlayIcon boxSize="3" color="success"/>}*/}
                {/*    variant="solid"*/}
                {/*    justifyContent="center"*/}
                {/*    fontSize="sm"*/}
                {/*    fontWeight="normal"*/}
                {/*    onClick={runChainOnSuite}*/}
                {/*    isLoading={chainIsRunning}*/}
                {/*    size={'sm'}*/}
                {/*>*/}
                {/*    Run Suite*/}
                {/*</Button>*/}
                <StreamRunSuiteButton
                    setSuiteData={setSuiteData}
                    suiteData={suiteData}
                    workspaceDataID={workspaceDataID}
                    setChainIsRunning={setChainIsRunning}
                    handleIconClick={handleIconClick}
                    chainID={chainID}
                    setCurrRunState={setCurrRunState}
                    chainIsRunning={chainIsRunning}
                    cells={cells}
                />
                <Button
                    leftIcon={<PlusIcon boxSize="3"/>}
                    justifyContent="center"
                    fontSize="sm"
                    fontWeight="normal"
                    onClick={addTest}
                >
                    Add Test
                </Button>

                {/* {!(chainData && chainData.config && chainData.config.type && chainData.config.type === "chat") &&<Button
                    leftIcon={<GraphIcon boxSize="3"/>}
                    justifyContent="center"
                    fontSize="sm"
                    fontWeight="normal"
                    onClick={() => setIsGenerationModalOpen(true)}
                >
                    Generate tests
                </Button>} */}


                {isGenerationModalOpen && (
                    <TestCaseGenerationModal isGenerationModalOpen={isGenerationModalOpen}
                                             setIsGenerationModalOpen={setIsGenerationModalOpen} addRedTeam={addRedTeam}
                                             isAddRedTeamLoading={isAddRedTeamLoading}/>
                )}

                {/* <Menu>
                        <MenuButton as={IconButton} size={'sm'} icon={<TriangleDownIcon/>}>
                        </MenuButton>
                        <MenuList>
                            <MenuItem>
                                <NumberInput value={testAddQty} onChange={(value) => setTestAddQty(value)} min={1}
                                             onClick={(e) => e.stopPropagation()}>
                                    <NumberInputField/>
                                    <NumberInputStepper>
                                        <NumberIncrementStepper/>
                                        <NumberDecrementStepper/>
                                    </NumberInputStepper>
                                </NumberInput>
                            </MenuItem>
                            <MenuItem onClick={addRedTeam}>Red Team</MenuItem>
                        </MenuList>
                    </Menu> */}
                {!(chainData && chainData.config && chainData.config.type && chainData.config.type === "chat") &&
                    <Button
                        leftIcon={<ImportIcon boxSize="3"/>}
                        variant="solid"
                        justifyContent="center"
                        fontSize="sm"
                        fontWeight="normal"
                        onClick={openImportModal}
                        size={'sm'}
                    >
                        Import Test Set
                    </Button>}
                {chainData && chainData.config && chainData.config.type && chainData.config.type === "chat" && <Button
                    leftIcon={<ChatIcon boxSize="3"/>}
                    variant="solid"
                    justifyContent="center"
                    fontSize="sm"
                    fontWeight="normal"
                    onClick={openCSVModal}
                    size={'sm'}
                >
                    Import Chat as Test
                </Button>}
                {suiteData.tests && cells && <Button
                    leftIcon={<CloudDownloadIcon boxSize="3"/>}
                    variant="solid"
                    justifyContent="center"
                    fontSize="sm"
                    fontWeight="normal"
                    onClick={() => handleDownloadClick(suiteData.tests, cells, suiteData.variables ? Object.keys(suiteData.variables) : [], (chainData && chainData.config && chainData.config.type && chainData.config.type === "chat"))}
                    size={'sm'}
                >
                    Download as CSV
                </Button>}
                {/* {console.log("CHAIN HISTORIES IS ", chainDiffHistories)} */}
                {/* {chainDiffHistories.length > 1 && <Button
                    leftIcon={<ComparisonIcon boxSize="3"/>}
                    variant="solid"
                    justifyContent="center"
                    fontSize="sm"
                    fontWeight="normal"
                    onClick={() => setIsDiffViewerOpen(true)}
                    size={'sm'}
                >
                    Diff
                </Button>} */}
            </ButtonGroup>

            <ImportTestsModal isOpen={isImportModalOpen} onClose={closeImportModal} suiteData={suiteData}
                              setSuiteData={setSuiteData}
                              workspaceDataID={workspaceDataID}/>
            <ImportChatCSVModal isOpen={isCSVModalOpen} onClose={closeCSVModal} onUpload={handleCSVUpload}/>
            <LastSuiteDiffViewer
                isChat={chainData && chainData.config && chainData.config.type && chainData.config.type === "chat"}
                recentHistories={chainDiffHistories} isDiffViewerOpen={isDiffViewerOpen}
                setIsDiffViewerOpen={setIsDiffViewerOpen}/>

            {(currRunState && currRunState.chain_stats && currRunState.chain_stats.stats && currRunState.chain_stats.timestamp) && (
                <>
                    <StatsPanel runTime={currRunState.chain_stats.stats.time}
                                promptTokens={currRunState.chain_stats.stats.prompt_tokens}
                                completionTokens={currRunState.chain_stats.stats.completion_tokens}
                                passPercent={currRunState.chain_stats.stats.percent_success}
                                dollarCost={currRunState.chain_stats.stats.cost} isCollapsed={false} currRunState={currRunState}/>
                    <HStack align="center" mb={4} justifyContent="space-between">
                        <Text color="gray.500" fontSize="sm">From run
                        at: {new Date(currRunState.chain_stats.timestamp * 1000).toLocaleString()}</Text>
                        {!(chainData && chainData.config && chainData.config.type && chainData.config.type === "chat") && <ResultBreakdown currRunState={currRunState}/>}
                    </HStack>
                </>
            )}

            {suiteData && suiteData.variables && (
                <VariableConfig suiteData={suiteData} setSuiteData={setSuiteData} workspaceDataID={workspaceDataID}/>
            )}
            {suiteData && suiteData.tests && suiteData.tests.map((test, index) => {
                const isLastTest = index === suiteData.tests.length - 1;

                return <Test key={test.id} chainID={chainID} testId={test.id} cells={cells} suiteData={suiteData}
                             setSuiteData={setSuiteData} onVarMappingChange={handleVarMappingChange}
                             chainIsRunning={chainIsRunning}
                             currRunState={currRunState} setCurrRunState={setCurrRunState}
                             workspaceDataID={workspaceDataID} workspaceData={workspaceData} deleteTest={deleteTest}
                             chainData={chainData} isLast={isLastTest} testIndex={index}/>;
            })}
        </Box>
    );
}

export default TestingPanel;
