import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Select, Box, ModalCloseButton } from "@chakra-ui/react";
import ChainHistoryDetails from "./ChainHistoryDetails";
import {useState} from 'react';
import CellHistoryDetails from "./CellHistoryDetails";


const ABComparisonModal = ({ histories, isOpen, onClose, formatTimestamp, isCell, isChat }) => {
    const [leftHistory, setLeftHistory] = useState(histories[0]);
    const [rightHistory, setRightHistory] = useState(histories[1]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>A/B Comparison</ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" justifyContent="space-between">
                    <Box width="45%">
                        <Select onChange={e => {
                                const selectedTimestamp = Number(e.target.value);
                                setLeftHistory(histories.find(h => h.timestamp === selectedTimestamp));
                            }} defaultValue={leftHistory.timestamp}>
                            {histories.map(history => (
                                <option key={history.timestamp} value={history.timestamp}>{formatTimestamp(history.timestamp)}</option>
                            ))}
                        </Select>
                        {isCell ? (<CellHistoryDetails history={leftHistory} />) : (<ChainHistoryDetails history={leftHistory} isChat={isChat} />)}
                    </Box>
                    <Box width="45%">
                        <Select onChange={e => {
                                const selectedTimestamp = Number(e.target.value);
                                setRightHistory(histories.find(h => h.timestamp === selectedTimestamp));
                            }}  defaultValue={rightHistory.timestamp}>
                            {histories.map(history => (
                                <option key={history.timestamp} value={history.timestamp}>{formatTimestamp(history.timestamp)}</option>
                            ))}
                        </Select>
                        {isCell ? (<CellHistoryDetails history={rightHistory} />) : (<ChainHistoryDetails history={rightHistory} isChat={isChat} />)}
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ABComparisonModal;