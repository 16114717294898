import {
    Box,
    Accordion, Button, useToast, ButtonGroup, Flex, Spinner, flattenTokens
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import axios from 'axios';
import CellHistories from "./CellHistory";
import ChainHistories from "./ChainHistory";
import {useUtilityFunctions} from "../UtilityFunctions";


const RunHistoryPanel = ({chainName, chainID, workspaceDataID, chainData}) => {
    const [cellHistories, setCellHistories] = useState({});
    const [chainHistories, setChainHistories] = useState({});
    const [chainSuiteHistories, setChainSuiteHistories] = useState({});
    const [currentPageCells, setCurrentPageCells] = useState(1);
    const [currentPageChain, setCurrentPageChain] = useState(1);
    const [currentPageChainSuite, setCurrentPageChainSuite] = useState(1);
    const [activeView, setActiveView] = useState('chain'); // cell or chain or chainSuite
    const [currentCellPageData, setCurrentCellPageData] = useState([]);
    const [currentChainPageData, setCurrentChainPageData] = useState([]);
    const [currentChainSuitePageData, setCurrentChainSuitePageData] = useState([]);
    const toast = useToast();
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();
    const [isFetchLoading, setIsFetchLoading] = useState(false)

    const fetchCellHistories = async () => {
        try {
            setIsFetchLoading(true)
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/history/cells/${chainID}`,
                {
                    page: currentPageCells,
                    per_page: 25,
                }, { headers: { 'Authorization': `Bearer ${getAccessToken(workspaceDataID)}` } }
            );
       

            if (response.data.length === 0) {
                // toast({
                //     title: "No more results.",
                //     description: "You have reached the end of the results.",
                //     status: "info",
                //     duration: 4000,
                //     isClosable: true,
                // });
            } else {
                setCellHistories(prevCellHistories => ({
                    ...prevCellHistories,
                    [currentPageCells]: response.data
                }));
                setCurrentCellPageData(response.data);
            }
            setIsFetchLoading(false)
        } catch (error) {
            setIsFetchLoading(false)
            console.error('Failed to fetch cell histories:', error);
        }
    };




    const fetchChainHistories = async () => {
        setIsFetchLoading(true)
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/history/chain/${chainID}`,
                {
                    page: currentPageChain,
                    per_page: 20,
                }, { headers: { 'Authorization': `Bearer ${getAccessToken(workspaceDataID)}` } }
            );

            if (response.data.length === 0) {
                // toast({
                //     title: "No more results.",
                //     description: "You have reached the end of the results.",
                //     status: "info",
                //     duration: 4000,
                //     isClosable: true,
                // });
            } else {
                setChainHistories(prevChainHistories => ({
                    ...prevChainHistories,
                    [currentPageChain]: response.data
                }));
                setCurrentChainPageData(response.data);
                setIsFetchLoading(false)
            }
        } catch (error) {
            console.error('Failed to fetch chain histories:', error);
            setIsFetchLoading(false)
        }
    };
    
    const fetchChainSuiteHistories = async () => {
        setIsFetchLoading(true)
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/history/chain/${chainID}/suite`,
                {
                    page: currentPageChainSuite,
                    per_page: 20,
                }, { headers: { 'Authorization': `Bearer ${getAccessToken(workspaceDataID)}` } }
            );

            if (response.data.length === 0) {
                // toast({
                //     title: "No more results.",
                //     description: "You have reached the end of the results.",
                //     status: "info",
                //     duration: 4000,
                //     isClosable: true,
                // });
            } else {
                setChainSuiteHistories(prevChainSuiteHistories => ({
                    ...prevChainSuiteHistories,
                    [currentPageChainSuite]: response.data
                }));
                setCurrentChainSuitePageData(response.data);
                setIsFetchLoading(false)
            }
        } catch (error) {
            console.error('Failed to fetch chain histories:', error);
            setIsFetchLoading(false)
        }
    };

    const downloadCSV = (csv, filename) => {
        let csvFile;
        let downloadLink;
      
        // CSV file
        csvFile = new Blob([csv], {type: "text/csv"});
      
        // Download link
        downloadLink = document.createElement("a");
      
        // File name
        downloadLink.download = filename;
      
        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);
      
        // Hide download link
        downloadLink.style.display = "none";
      
        // Add the link to DOM
        document.body.appendChild(downloadLink);
      
        // Click download link
        downloadLink.click();
      };

    useEffect(() => {
        console.log("CHAIN HISTORY", chainHistories)
    }, [chainHistories]);

    useEffect(() => {
        if (currentCellPageData.length === 0) {
            const updated = currentPageCells - 1;
            setCurrentPageCells(updated);
        }
    }, [currentCellPageData]);

    useEffect(() => {
        if (currentChainPageData.length === 0) {
            const updated = currentPageChain - 1;
            setCurrentPageChain(updated);
        }
    }, [currentChainPageData]);
    
    useEffect(() => {
        if (currentChainPageData.length === 0) {
            const updated = currentPageChainSuite - 1;
            setCurrentPageChainSuite(updated);
        }
    }, [currentChainSuitePageData]);

    useEffect(() => {
        if (cellHistories[currentPageCells]) {
            setCurrentCellPageData(cellHistories[currentPageCells]);
        } else {
            fetchCellHistories();
        }
    }, [chainID, currentPageCells]);


    useEffect(() => {
        if (chainHistories[currentPageChain]) {
            setCurrentChainPageData(chainHistories[currentPageChain]);
        } else {
            fetchChainHistories();
        }
    }, [chainID, currentPageChain]);
    
    useEffect(() => {
        if (chainSuiteHistories[currentPageChainSuite]) {
            setCurrentChainSuitePageData(chainSuiteHistories[currentPageChainSuite]);
        } else {
            fetchChainSuiteHistories();
        }
    }, [chainID, currentPageChainSuite]);


    return (
        <Box>
            <ButtonGroup spacing={2} pb={4}>
                <Button
                    size={'sm'}
                    colorScheme={activeView === 'chainSuite' ? 'blue' : null}
                    onClick={() => setActiveView('chainSuite')}
                >
                    Chain Suite Histories
                </Button>
                <Button
                    size={'sm'}
                    colorScheme={activeView === 'chain' ? 'blue' : null}
                    onClick={() => setActiveView('chain')}
                >
                    Chain Histories
                </Button>
                <Button
                    size={'sm'}
                    colorScheme={activeView === 'cell' ? 'blue' : null}
                    onClick={() => setActiveView('cell')}
                >
                    Cell Histories
                </Button>
            </ButtonGroup>
            {activeView === 'cell' ? 
                <CellHistories cellHistories={currentCellPageData} chainID={chainID} workspaceDataID={workspaceDataID}/> 
                : (activeView === 'chain' ? 
                    <ChainHistories chainHistories={currentChainPageData} chainID={chainID} workspaceDataID={workspaceDataID} isChat={chainData && chainData.config && chainData.config.type && chainData.config.type === "chat"} isSuite={false} />
                    // reuse ChainHistories for chain suites 
                    : <ChainHistories chainHistories={currentChainSuitePageData} chainID={chainID} workspaceDataID={workspaceDataID} isChat={chainData && chainData.config && chainData.config.type && chainData.config.type === "chat"} isSuite={true} />)
                }
            {isFetchLoading && <Spinner/>}
            {/* Add pagination controls here */}
            <Flex pt={4} justifyContent="space-between" width="100%">
                <Button size={'sm'} onClick={() => {
                    let newPage = activeView === 'cell' ? currentPageCells - 1 : activeView === 'chain' ? currentPageChain - 1 : currentPageChainSuite - 1;
                    if (newPage < 1) return;
                    if (activeView === 'cell') {
                        setCurrentPageCells(newPage);
                    } else if (activeView === 'chain') {
                        setCurrentPageChain(newPage);
                    } else {
                        setCurrentPageChainSuite(newPage);
                    }
                }}>Previous</Button>
                <Button size={'sm'} onClick={() => {
                    let newPage = activeView === 'cell' ? currentPageCells + 1 : activeView === 'chain' ? currentPageChain + 1 : currentPageChainSuite + 1;
                    if (activeView === 'cell') {
                        setCurrentPageCells(newPage);
                    } else if (activeView === 'chain') {
                        setCurrentPageChain(newPage);
                    } else {
                        setCurrentPageChainSuite(newPage)
                    }
                }}>Next</Button>
            </Flex>
        </Box>
    );

};

export default RunHistoryPanel;


