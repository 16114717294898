import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button, Select,
} from "@chakra-ui/react";
import axios from "axios";
import {useToast} from "@chakra-ui/react";
import {useState, useEffect} from "react";
import {useUtilityFunctions} from "../UtilityFunctions";

const UserSettingsModal = ({isOpen, onClose, userData, setUserData}) => {
    const roleMapping = {
        'Developer': 'developer',
        'Test Suite': 'nontech'
    };

    const reverseRoleMapping = {
        'developer': 'Developer',
        'nontech': 'Test Suite'
    };

    const [role, setRole] = useState(reverseRoleMapping[userData.status?.role] || 'Developer');
    const toast = useToast();
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    const handleSetRole = async (role) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/user/role`,
                {role: role},
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken()}`
                    }
                }
            );

            setUserData(prevUserData => {
                const updatedUserData = {
                    ...prevUserData,
                    status: {
                        ...prevUserData.status,
                        role: role
                    }
                };
    
                // Save updated user data to localStorage after state is updated
                localStorage.setItem('user_data', JSON.stringify(updatedUserData));
    
                return updatedUserData;
            });
        } catch (error) {
            // Handle error
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Settings</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Select
                        value={role}
                        onChange={(e) => {
                            const newRole = e.target.value;
                            setRole(newRole);
                            handleSetRole(roleMapping[newRole]);
                        }}
                    >
                        <option value="Developer">Developer</option>
                        <option value="Test Suite">Test Suite</option>
                    </Select>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UserSettingsModal;
