import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    HStack,
    VStack,
    Select,
    Textarea,
    Button,
    Icon,
    Text,
    Input,
    useToast
} from "@chakra-ui/react";
import {LabTestIcon, TrashIcon} from "evergreen-ui";
import {useState} from 'react';
import MonacoEditor from 'react-monaco-editor';

import {PlayIcon, CodeIcon} from 'evergreen-ui';
import {useEffect} from "react";
import NameEdit from "./NameEdit";
import PromptEditor from "./PromptEditor";
import axios from "axios";
import ModelMenu from "./ModelMenu";
import ParamSelector from "./ParamSelector";
import DeleteCellButton from "./DeleteButton";
import ResponseFormatEditor from './ResponseFormatEditor'
import {useUtilityFunctions} from "../UtilityFunctions";
import CellSDKModal from "../SDK-Components/CellSDKModal";

const ConvertedPlayIcon = () => <Icon as={PlayIcon}/>;
const ConvertedCodeIcon = () => <Icon as={CodeIcon}/>;

const Cell = ({
                  cell,
                  cellIndex,
                  workspaceDataID,
                  workspaceData,
                  handleIconClick,
                  currRunState,
                  setCurrRunState,
                  chainID,
                  updateCell,
                  suiteData,
                  setSuiteData,
                  modelData,
                  deleteCell,
                  allOpen,
                  mergedModelData,
                  chainData,
                  checkChainController,
                  updateChainController
              }) => {
    const [localCell, setLocalCell] = useState(cell);
    const [name, setName] = useState(cell.name);
    const [prompt, setPrompt] = useState(cell.config.prompt);
    const [modelFamily, setModelFamily] = useState(cell.config.model_family);
    const [model, setModel] = useState(cell.config.model);
    // const [params, setParams] = useState(cell.config.params);
    const [modelDetails, setModelDetails] = useState(null);
    const [paramDetails, setParamDetails] = useState(null);
    const [isExpanded, setIsExpanded] = useState(allOpen ? [0] : []);
    const cellID = cell._id;
    const [suiteRunning, setSuiteRunning] = useState(false)
    const toast = useToast();
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    useEffect(() => {
        updateCell(localCell);
    }, [localCell]);

    const handleRunSuite = async () => {
        try {
            setSuiteRunning(true)
            handleIconClick('TestingPanel')

            let updatedWorkspace = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/byID/${workspaceDataID}`,
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    },
                });
            if (!updatedWorkspace.data.aux.vault_id) {
                toast({
                    title: "No vault found",
                    description: "Please choose an API key vault before running",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                setSuiteRunning(false)
                return;
            }
            const updatedWorkspaceVault = updatedWorkspace.data.aux.vault_id


            const suiteResponse = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
                params: {
                    chain_id: chainID,
                },
            });


            const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cell._id}/run/suite`,
                {
                    vault_id: updatedWorkspaceVault,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    },
                });


            let newState = {...currRunState};


            suiteResponse.data.tests.forEach(async (test) => {

                const testResultInRunState = newState.test_results.find((result) => result.testID === test.id);
                const cellResultInResponse = response.data.tests.find((respTest) => respTest.name === test.name);

                if (!cellResultInResponse) {
                    return; // Skip this test if there's no corresponding result in the response.
                }

                const newCellResult = {
                    cell_id: response.data.cell_id,
                    timestamp: response.data.timestamp,
                    timestampIsStandout: true,
                    result: cellResultInResponse.result,
                    usage: cellResultInResponse.usage,
                    evals: cellResultInResponse.evals,
                    passed: cellResultInResponse.passed,
                };


                if (testResultInRunState && testResultInRunState.cell_results) {
                    const cellResultInRunState = testResultInRunState.cell_results.find((result) => result.cell_id === cell._id);
                    if (cellResultInRunState) {
                        // update existing cell_result with new data
                        Object.assign(cellResultInRunState, newCellResult);
                    } else {
                        // add new cell_result
                        testResultInRunState.cell_results.push(newCellResult);
                    }
                } else if (testResultInRunState) {
                    // add new cell_results array with newCellResult as its first element
                    testResultInRunState.cell_results = [newCellResult];
                } else {
                    const newTestResult = {
                        testID: test.id,
                        cell_results: [newCellResult],
                        stats: response.data.stats
                    }
                    newState.test_results.push(newTestResult)
                }
            });

            // update currRunState using the setCurrRunState method
            setCurrRunState(newState);
            let updatedSuiteData = {
                ...suiteData,
                state: newState
            };
            setSuiteData(updatedSuiteData)

            try {
                const runStatePutResponse = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/suites/${suiteData._id}/state`, newState, {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    },
                });


            } catch (error) {
                console.error('Failed to update the current run state:', error);
                toast({
                    title: "Couldn't save.",
                    description: "Couldn't save the run state, please check your network connection.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }

            setSuiteRunning(false)
        } catch (error) {
            setSuiteRunning(false);
            toast({
                title: "There was a problem.",
                description: "Please make sure your model is selected and your vault has correct keys for the selected models.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            console.error('Error running suite:', error);
        }
    };


    useEffect(() => {
        if (
            modelData &&
            mergedModelData &&
            modelFamily &&
            model &&
            mergedModelData.hasOwnProperty(modelFamily) &&
            mergedModelData[modelFamily].hasOwnProperty(model)
        ) {
            setModelDetails(mergedModelData[modelFamily][model]);
            setParamDetails(mergedModelData[modelFamily][model].params);
        }
    }, [modelFamily, model, modelData, mergedModelData]);

    useEffect(() => {
        setIsExpanded(allOpen ? [0] : []);
    }, [allOpen]);


    return (
        <Accordion allowToggle width={"100%"} index={isExpanded} onChange={index => setIsExpanded(index)}>
            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <VStack width="100%">
                            <HStack justifyContent="space-between" w="100%">
                                <AccordionIcon/>
                                <HStack flex="1" justifyContent="space-between" onClick={(e) => e.stopPropagation()}>
                                    <ModelMenu modelData={mergedModelData} setLocalCell={setLocalCell} cellID={cellID}
                                               modelFamily={modelFamily} setModelFamily={setModelFamily}
                                               model={model} setModel={setModel} setModelDetails={setModelDetails}
                                               workspaceDataID={workspaceDataID}/>
                                    {((chainData && chainData.config && chainData.config.type && chainData.config.type === "chat")) && <Text>Message #{cellIndex + 1}</Text>}
                                    <NameEdit name={name} setLocalCell={setLocalCell} setName={setName} id={cellID}
                                              workspaceDataID={workspaceDataID}/>
                                </HStack>
                            </HStack>
                        </VStack>
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <VStack align="stretch" spacing={4}>
                        <HStack width="full">
                            <Box align="left" w="100%">
                                <ParamSelector cellID={cellID} paramDetails={paramDetails} localCell={localCell}
                                               setLocalCell={setLocalCell} workspaceDataID={workspaceDataID}/>
                            </Box>
                        </HStack>
                        {(prompt !== undefined && cell && localCell) && (
                            <PromptEditor
                                modelDetails={modelDetails}
                                prompt={prompt}
                                setPrompt={setPrompt}
                                cell={cell}
                                cellIndex={cellIndex}
                                setLocalCell={setLocalCell}
                                suiteData={suiteData}
                                setSuiteData={setSuiteData}
                                workspaceDataID={workspaceDataID}
                                model={model}
                                modelFamily={modelFamily}
                                workspaceData={workspaceData}
                                chainID={chainID}
                                chainData={chainData}
                                checkChainController={checkChainController}
                                updateChainController={updateChainController}
                            />
                        )}
                        <ResponseFormatEditor cellID={cellID} prompt={prompt} setPrompt={setPrompt}
                                              paramDetails={paramDetails} cell={cell} localCell={localCell}
                                              setLocalCell={setLocalCell} suiteData={suiteData}
                                              workspaceDataID={workspaceDataID} workspaceData={workspaceData}
                                              setSuiteData={setSuiteData} chainID={chainID}/>
                        <HStack justifyContent="space-between">
                            <HStack>
                                <Button onClick={handleRunSuite} leftIcon={<ConvertedPlayIcon/>} colorScheme="green"
                                        size="sm" borderRadius={4} isLoading={suiteRunning}>Run on Suite</Button>
                                <CellSDKModal cell_id={cellID} vars={cell.config.variables}/>
                            </HStack>
                            {(!(chainData && chainData.config && chainData.config.type && chainData.config.type === "chat")) && <DeleteCellButton deleteCell={deleteCell} cellID={cellID}
                                              workspaceDataID={workspaceDataID}/>}
                        </HStack>
                    </VStack>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
}

export default Cell;

