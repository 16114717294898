import React, {useState, useEffect} from 'react';
import {
    EditIcon
} from 'evergreen-ui';
import {
    Textarea,
    Box,
    Button,
    ButtonGroup,
    useToast,
    VStack,
    Heading,
    Flex,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody, ModalFooter, Icon, Input, Text,
    Modal,
    ModalContent,
    useBreakpointValue,
    Image,
    Switch
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import Objgen from './Objgen';
import {Paragraph} from 'evergreen-ui';
import {CopyIcon, InfoIcon, AddIcon} from "@chakra-ui/icons";

import { TrashIcon } from 'evergreen-ui'; // Assuming Evergreen UI exports this icon with this name

function DialogFooter() {
    return null;
}

function isValidJSON(jsonString) {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (error) {
        return false;
    }
}

function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
}

function transformJson(inputJson) {
    const outputJson = {};

    Object.entries(inputJson).forEach(([key, value]) => {
        let propertyType = "string";
        let propertyName = key;
        let description = key;

        let parts = key.split(':');

        if (parts.length === 3) {
            [propertyName, propertyType, description] = parts;
        } else if (parts.length === 2) {
            [propertyName, propertyType] = parts;
            description = propertyName;
        } else {
            [propertyName] = parts;
        }

        if (isEmptyObject(value)) {
            outputJson[propertyName] = {
                type: propertyType,
                description: description
            };
        } else {
            let transformedChild = transformJson(value);

            if (propertyType === "array") {
                if (Object.keys(transformedChild).length === 1) {
                    // If only one child, simplify the structure
                    let singleKey = Object.keys(transformedChild)[0];
                    outputJson[propertyName] = {
                        type: propertyType,
                        description: description,
                        items: transformedChild[singleKey]
                    };
                } else {
                    // If multiple children, use object structure
                    outputJson[propertyName] = {
                        type: propertyType,
                        description: description,
                        items: {
                            type: 'object',
                            properties: transformedChild,
                            required: Object.keys(transformedChild)
                        }
                    };
                }
            } else {
                outputJson[propertyName] = {
                    type: "object",
                    properties: transformedChild,
                    required: Object.keys(transformedChild)
                };
            }
        }
    });

    return outputJson;
}



const FunctionCallEditorTool = ({onClose, isInternal, setEditorIsOpen, existingFunctions = []}) => {
    // Extract names and descriptions from the nested function object
    
    const [names, setNames] = useState(existingFunctions.map(func => func.function.name || ""));
    const [descriptions, setDescriptions] = useState(existingFunctions.map(func => func.function.description || ""));
    // Stringify the entire function object for the JSON versions
    const [JSONVersionTexts, setJSONVersionTexts] = useState(existingFunctions.map(func => JSON.stringify(func.function)));
    // Use plain_text for the text areas
    const [textAreas, setTextAreas] = useState(existingFunctions.map(func => func.plain_text || ""));

    const [functionIDs, setFunctionIDs] = useState(existingFunctions.map(func => func._id || ""))
    const toast = useToast();
    const [isOpen, setIsOpen] = useState(false);

    // const { isOpen, onOpen, onClose } = useDisclosure()
    const placeholderJsonObject = {
       
    }

    const [switchStates, setSwitchStates] = useState(existingFunctions.map(func => func.use_raw || false));




 

    const navigate = useNavigate();

    const closeModalAndNavigateHome = () => {
        setIsOpen(false);
        if (isInternal) {
            setEditorIsOpen(false)
            onClose(functionIDs, textAreas, JSONVersionTexts, switchStates)
        } else {
            navigate('/');
        }
    };



    const jsonString = JSON.stringify(placeholderJsonObject, null, 2);

    const handleJSONInputChange = (event, index) => {
        const updatedTextAreas = [...textAreas];
        updatedTextAreas[index] = event.target.value;
        setTextAreas(updatedTextAreas);

        const numSpaces = 2;


        const updatedVersionTexts = [...JSONVersionTexts];

        const transformed = JSON.stringify(transformJson(JSON.parse(Objgen.xJson(event.target.value, {numSpaces: numSpaces}))), undefined, numSpaces)
        let newJSONString = JSON.stringify({
            "name": names[index],
            "description": descriptions[index],
            "parameters": {
                "type": "object",
                "properties": JSON.parse(transformed),
                "required": jsonString ? Object.keys(JSON.parse(transformed)) : []
            }
        });
        updatedVersionTexts[index] = newJSONString;
        setJSONVersionTexts(updatedVersionTexts);

    };

    const transformFunctionListToOutputFormat = () => {
        // Filter out invalid JSONs and then map through each stringified JSON to prettify it
        const prettifiedJsons = JSONVersionTexts.filter(textArea => {
            try {
                JSON.parse(textArea);
                return true;
            } catch (error) {
                return false;
            }
        }).map(validTextArea => {
            // Parse the JSON string and stringify it again with indentation of 2 spaces
            return JSON.stringify(JSON.parse(validTextArea), null, 2);
        });
    
        // Join the array of prettified JSON strings, separating them with a comma and a newline
        const concatenatedJsons = prettifiedJsons.join(",\n");
    
        // Add "functions: " before the list, and surround the list with square brackets
        return `"functions": [\n${concatenatedJsons}\n]`;
    }
    
    

    const removeFunction = (index) => {
        const updatedTextAreas = [...textAreas];
        updatedTextAreas.splice(index, 1);
        setTextAreas(updatedTextAreas);

        const updatedNames = [...names];
        updatedNames.splice(index, 1);
        setNames(updatedNames)

        const updatedDesc = [...descriptions];
        updatedDesc.splice(index, 1);
        setDescriptions(updatedDesc)

        const updatedVersionTexts = [...JSONVersionTexts]
        updatedVersionTexts.splice(index, 1);
        setJSONVersionTexts(updatedVersionTexts)

        const updatedFunctionIDs = [...functionIDs]
        updatedFunctionIDs.splice(index, 1);
        setFunctionIDs(updatedFunctionIDs)

        const updatedSwitchStates = [...switchStates]
        updatedSwitchStates.splice(index, 1);
        setSwitchStates(updatedSwitchStates)

        
    };

    const copyJSON = async () => {
        const jsonToCopy = transformFunctionListToOutputFormat()

        try {
            await navigator.clipboard.writeText(jsonToCopy);
            toast({
                title: "JSON Copied!",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top-right",
            });

        } catch (err) {
            toast({
                title: "Error",
                description: "Failed to copy text: " + err,
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
            });
        }
    }

    const handleSwitchChange = (index) => {
        const updatedSwitchStates = [...switchStates];
        updatedSwitchStates[index] = !updatedSwitchStates[index];
        setSwitchStates(updatedSwitchStates);
    };
    
    const handleDirectJSONChange = (event, index) => {
        const updatedVersionTexts = [...JSONVersionTexts];
        updatedVersionTexts[index] = event.target.value;
        setJSONVersionTexts(updatedVersionTexts);
    };

    return (
        <Modal bg="white" isOpen={true} onClose={closeModalAndNavigateHome} size="full">
          <ModalOverlay />
          <ModalContent minH="100vh">
            <ModalCloseButton color={'black'}/>
            <ModalBody bg="white" color="black">
                <Box bg="white" width="full">
                    <VStack spacing={4} marginBottom={0}>
                        <Image 
                                src="https://i.ibb.co/1vXNFcc/symbol-text-dark.png" 
                                alt="Chatter" 
                                width="100px" 
                                objectFit="contain" 
                                textAlign={useBreakpointValue({base: 'center', md: 'left'})}
                            />
                        <Heading fontSize="4xl" color="#212121" flex="1" fontWeight="600">
                                 Function Editor
                        </Heading>
                        
                                    <>
                                        <ButtonGroup mb={8} gap={4}>
                                            <Button leftIcon={<InfoIcon/>} size={'sm'} color="black"
                                                    onClick={() => setIsOpen(true)}>Instructions</Button>
                                            <Button onClick={copyJSON} size={'sm'} leftIcon={<CopyIcon/>} color="black">Copy JSON</Button>
                                        </ButtonGroup>
                                        <Box display="flex" flexDirection="row" width="100%" height="calc(85vh)" justifyContent="space-between" marginBottom={'0px'}>
                                        <Flex flexDirection="column" flex={1} marginRight={4} maxH="85vh" overflowY="auto">
                                            <Flex flexDirection="row" justifyContent="space-between" alignItems="center" mb={4}>
                                                <Text fontSize="lg">Format: var_name:type:description</Text>
                                                <Button 
                                                    leftIcon={<Icon as={AddIcon} color="white" />} 
                                                    backgroundColor="green.500" 
                                                    borderRadius="full" 
                                                    color={"white"}
                                                    minH={"3%"}
                                                    maxW={"25%"}
                                                    _hover={{ backgroundColor: "green.300" }}
                                                    onClick={() => {
                                                        setTextAreas([...textAreas, ""]);
                                                        setNames([...names, ""]);
                                                        setDescriptions([...descriptions, ""]);
                                                        setJSONVersionTexts([...JSONVersionTexts, ""]);
                                                        setFunctionIDs([...functionIDs, ""]);
                                                        setSwitchStates([...switchStates, false])
                                                    }}
                                                >
                                                    <Text fontSize={15}>Function</Text>
                                                </Button>
                                            </Flex>
                                            {textAreas.map((text, index) => (
                                                <Box key={index} border="1px" borderRadius="xl" padding="4" borderColor="orange" marginBottom="4">
                                                    <Flex alignItems="center" justifyContent="space-between" mb={3}>
                                                    <Flex alignItems="center">
                                                        <Text fontWeight={"bold"}>Edit raw function call</Text>
                                                        <Switch 
                                                            isChecked={switchStates[index]} 
                                                            onChange={() => handleSwitchChange(index)}
                                                            ml={3}  // margin-left added for some spacing between Text and Switch
                                                            colorScheme={"orange"}
                                                        />
                                                    </Flex>

                                                        {textAreas.length > 1 && (
                                                                <Icon 
                                                                as={TrashIcon} 
                                                                color="red.500" 
                                                                w={5} 
                                                                h={5} 
                                                                ml={5} 
                                                                cursor="pointer" 
                                                                onClick={() => removeFunction(index)}
                                                                _hover={{ color: "red.700" }}
                                                            />
                                                            )}
                                                        </Flex>
                                                    <Box marginBottom={4}>
                                                        {(!switchStates[index]) &&<Text mb={2} fontSize={'sm'} color="black">Function Name</Text>}
                                                         <Flex alignItems="center">
                                                         {(!switchStates[index]) &&<Input
                                                                label="Name"
                                                                value={names[index]}
                                                                onChange={(e) => {
                                                                    const updatedNames = [...names];
                                                                    let updatedValue = e.target.value.replace(/\s+/g, '_');
                                                            
                                                                    updatedNames[index] = updatedValue;
                                                            
                                                                    setNames(updatedNames);

                                                                    if (isValidJSON(JSONVersionTexts[index])) {
                                                                        let updatedVersionTexts = [...JSONVersionTexts]
                                                                        const parsedObj = JSON.parse(JSONVersionTexts[index]);
                                                                        parsedObj.name = e.target.value;
                                                                        updatedVersionTexts[index] = JSON.stringify(parsedObj, null, 2);

                                                                        setJSONVersionTexts(updatedVersionTexts)
                                                                    }
                                                                }}
                                                                borderColor="gray.300"
                                                                focusBorderColor="orange"
                                                                flex="1"
                                                            />}
                                                        </Flex>
                                                    </Box>
                                                    <Box marginBottom={4}>
                                                    {(!switchStates[index]) &&
                                                        <>
                                                        <Text mb={2} fontSize={'sm'} color="black">Description</Text>
                                                        <Textarea
                                                            label="Description"
                                                            value={descriptions[index]}
                                                            onChange={(e) => {
                                                                const updatedDesc = [...descriptions];
                                                        
                                                                updatedDesc[index] = e.target.value;
                                                        
                                                                setDescriptions(updatedDesc);

                                                                if (isValidJSON(JSONVersionTexts[index])) {
                                                                    let updatedVersionTexts = [...JSONVersionTexts]
                                                                    const parsedObj = JSON.parse(JSONVersionTexts[index]);
                                                                    parsedObj.description = e.target.value;
                                                                    updatedVersionTexts[index] = JSON.stringify(parsedObj, null, 2);

                                                                    setJSONVersionTexts(updatedVersionTexts)
                                                                }
                                                            }}
                                                            borderColor="gray.300"
                                                            focusBorderColor="orange"
                                                        />
                                                        </>
                                                        }
                                                    </Box>
                                                    <Textarea
                                                        placeholder={"" + "anish:string:name \n" + "\theight:number:desc \n" + "\thairColor:string:color \n\n" + "chatter:string:company \n" + "\tisYC:boolean:yes"}
                                                        value={switchStates[index] ? JSONVersionTexts[index] : textAreas[index]}
                                                        resize="none"
                                                        onChange={switchStates[index] ? (e) => handleDirectJSONChange(e, index) : (e) => handleJSONInputChange(e, index)}
                                                        marginRight={4}
                                                        height={'30vh'}
                                                        borderColor="gray.300"
                                                        focusBorderColor="orange"
                                                        flex={1}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Tab') {
                                                                e.preventDefault();
                                                                const {selectionStart, selectionEnd, value} = e.target;
                                                                const newValue =
                                                                    value.substring(0, selectionStart) +
                                                                    '\t' +
                                                                    value.substring(selectionEnd);
                                                                e.target.value = newValue;
                                                                e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
                                                                handleJSONInputChange(e, index); // Call handleInputChange for all key events
                                                            }
                                                        }}
                                                    />
                                                
                                                </Box>
                                            ))}
                                            
                                        </Flex>

                                        <Flex flexDirection="column" flex={1} marginRight={4}>
                                            <Text fontSize="lg" mb={4}>Your output</Text>
                                            <Textarea
                                                placeholder={jsonString}
                                                value={transformFunctionListToOutputFormat()}
                                                resize="none"
                                                height={'100vh'}
                                                borderColor="orange"
                                                color="black"
                                                mt={3}
                                                readOnly
                                            />
                                            </Flex>
    
                                            {/*instructions modal*/}
                                            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} >
                                                <ModalOverlay />
                                                <ModalContent>
                                                    <ModalHeader bg="white" color="orange">Instructions</ModalHeader>
                                                    <ModalCloseButton color={'black'}/>
                                                    <ModalBody bg="white" color="black">
                                                        Generate function calling JSON!<br/><br/>

                                                        Use this tool to specify your function call format (we know how
                                                        annoying that can be). We'll handle all the formatting.<br/><br/>

                                                        Define the function above.<br/>

                                                        Use 'tab' to nest variables within each other for objects and arrays.<br/><br/>

                                                        By default everything is a string. Otherwise, use:<br/><br/>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            fontSize: '14px',
                                                            fontWeight: 'bold'
                                                        }}>
                                                            var_name:type:description
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter bg="white">
                                                    </ModalFooter>
                                                </ModalContent>
                                            </Modal>
                                        </Box>
                                    </>
                        </VStack>
                </Box>
             </ModalBody>
          </ModalContent>
        </Modal>
    );
    
};

export default FunctionCallEditorTool;