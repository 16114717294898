import {
    AlertDialog, AlertDialogBody,
    AlertDialogContent, AlertDialogFooter, AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader, HStack, Tag,
    Text
} from "@chakra-ui/react";
import CustomModelsMenu from "./CustomModelsMenu";
import {useRef, useState} from "react";
import {useUtilityFunctions} from "../../UtilityFunctions";
import axios from "axios";
import AddEngineModal from "./AddEngineModal";

const CustomModelCard = ({customModels, modelFamily, setCustomModels, workspaceID}) => {
    const engines = customModels[modelFamily]?.engines;
    const [isAddEngineOpen, setAddEngineOpen] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();
    const {getAccessToken} = useUtilityFunctions();


    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceID}/models/family/${modelFamily}`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceID)}`,
                }
            });

            setCustomModels(prevState => {
                const newCustomModels = {...prevState};
                delete newCustomModels[modelFamily];
                return newCustomModels;
            });

            onClose();
        } catch (error) {
            console.error('Error deleting model family:', error);
        }
    };


    return (
        <Card variant='outline'>
            <CardHeader>
                <Text fontWeight={'semibold'} >{modelFamily}</Text>
            </CardHeader>
            <CardBody>
                <Text mb={2}>Engines</Text>
                {engines && Object.keys(engines).map((engineKey, i) => (
                    <Tag key={i}>{engineKey}</Tag>
                ))}
                <HStack mt={4}>
                    <Button size={'sm'} onClick={() => setIsOpen(true)}>Delete</Button>
                    <Button size={'sm'} onClick={() => setAddEngineOpen(true)}>Add Engine</Button> {/* New Button */}
                </HStack>
            </CardBody>

            <AddEngineModal
                isOpen={isAddEngineOpen}
                onClose={() => setAddEngineOpen(false)}
                workspaceID={workspaceID}
                modelFamily={modelFamily}
                setCustomModels={setCustomModels}
            />

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Model
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to delete this model family?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={handleDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Card>
    );
};

export default CustomModelCard;

