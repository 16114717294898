import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    VStack,
    Box,
    Text, SimpleGrid, Tooltip
} from '@chakra-ui/react';
import DocumentCard from "./DocumentCard";
import {useUtilityFunctions} from "../../UtilityFunctions";
import {Search2Icon} from "@chakra-ui/icons";
import AddDocumentModal from "./AddDocumentModal";
import * as PropTypes from "prop-types";


const DocumentMenu = ({workspaceData, setWorkspaceData, getAccessToken, isOpen, documents, setDocuments}) => {
    const [showAddDocumentForm, setShowAddDocumentForm] = useState(false);


    const updateDocuments = (deletedDocumentId) => {
        const updatedDocuments = documents.filter(doc => doc.id !== deletedDocumentId);
        setDocuments(updatedDocuments);
        setWorkspaceData(prevState => ({
            ...prevState,
            aux: {
                ...prevState.aux,
                documents: updatedDocuments
            }
        }));
    };


    return (
        <>
            <Text fontWeight={'bold'} fontSize={'lg'} pb={4}>Documents</Text>
            <Button colorScheme="blue" size={'sm'} mb={4} onClick={() => setShowAddDocumentForm(true)}>
                Add Document
            </Button>
            {documents.length > 0 ? (
                <SimpleGrid columns={2} spacing={10}>
                    {documents.map((doc) => (
                        <DocumentCard key={doc.id}
                                      document={doc}
                                      workspaceId={workspaceData._id}
                                      updateDocuments={updateDocuments}
                        />
                    ))}
                </SimpleGrid>
            ) : (
                <Text>No documents available. Create one here.</Text>
            )}
            <AddDocumentModal
                isOpen={showAddDocumentForm}
                onClose={() => setShowAddDocumentForm(false)}
                documents={documents}
                setDocuments={setDocuments}
                workspaceID={workspaceData._id}
            />
        </>
    );
};

export default DocumentMenu;