// BooleanButtonGroup.js
import {Button, VStack} from "@chakra-ui/react";

const BooleanGroupInput = ({currAssertion, handleAssertionChange, handleAssertionBlur, cellAssertions}) => {
    return (
        <VStack spacing={2} align="start" w="100%">
            {cellAssertions
                .filter(assertion => assertion.id !== currAssertion.id)
                .map((assertion) => {
                    const isSelected = currAssertion.assert_list.includes(assertion.id);
                    return (
                        <Button
                            size={"xs"}
                            key={assertion.id}
                            color={isSelected ? "white" : "black"}
                            bg={isSelected ? "#ED7547" : "white"}
                            _hover={{
                                bg: isSelected ? "#D05D32" : "#F2F2F2",
                            }}
                            variant="outline"
                            onClick={() => {
                                const newList = [...currAssertion.assert_list];
                                if (isSelected) {
                                    const index = newList.indexOf(assertion.id);
                                    newList.splice(index, 1);
                                } else {
                                    newList.push(assertion.id);
                                }
                                handleAssertionChange(currAssertion.id, {
                                    ...currAssertion,
                                    assert_list: newList
                                });
                            }}
                            onBlur={handleAssertionBlur}
                        >
                            {assertion.assert_type} - {assertion.assert_string}
                        </Button>
                    )
                })}
        </VStack>
    );
}

export default BooleanGroupInput;