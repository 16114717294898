import {
    Grid,
    Box,
    Heading,
    Divider,
    useColorModeValue,
    IconButton,
    VStack,
    useColorMode,
    CloseButton,
    Flex,
    useToast,
    useDisclosure,
    Text,
    Center, HStack, Button, Badge, Card, CardBody
} from '@chakra-ui/react';
import {LabTestIcon, CodeIcon, TimeIcon} from 'evergreen-ui';
import {useEffect, useState} from 'react';
import TestingPanel from '../Testing/TestingPanel';
import CodePanel from '../ExportCode/CodePanel';
import RunHistoryPanel from '../RunHistory/RunHistoryPanel';
import axios from "axios";
import Chain from './Chain';
import ChainBar from "./ChainBar";
import {useUtilityFunctions} from "../UtilityFunctions";

const ChainSpace = ({chainName, chainID, workspaceDataID, workspaceData, userData, setUserData}) => {
    const {colorMode} = useColorMode();
    const bgLeft = useColorModeValue("white", "gray.800");
    const bgRight = useColorModeValue("#FFFFFF", "gray.900");
    const textColor = useColorModeValue("black", "white");
    const [isSidePanelVisible, setIsSidePanelVisible] = useState(false);
    const [activePanel, setActivePanel] = useState('');
    const [cells, setCells] = useState([]);
    const [suiteData, setSuiteData] = useState(null); // need to update this in prompt and use in testing panel
    const [modelData, setModelData] = useState({});
    const [allOpen, setAllOpen] = useState(true);
    const [currRunState, setCurrRunState] = useState({chain_stats: {}, test_results: []});
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();
    const [chainIsRunning, setChainIsRunning] = useState(false)
    const toast = useToast();
    const [mergedModelData, setMergedModelData] = useState({});
    const [chainData, setChainData] = useState(null)

    const fetchSuiteData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                },
                params: {
                    chain_id: chainID,
                },
            });


            if (response.data) {
                setSuiteData(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch tests:', error);
        }
    };

    const fetchChain = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/chain/${chainID}`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                }
            });


            if (response.data) {
                setChainData(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch chain:', error);
        }
    };

    useEffect(() => {
        fetchSuiteData();
        fetchChain();
    }, [chainID]);

    const fetchCells = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/chain/${chainID}/cells`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                }
            });


            setCells(response.data);
        } catch (error) {
            console.error('Failed to fetch cells:', error);
        }
    };

    const fetchModelData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/model`, {});

            setModelData(response.data);
        } catch (error) {
            console.error('Failed to fetch cells:', error);
        }
    }

    useEffect(() => {
        fetchModelData();
    }, []);

    // useEffect(() => {
    //     console.log("model data", modelData);
    // }, [modelData]);

    useEffect(() => {
        fetchCells();

    }, [chainID]);


    const handleIconClick = (panel) => {
        setActivePanel(panel);
        setIsSidePanelVisible(true);
    };

    const handleExitClick = () => {
        setIsSidePanelVisible(false);
    };

    const runChainOnSuite = async () => {
        try {
            setChainIsRunning(true);
            handleIconClick('TestingPanel');

            // update workspace, get vault ID
            let updatedWorkspace = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/byID/${workspaceDataID}`,
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    },
                });
            if (!updatedWorkspace.data.aux.vault_id) {
                toast({
                    title: "No vault found",
                    description: "Please choose an API key vault before running",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                setChainIsRunning(false);
                return;
            }
            const updatedWorkspaceVault = updatedWorkspace.data.aux.vault_id


            const response = await axios.post(`${process.env.REACT_APP_ROUTE_PREFIX}/api/chain/${chainID}/run/suite`,
                {
                    vault_id: updatedWorkspaceVault,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    },
                });

            // now update the overall currRunState var


            if (response.data) {
                const history = response.data;
                const chain_stats = {
                    timestamp: history.timestamp,
                    stats: history.stats
                };

                const test_results = history.tests.map((test, index) => {
                    const test_stats = {
                        timestamp: history.timestamp,
                        stats: test.stats
                    };

                    const cell_results = test.cell_results.map((cell_result, index) => ({
                        cell_id: cells[index]._id,
                        timestamp: history.timestamp,
                        timestampIsStandout: false,
                        result: cell_result.result,
                        usage: cell_result.usage,
                        evals: cell_result.evals,
                        passed: cell_result.passed
                    }));

                    return {
                        testID: suiteData.tests[index].id,
                        test_stats,
                        cell_results
                    };
                });

                setCurrRunState({chain_stats, test_results});
                let updatedSuiteData = {
                    ...suiteData,
                    state: {chain_stats, test_results}
                };
                setSuiteData(updatedSuiteData)


                try {
                    const newState = {
                        chain_stats: chain_stats,
                        test_results: test_results
                    };

                    const runStatePutResponse = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/suite/suites/${suiteData._id}/state`, newState, {
                        headers: {
                            'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                        },
                    });


                } catch (error) {
                    console.error('Failed to update the current run state:', error);

                    setChainIsRunning(false);
                }
            }
        } catch (error) {
            console.error('Failed to run suite on chain:', error);
            toast({
                title: "There was a problem.",
                description: "Please make sure your model is selected and your vault has correct keys for the selected models.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setChainIsRunning(false);
        } finally {
            setChainIsRunning(false);
        }
    };


    const buttonBg = useColorModeValue("white", "gray.800");

    const orangeColor = "#ED7547"

    useEffect(() => {

        if (workspaceData && workspaceData.aux && workspaceData.aux.models && modelData && Object.keys(modelData).length > 0) {

            const constantModels = ['OpenAI', 'Anthropic', 'Cohere-Chat', 'HuggingFace'];

            const filteredModelData = Object.keys(modelData)
                .filter(key => constantModels.includes(key))
                .reduce((acc, key) => {
                    acc[key] = modelData[key];
                    return acc;
                }, {});


            const customModels = Object.keys(workspaceData.aux.models).reduce((acc, modelKey) => {
                const engines = workspaceData.aux.models[modelKey].engines;
                acc[modelKey] = Object.keys(engines).reduce((engineAcc, engineKey) => {
                    engineAcc[engineKey] = engines[engineKey];
                    return engineAcc;
                }, {});
                return acc;
            }, {});

            const updatedModelData = {...filteredModelData, ...customModels};

            setMergedModelData(updatedModelData);
        }
    }, [workspaceData.aux.models, modelData])


    //CSV import stuff
    const {isOpen: isCSVModalOpen, onOpen: openCSVModal, onClose: closeCSVModal} = useDisclosure();


    // Find the collection for the given chain ID
    const collectionForChain = workspaceData.collections.find(collection =>
        collection.chains.some(chain => chain.id === chainID)
    );


    const addCell = async (init_messages = [], cellPresetIndex = -1) => {
        let defaultPrompt = [];

        // Add starting_messages if present
        if (collectionForChain && collectionForChain.starting_messages && collectionForChain.starting_messages.length > 0) {
            defaultPrompt = [...defaultPrompt, ...collectionForChain.starting_messages];
        }

        // Add init_messages if present
        if (init_messages.length > 0) {
            defaultPrompt = [...defaultPrompt, ...init_messages];
        }

        // if chat, add the message with variable for the cell input
        if (chainData && chainData.config && chainData.config.type && chainData.config.type === "chat") {
            let cellIndex = cells.length
            if (cellPresetIndex > 0) {
                cellIndex = cellPresetIndex
            }
            // CHANGED FROM TEST TO USER
            const chatPrompt = [{role: "user", content: `{{Message_${cellIndex + 1}_Input}}`}];
            defaultPrompt = [...defaultPrompt, ...chatPrompt];
        }

        // If both are empty, use default message
        if (defaultPrompt.length === 0) {
            defaultPrompt = [{role: "user", content: "New prompt"}];
        }

        let use_routers = false;
        if (collectionForChain && collectionForChain.settings && collectionForChain.settings.default_all_routers) {
            use_routers = true
        }

        if (!collectionForChain) {
            toast({
                title: "Chain not in the current workspace.",
                description: "Please open the correct workspace or close this chain.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        // Add your default cell configuration here
        const newCell = {
            name: "New Cell",
            chain_id: chainID,
            model_family: "OpenAI",
            model: use_routers ? "gpt-4" : "gpt-3.5-turbo",
            prompt: defaultPrompt,
            auto_all_routers: use_routers,
            collection_id: collectionForChain.id
        };

        try {
            // Replace with your actual backend endpoint and add required headers if necessary
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/cell`,
                newCell,
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    }
                }
            );


            // If successful, add new cell to state
            if (response.data.msg === "Cell created") {
                setCells((prevCells) => [...prevCells, response.data.cell]);
                return response.data.cell
            } else {
                throw new Error("Cell creation failed");
            }
        } catch (err) {
            console.error("Failed to create cell:", err);
        }
    };

    const addCellBulk = async (numberOfCells, init_messages = [], startingIndex) => {

        const cellsToAdd = []

        for (let i = 0; i < numberOfCells; i++) {
            let defaultPrompt = [];


            // Add starting_messages if present
            if (collectionForChain && collectionForChain.starting_messages && collectionForChain.starting_messages.length > 0) {
                defaultPrompt = [...defaultPrompt, ...collectionForChain.starting_messages];
            }

            // Add init_messages if present
            if (init_messages.length > 0) {
                defaultPrompt = [...defaultPrompt, ...init_messages];
            }

            // if chat, add the message with variable for the cell input
            // CHANGED FROM TEST TO USER
            if (chainData && chainData.config && chainData.config.type && chainData.config.type === "chat") {
                const chatPrompt = [{role: "user", content: `{{Message_${startingIndex + i + 1}_Input}}`}];
                defaultPrompt = [...defaultPrompt, ...chatPrompt];
            }

            // If both are empty, use default message
            if (defaultPrompt.length === 0) {
                defaultPrompt = [{role: "user", content: "New prompt"}];
            }

            let use_routers = false;
            if (collectionForChain && collectionForChain.settings && collectionForChain.settings.default_all_routers) {
                use_routers = true
            }


            cellsToAdd.push({
                name: "New Cell",
                chain_id: chainID,
                model_family: "OpenAI",
                model: use_routers ? "gpt-4" : "gpt-3.5-turbo",
                prompt: defaultPrompt,
                auto_all_routers: use_routers,
                collection_id: collectionForChain.id
            });
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/bulk`,
                cellsToAdd,
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    }
                }
            );

            if (response.data.msg.includes("Cells created")) {
                setCells((prevCells) => [...prevCells, ...response.data.cells]);
                return response.data.cells;
            } else {
                throw new Error("Bulk cell creation failed");
            }
        } catch (err) {
            console.error("Failed to create cells in bulk:", err);
        }
    };

    if (userData.status && userData.status.role && userData.status.role !== 'developer') {
        // NON TECHNICAL CHAIN SPACE
        return (
            <VStack alignItems="stretch" height="100%" style={{overflowY: 'auto'}}>
                {/* Top aligned bar with buttons */}
                <HStack spacing={2} pl={4} pr={4} pt={2}>
                    <Button
                        aria-label="Open testing panel"
                        onClick={() => handleIconClick('TestingPanel')}
                        colorScheme={activePanel !== 'RunHistoryPanel' ? "messenger" : "gray"}
                        size={"sm"}
                    >
                        Testing Panel
                    </Button>
                    <Button
                        aria-label="Open run history panel"
                        onClick={() => handleIconClick('RunHistoryPanel')}
                        colorScheme={activePanel !== 'RunHistoryPanel' ? "gray" : "facebook"}
                        size={"sm"}
                    >
                        Run History
                    </Button>
                </HStack>
                <Box flex="1" overflowY="auto">
                    {chainData && chainData.config && chainData.config.type && chainData.config.type === "chat" ? (
                        <Box pl={4} pr={4}>
                            <Badge colorScheme="blue">Chat Test</Badge>
                        </Box>
                    ) : (
                        <div>
                            <Divider/>
                            <Box pl={4} pr={4}>
                                {cells.map((cell, index) => (
                                    <Box key={index} mb={4}>
                                        <Box fontWeight="bold">Cell {index + 1}</Box>
                                        {cell.config.prompt.map((item, subIndex) => (
                                            <Box key={subIndex} ml={2} mb={4}>
                                                <Badge colorScheme="blue">{item.role}</Badge>
                                                <Card mt={2} variant='outline'>
                                                    <CardBody>
                                                        <Box as="pre"
                                                             style={{whiteSpace: 'pre-wrap', fontFamily: 'inherit'}}>
                                                            {
                                                                item.content.split(/({{.*?}})/g).map((part, i) =>
                                                                    part.startsWith("{{") && part.endsWith("}}") ?
                                                                        <Badge key={i}>{part.slice(2, -2)}</Badge> :
                                                                        part
                                                                )
                                                            }
                                                        </Box>
                                                    </CardBody>
                                                </Card>
                                            </Box>
                                        ))}
                                    </Box>
                                ))}
                            </Box>
                        </div>
                    )}
                    <Divider/>
                    {/* Testing panel / history */}
                    <Box bg={bgLeft} color={textColor} pl={4} pr={4} overflowY="auto" height="calc(100vh - 100px)">
                        {
                            activePanel !== 'RunHistoryPanel' ? (workspaceDataID && suiteData &&
                                    <TestingPanel workspaceDataID={workspaceDataID} workspaceData={workspaceData}
                                                  chainName={chainName} chainID={chainID} chainData={chainData}
                                                  cells={cells} iconClick={handleIconClick}
                                                  suiteData={suiteData} setSuiteData={setSuiteData}
                                                  currRunState={currRunState} setCurrRunState={setCurrRunState}
                                                  runChainOnSuite={runChainOnSuite}
                                                  chainIsRunning={chainIsRunning} setChainIsRunning={setChainIsRunning}
                                                  isCSVModalOpen={isCSVModalOpen} openCSVModal={openCSVModal}
                                                  closeCSVModal={closeCSVModal} addCellBulk={addCellBulk}/>) :
                                (chainName &&
                                    <RunHistoryPanel workspaceDataID={workspaceDataID} chainName={chainName}
                                                     chainID={chainID} cells={cells}/>)
                        }
                    </Box>
                </Box>
            </VStack>
        );
    } else {
        // TECHNICAL CHAIN SPACE
        return (
            <Grid templateColumns={isSidePanelVisible ? "1fr 1px 1fr" : "1fr"} alignItems="stretch" height="100%">
                <Box
                    bg={bgLeft}
                    color={textColor}
                    pr={8}
                    overflowY="auto"
                    height="calc(100vh - 100px)"
                    position="relative"
                >
                    {chainData && chainData.config && chainData.config.type && chainData.config.type === "chat" && <Box
                        backgroundColor="blue.500"
                        p={2}
                        boxShadow="md"
                        fontWeight={"bold"}
                        mt={2} // optional: add some margin at the top to separate it from other elements
                    >
                        <Center>
                            <Text color="white">Chat</Text>
                        </Center>
                    </Box>}
                    <ChainBar chainID={chainID} allOpen={allOpen} setAllOpen={setAllOpen}
                              runChainOnSuite={runChainOnSuite}
                              chainIsRunning={chainIsRunning} setChainIsRunning={setChainIsRunning}
                              handleIconClick={handleIconClick}/>
                    <Chain allOpen={allOpen} currRunState={currRunState} setCurrRunState={setCurrRunState}
                           workspaceDataID={workspaceDataID} workspaceData={workspaceData} chainName={chainName}
                           chainID={chainID} cells={cells} modelData={modelData} setCells={setCells}
                           suiteData={suiteData}
                           setSuiteData={setSuiteData} handleIconClick={handleIconClick} fetchSuiteData={fetchSuiteData}
                           mergedModelData={mergedModelData} chainData={chainData} addCell={addCell}
                    />

                    <VStack align="stretch" position="absolute" height="100%" right="0" top="0" spacing={2}
                            bg={colorMode === 'light' ? 'white' : 'gray.800'} border="1px solid" borderRight="none"
                            borderTop="none" borderBottom="none"
                            borderColor={colorMode === 'light' ? '#ededed' : 'white'}>
                        <IconButton
                            aria-label="Open testing panel"
                            icon={<LabTestIcon
                                color={isSidePanelVisible && activePanel === 'TestingPanel' ? orangeColor : 'default'}/>}
                            bg={buttonBg}
                            _hover={{bg: "white"}}
                            onClick={() => handleIconClick('TestingPanel')}
                        />
                        <IconButton
                            aria-label="Open code panel"
                            icon={<CodeIcon
                                color={isSidePanelVisible && activePanel === 'CodePanel' ? orangeColor : 'default'}/>}
                            bg={buttonBg}
                            _hover={{bg: "white"}}
                            onClick={() => handleIconClick('CodePanel')}
                        />
                        <IconButton
                            aria-label="Open run history panel"
                            icon={<TimeIcon
                                color={isSidePanelVisible && activePanel === 'RunHistoryPanel' ? orangeColor : 'default'}/>}
                            bg={buttonBg}
                            _hover={{bg: "white"}}
                            onClick={() => handleIconClick('RunHistoryPanel')}
                        />
                    </VStack>
                </Box>
                {isSidePanelVisible && (
                    <>
                        <Divider orientation="vertical" borderColor="#ededed" borderWidth="1px"/>
                        <Box
                            bg={bgRight}
                            color={textColor}
                            p={4}
                            overflowY="auto"
                            height="calc(100vh - 100px)"
                            transition="all 0.3s ease"
                        >
                            <Flex justify="space-between">
                                <Heading as="h4" size="md"
                                         fontWeight="regular">{activePanel === "TestingPanel" ? "Testing Suite" : activePanel === "CodePanel" ? "Code Exports" : "Run History"}</Heading>
                                <CloseButton onClick={handleExitClick}/>
                            </Flex>
                            {
                                activePanel === 'TestingPanel' ? (workspaceDataID && suiteData &&
                                        <TestingPanel workspaceDataID={workspaceDataID} workspaceData={workspaceData}
                                                      chainName={chainName} chainID={chainID} chainData={chainData}
                                                      cells={cells} handleIconClick={handleIconClick}
                                                      suiteData={suiteData} setSuiteData={setSuiteData}
                                                      currRunState={currRunState} setCurrRunState={setCurrRunState}
                                                      runChainOnSuite={runChainOnSuite}
                                                      chainIsRunning={chainIsRunning} setChainIsRunning={setChainIsRunning}
                                                      isCSVModalOpen={isCSVModalOpen} openCSVModal={openCSVModal}
                                                      closeCSVModal={closeCSVModal} addCellBulk={addCellBulk}/>) :
                                    activePanel === 'CodePanel' ?
                                        (chainName && <CodePanel workspaceDataID={workspaceDataID} chainName={chainName}
                                                                 chainID={chainID} cells={cells}/>) :
                                        (chainName &&
                                            <RunHistoryPanel workspaceDataID={workspaceDataID} chainName={chainName}
                                                             chainID={chainID} cells={cells} chainData={chainData}/>)
                            }
                        </Box>
                    </>
                )}
            </Grid>
        );
    }
}

export default ChainSpace;
