import {Textarea, Box, Text, Flex, Button, useToast} from '@chakra-ui/react';
import StatsPanel from './StatsPanel'
import {useState} from "react";
import {ArrowUpDownIcon, CopyIcon, ExternalLinkIcon} from "@chakra-ui/icons";

const CellTestOutput = ({ cell, cellIndex, testId, chainID, chainIsRunning, currRunState, setCurrRunState }) => {

  const [isExpanded, setIsExpanded] = useState(false);

  const toast = useToast();  // For showing notifications

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(cellOutput);
            toast({
                title: "Copied!",
                description: "Output copied to clipboard.",
                status: "success",
                duration: 2000,
                isClosable: true,
            });
        } catch (err) {
            console.error("Failed to copy text: ", err);
            toast({
                title: "Error",
                description: "Failed to copy content.",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    };

  if (!currRunState || !currRunState.test_results) {
    return (
      <Textarea placeholder="Run to see cell output here..." size="sm" resize="none" readOnly/>
    );
  }

  const currTestRunState = currRunState.test_results.find(result => result.testID === testId);

  
  let cellOutput = '';
  let cellUsage = {};
  let borderColor = 'gray';
  let borderWidth = '1px';
  let cellTimeStamp = "";
  let timestampColor = "gray.500";



  if (currTestRunState && currTestRunState.cell_results) {
    const specificCellResult = currTestRunState.cell_results.find(result => result.cell_id === cell._id);
    if (specificCellResult) {
      cellOutput = specificCellResult.result || '';

      if (typeof cellOutput !== 'string') {
        // result of a function call
       
        cellOutput = JSON.stringify(cellOutput);
       
      }

      cellUsage = specificCellResult.usage || {};

      if (specificCellResult.hasOwnProperty('passed')) {
        borderColor = specificCellResult.passed ? 'green' : 'red';
        borderWidth = '2px';
      }
      if (specificCellResult.hasOwnProperty('timestamp')) {
        cellTimeStamp = new Date(specificCellResult.timestamp * 1000).toLocaleString()
      }
      if (specificCellResult.hasOwnProperty('timestampIsStandout')) {
        if(specificCellResult.timestampIsStandout) {
          timestampColor="#e16e2b"
        }
      }
    }
  }


  return (
      <>
        <Box position="relative" width="100%" _hover={{ ".showOnHover": { opacity: 1 } }}>
            <Textarea
                height={isExpanded ? "500px" : "100px"} // Adjust these as needed
                border={borderWidth}
                borderColor={borderColor}
                value={cellOutput}
                placeholder="Run to see cell output here..."
                size="sm"
                resize="none"
                readOnly
            />
            <Box
                className="showOnHover"
                position="absolute"
                top={2}
                right={2}
                display="flex"
                flexDirection="column"
                gap={1}
                zIndex={1}
                opacity={0}
                alignItems="flex-end"
            >
                <Button
                    size="xs"
                    _hover={{ opacity: 1 }}
                    onClick={() => setIsExpanded(prev => !prev)}
                    leftIcon={<ArrowUpDownIcon />}  // Expand icon
                >
                    {isExpanded ? "Collapse" : "Expand"}
                </Button>
                <Button
                    size="xs"
                    _hover={{ opacity: 1 }}
                    onClick={handleCopy}
                    leftIcon={<CopyIcon />}  // Copy icon
                >
                    Copy
                </Button>
            </Box>

        </Box>
        <Flex justifyContent="space-between" align="center" wrap="nowrap" mb={5}>
          <Text color={timestampColor} fontSize="sm">From run at: {cellTimeStamp}</Text>
          {(cellUsage && cellUsage.time && cellUsage.prompt_tokens && cellUsage.cost) && (
              <Box fontSize="sm" flexGrow={1}>
              <Flex justifyContent="flex-end">
                <StatsPanel runTime={cellUsage.time} promptTokens={cellUsage.prompt_tokens} completionTokens={cellUsage.completion_tokens} dollarCost={cellUsage.cost} isCollapsed={true}/>
              </Flex>
          </Box>
        )}
      </Flex>
    </>
  );
  
}

export default CellTestOutput;
