import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Flex, useToast
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {LockIcon} from "@chakra-ui/icons";
import axios from "axios";
import CreateVault from "./CreateVault";
import VaultCard from "./VaultCard";
import {useUtilityFunctions} from "../UtilityFunctions";

const VaultModal = ({workspaceData, setWorkspaceData}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [vaults, setVaults] = useState([]);
    const [modelData, setModelData] = useState({});
    const [activeVaultId, setActiveVaultId] = useState(null);
    const toast = useToast();
    const {getAccessToken} = useUtilityFunctions();


    useEffect(() => {
        setWorkspaceData(prevWorkspaceData => ({
            ...prevWorkspaceData,
            aux: {
                ...prevWorkspaceData.aux,
                vault_id: activeVaultId
            }
        }));
    }, [activeVaultId]);

    useEffect(() => {
        if (workspaceData.aux.vault_id) {
            setActiveVaultId(workspaceData.aux.vault_id)
        }
    }, [workspaceData.aux.vault_id]);

    useEffect(() => {
        if(vaults.length > 0 && !activeVaultId){
            selectVault(vaults[0]._id);
        }
        if(activeVaultId && vaults.length < 1){
            selectVault(activeVaultId);
        }
        if (vaults.length > 0 && (!activeVaultId || !vaults.some(vault => vault._id === activeVaultId))) {
            selectVault(vaults[0]._id);
        }
    }, [vaults, activeVaultId]);


    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    const fetchVaults = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/vault`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                }
            });

            setVaults(response.data);

            if(response.data.length > 0 && !activeVaultId){
                selectVault(response.data[0]._id)
                setActiveVaultId(response.data[0]._id);
            }
        } catch (err) {
            console.error(err);
            // You can add some error handling here.
        }
    }

    const deleteVault = async (vaultId) => {
        if (vaultId === activeVaultId) {
            toast({
                title: "Action Denied",
                description: "Cannot delete the active vault.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        } 


        try {
            await axios.delete(`${process.env.REACT_APP_ROUTE_PREFIX}/api/vault/${vaultId}`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`
                }
            });
            fetchVaults(); // Refresh vaults list
        } catch (err) {
            console.error(err);
            // Handle error here
        }
    };


    const fetchModelData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/model`, {});

    

            setModelData(response.data);
        } catch (error) {
            console.error('Failed to fetch cells:', error);
        }
    }

    useEffect(() => {
        fetchVaults();
        fetchModelData();
    }, []);


    const selectVault = async (vaultId) => {
        setActiveVaultId(vaultId);

        // make the API call to update the backend
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceData._id}/vault`,
                {vault_id: vaultId},
                {headers: {'Authorization': `Bearer ${getAccessToken(workspaceData._id)}`}}
            );


        } catch (error) {
            console.error('Failed to update workspace vault id:', error);
        }
    };


    return (
        <>
            {(console.log("ID IS ", activeVaultId))}
            <Button w="100%" mt={2} variant={'solid'} colorScheme={'purple'} onClick={handleOpen}
                    leftIcon={<LockIcon/>}>Vault</Button>

            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Vaults</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {vaults.map(vault => <VaultCard key={vault._id} onDelete={deleteVault} vault={vault}
                                                        activeVaultId={activeVaultId} onSelect={selectVault}
                                                        workspaceData={workspaceData}/>)}
                    </ModalBody>
                    <ModalFooter>
                        <Flex justifyContent="space-between" width="100%">
                            <CreateVault modelData={modelData} setVaults={setVaults} workspaceDataID={workspaceData._id}/>
                            <Button colorScheme="blue" onClick={handleClose}>
                                Close
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default VaultModal;
