import * as monaco from 'monaco-editor';


export const editorSetup = () => {

    const LANGUAGE_ID = 'chatter-ninja';

    monaco.languages.register({id: LANGUAGE_ID});

    monaco.languages.setLanguageConfiguration(LANGUAGE_ID, {
        comments: {
            lineComment: '#',
        },
        brackets: [
            ['{{', '}}'],
            ['{% ', ' %}'],
            ['{%', '%}'],
            ['{', '}'],
            ['[', ']'],
            ['(', ')']
        ],
        autoClosingPairs: [
            {open: '{{', close: '}}'},
            {open: '{%', close: '%}'},
            {open: '{', close: '}'},
            {open: '[', close: ']'},
            {open: '(', close: ')'},
            {open: '"', close: '"'},
            {open: "'", close: "'"}
        ],
    });

    monaco.languages.setMonarchTokensProvider(LANGUAGE_ID, {
        tokenizer: {
            root: [
                [/^\s*#.*/, "comment"],
                [/[{}\[\]()]/, "delimiter"],
                [/".*?"/, "string"],
                [/'.*?'/, "string"],
                [/{%.*?%}/, "variable"],
                [/{{.*?}}/, "variable"],
                // [/\b(for|if|set|include|block|macro|call|endfor|endif|endset|endblock|endmacro)\b/, "keyword"]
            ]
        }
    });


}
