import {
    Code,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Heading,
    HStack,
    Icon,
    Text,
    VStack,
    Divider,
    Flex
} from "@chakra-ui/react";
import {FaClock, FaPercentage} from "react-icons/fa";
import StatDisplay from "./StatDisplay";
import CellResultHistory from "./CellResultHistory";
import React from "react"; // Import appropriate icons

const CellHistoryDetails = ({history}) => {


    return (
        <VStack align="start" spacing={4}>
            {/* Stats Card */}
            <StatDisplay stats={history.stats}/>

            Tests Accordion
            <Accordion allowToggle w={'100%'}>
                <Heading size="sm">Tests</Heading>
                {history.tests.map((test, index) => (<AccordionItem key={index} border={'none'}>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">{test.name || "Unnamed Test"}</Box>
                        <AccordionIcon/>
                    </AccordionButton>
                    <AccordionPanel>
                        <CellResultHistory test_prompt={test.test_prompt} result={test.result} passed={test.passed}
                                           usage={test.usage} evals={test.evals}/>
                    </AccordionPanel>
                </AccordionItem>))}
            </Accordion>

            {/* Summary of Cells */}
            <Box>
                <Text as={'b'}>Cell Configuration:</Text><br/>
                <Flex>
                    <Text width="80px">Prompt:</Text>
                    <Code flex="1" whiteSpace="pre-wrap">
                        {typeof history.details.prompt === 'string' ? history.details.prompt : JSON.stringify(history.details.prompt)}
                    </Code>
                </Flex>
                <Flex>
                    <Text width="80px">Model:</Text>
                    <Text flex="1" whiteSpace="pre-wrap">{history.details.model_family} / {history.details.model}</Text>
                </Flex>
                {history.details.params && Object.keys(history.details.params).length > 0 && (<Flex>
                    <Text width="80px">Params:</Text>
                    <Text flex="1" whiteSpace="pre-wrap">
                        {Object.entries(history.details.params).map(([key, value], index) => (
                            <span key={index}>
                                {key}: {typeof value === 'string' ? value : JSON.stringify(value)}
                                {index < Object.keys(history.details.params).length - 1 ? ', ' : ''}
                            </span>
                        ))}
                    </Text>
                </Flex>)}

            </Box>
        </VStack>
    );
};

export default CellHistoryDetails;
