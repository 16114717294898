import {
    Tag,
    TagLabel,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverCloseButton,
    Button,
    VStack,
    Input,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Box,
    HStack
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import axios from "axios";
import {useUtilityFunctions} from "../UtilityFunctions";


const ParamSelector = ({cellID, paramDetails, localCell, setLocalCell, workspaceDataID}) => {
    const [params, setParams] = useState(localCell.config.params);
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    // useEffect(() => {
    //     updateParams();
    // }, [params]);


    const updateParams = async () => {
       
        if (paramDetails && params) {
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cellID}/update/params`,
                {
                    params: params,
                    config: paramDetails,
                    },
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`,
                        'Content-Type': 'application/json',
                    }
                });

            setLocalCell(prevCell => ({...prevCell, config: {...prevCell.config, params: response.data.params}}));
            setParams(response.data.params)
           
        }
    }

    useEffect(() => {
        setParams(localCell.config.params);
    }, [localCell.config.params]);

    const handleParamChange = (paramKey, value) => {
     
        if (paramDetails[paramKey].type === "range") {
            const withinRange = value >= paramDetails[paramKey].min && value <= paramDetails[paramKey].max;
            if (!withinRange) {
                console.log(`Value ${value} for ${paramKey} is not within the range`);
                return;
            }
        }
        setParams(prevParams => ({...prevParams, [paramKey]: value}));
    }

    return (
        <>
            {paramDetails &&
                <HStack align="start" spacing={2} width="full">
                    {Object.entries(paramDetails).filter(([paramKey]) => paramKey !== "functions").map(([paramKey, paramValue]) => (
                        <Popover key={paramKey}>
                            <PopoverTrigger>
                                <Tag size="sm" key={paramKey} borderRadius="full" colorScheme="gray">
                                    <TagLabel>{paramKey}: {params[paramKey] || paramValue.default}</TagLabel>
                                </Tag>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverHeader>{paramKey}</PopoverHeader>
                                <PopoverCloseButton/>
                                <PopoverBody>
                                    {paramValue.type === "range" &&
                                        <NumberInput
                                            min={paramValue.min}
                                            max={paramValue.max}
                                            defaultValue={params[paramKey] || paramValue.default}
                                            onChange={(value) => handleParamChange(paramKey, value)}
                                            onBlur={updateParams}>
                                            <NumberInputField/>
                                            <NumberInputStepper>
                                                <NumberIncrementStepper/>
                                                <NumberDecrementStepper/>
                                            </NumberInputStepper>
                                        </NumberInput>}
                                    {paramValue.type === "string_array" &&
                                        <Input
                                            defaultValue={(params[paramKey] && params[paramKey].join(',')) || paramValue.default.join(',')}
                                            onChange={(event) => handleParamChange(paramKey, event.target.value.split(','))}
                                            onBlur={updateParams}
                                        />}
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    ))}
                </HStack>
            }
        </>
    );
}

export default ParamSelector;
