import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    useToast, HStack, Box, Code
} from "@chakra-ui/react";
import {useState, useEffect} from "react";
import axios from "axios";
import {SettingsIcon} from "@chakra-ui/icons";
import {useUtilityFunctions} from "../UtilityFunctions";
import MonacoEditor from "react-monaco-editor";

const CellSDKModal = ({cell_id, vars}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [sdkReference, setSdkReference] = useState(null);
    const [referenceInput, setReferenceInput] = useState("");
    const toast = useToast();
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();

    const onOpen = () => {
        setIsOpen(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const fetchReference = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cell_id}/sdk_reference`, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken()}`
                }
            });
            setSdkReference(response.data.reference);
            setReferenceInput(response.data.reference || "");
        } catch (error) {
            console.error(error);
            // Handle error as needed
        }
    };

    const updateReference = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/${cell_id}/sdk_reference`, {reference: referenceInput}, {
                headers: {
                    'Authorization': `Bearer ${getAccessToken()}`
                }
            });
            toast({title: "Reference Updated!", status: "success"});
            setSdkReference(referenceInput);
        } catch (error) {
            console.error(error);
            toast({title: "Reference Taken!", status: "error"});
        }
    };


    useEffect(() => {
        if (isOpen) {
            fetchReference();
        }
    }, [isOpen]);

    const varsToString = (vars) => {
        if (!Array.isArray(vars)) return "";
        return vars.map((value) => `"${value}": ""`).join(", ");
    };

    return (
        <>
            <Button colorScheme="facebook" size="sm" borderRadius={4} onClick={onOpen}
                    leftIcon={<SettingsIcon/>}>API</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>API Modal</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {sdkReference ? <div>Current Prompt ID: <span><Code fontSize={'lg'}>{sdkReference}</Code></span> </div> :
                            <div>No prompt id set. Please update it.</div>}
                        <HStack mt={4}>
                            <Input borderRadius={6} size={'sm'} value={referenceInput}
                                   onChange={e => setReferenceInput(e.target.value)}/>
                            <Button size={'sm'} borderRadius={6} colorScheme="blue" onClick={updateReference}>
                                Update
                            </Button>
                        </HStack>
                        <Box mt={4} mb={4}>
                            <MonacoEditor
                                width="100%"
                                height="200px"
                                language="python"
                                theme="vs-dark"
                                value={`chatter.render_prompt(id='${sdkReference}', variables={${varsToString(vars)}});`}
                                options={{
                                    readOnly: true,
                                    minimap: {enabled: false},
                                    wordWrap: 'on',
                                    automaticLayout: true,
                                }}
                            />
                        </Box>
                        <Button
                            size={'sm'}
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    `chatter.render_prompt(id='${sdkReference}', variables={${varsToString(vars)}});`
                                )
                                    .then(() => {
                                        toast({title: "Code Copied!", status: "success"});
                                    })
                                    .catch(err => {
                                        toast({title: "Failed to copy code", status: "error"});
                                    });
                            }}
                        >
                            Copy Code
                        </Button>
                    </ModalBody>

                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CellSDKModal;
