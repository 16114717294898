import React, {useRef, useEffect, useState} from 'react';
import MonacoEditor from 'react-monaco-editor';
import axios from "axios";
import {Button, HStack, Select, Text, VStack, useDisclosure, useToast} from "@chakra-ui/react";
import {useUtilityFunctions} from "../UtilityFunctions";
import {editorSetup} from './editorSetup';
import {MinusIcon} from "@chakra-ui/icons";
import {ImportIcon} from 'evergreen-ui';
import ImportChatCSVModal from '../ChatImport/ImportChatCSVModal';
import ControllerAlertModal from '../Chain/ControllerAlertModal';

const PromptEditor = ({
                          prompt,
                          setPrompt,
                          cell,
                          cellIndex,
                          setLocalCell,
                          setSuiteData,
                          modelDetails,
                          workspaceDataID,
                          model,
                          modelFamily,
                          workspaceData,
                          chainID,
                          chainData,
                          checkChainController,
                          updateChainController
                      }) => {
    const MIN_HEIGHT = 200;
    const MAX_HEIGHT = 800;

    const editorRef = useRef(null);
    const editorContentRef = useRef(prompt); // Using useRef to store the latest editor content

    const [editorHeight, setEditorHeight] = useState(MIN_HEIGHT); // starting height
    const [editorContent, setEditorContent] = useState(prompt); // editor content state
    const {getAccessToken, getUserData, logoutStorage} = useUtilityFunctions();
    const [editorContentArray, setEditorContentArray] = useState(prompt || []);
    const toast = useToast();

    const [isController, setIsController] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const fallbackFocusRef = useRef(null);

    let collectionForChain = workspaceData.collections.find(collection =>
        collection.chains.some(chain => chain.id === chainID)
    );

    const editorDidMount = (editor) => {
        editorRef.current = editor;
        editor.onDidBlurEditorWidget(() => {
            updatePrompt(editorContentArray); // setPrompt when the editor is blurred
        });
        editor.onDidFocusEditorWidget(async () => {
            // Check if the current user is the controller
            const isChainController = await checkChainController(chainID);
            console.log("SETTING IS CONTROLLER TO ", isChainController)
            setIsController(isChainController);

            if (!isChainController) {
                console.log("IS CHAIN ", isChainController, " IS CON ", isController)
                if (!isChainController) {
                    // Focus on fallback element
                    if (fallbackFocusRef.current) {
                        fallbackFocusRef.current.focus();
                    }
                }
                setShowModal(true); // Show modal if not the controller
            }
        });
    };

    useEffect(() => {
        if (workspaceData.collections) {
            if (collectionForChain && collectionForChain.starting_messages && collectionForChain.starting_messages.length > 0) {
                const startingMessage = collectionForChain.starting_messages[0];
                // Check if the first item of editorContentArray is different from startingMessage
                if (!editorContentArray[0] || (editorContentArray[0].role !== startingMessage.role)) {
                    setEditorContentArray([startingMessage, ...editorContentArray]);
                    updatePrompt([startingMessage, ...editorContentArray])
                } else {
                    if (editorContentArray[0].content !== startingMessage.content) {
                        setEditorContentArray([startingMessage, ...editorContentArray.slice(1)]);
                        updatePrompt([startingMessage, ...editorContentArray.slice(1)])
                    }
                }
            }
        }
    }, [collectionForChain?.starting_messages]);

    useEffect(() => {
        if (chainData && chainData.config && chainData.config.type && chainData.config.type === "chat" && modelDetails) {
            const expectedContent = `{{Message_${cellIndex + 1}_Input}}`;
            const hasPromptEntry = editorContentArray.some(entry => entry.content === expectedContent);

            if (!hasPromptEntry) {
                addNewPrompt()
            }
        }
    }, [chainData, modelDetails]);





    const updatePrompt = async (newPrompt) => {
        const isChainController = await checkChainController(chainID);
        if (!isChainController) {
            console.log("RETURNING")
            return;
        }
        try {
            const response = await axios.put(`${process.env.REACT_APP_ROUTE_PREFIX}/api/cell/update/prompt`,
                {cell_id: cell._id, prompt: newPrompt},
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceDataID)}`
                    }
                }
            );
            // Assuming your API responds with updated cell
            if (response.data) {
                setPrompt(newPrompt)

                setLocalCell(prevCell => ({
                    ...prevCell,
                    config: {...prevCell.config, variables: response.data.variables}
                }));


                if (response.data.new_suite_vars) {
                    setSuiteData(prevSuiteData => {
                        let updatedVariables = response.data.new_suite_vars;

                        return {...prevSuiteData, variables: updatedVariables};
                    });
                }
            } else {
                console.error("Failed to update prompt:");
            }
        } catch (err) {
            console.error("Failed to update prompt:");
        }
    };

    const autoResize = () => {
        try {
            if (editorRef.current) {
                const model = editorRef.current.getModel();
                const newLineCount = model.getLineCount();  // get line count from model

                const layoutInfo = editorRef.current.getLayoutInfo();
                const approxCharWidth = 7.5;  // Approximate width for a monospace character
                const paddingSpaces = 50;  // Spaces to account for padding
                const charactersPerLine = (layoutInfo.width - (2 * paddingSpaces)) / approxCharWidth;

                let wrappedLines = 0;
                for (let i = 1; i <= newLineCount; i++) {
                    const lineContent = model.getLineContent(i);
                    wrappedLines += Math.floor(lineContent.length / charactersPerLine);
                }

                const newHeight = Math.max((newLineCount + wrappedLines) * 19, MIN_HEIGHT); // 19px per line, minimum 200px
                const finalHeight = Math.min(newHeight, MAX_HEIGHT); // maximum 700px
                setEditorHeight(finalHeight);
            }
        } catch (err) {
            console.error("Failed to auto-resize editor:", err);
        }
    }

    // This hook will now trigger on changes to editorContent
    useEffect(() => {
        autoResize();
    }, [editorContent]);

    useEffect(() => {
        editorSetup();
    }, []);

    const handleRoleChange = (index, newRole) => {
        const updatedContent = [...editorContentArray];
        updatedContent[index].role = newRole;
        setEditorContentArray(updatedContent);
        updatePrompt(updatedContent);
    };

    const handleEditorArrayChange = (index, value) => {
        setEditorContentArray(prevContentArray => {
            const updatedContent = [...prevContentArray];
            updatedContent[index].content = value;
            return updatedContent;
        });
    };

    const addNewPrompt = () => {
        let insertedContent = ""
        const firstAvailableRole = modelDetails.roles ? modelDetails.roles[0] : "";
        const newPromptObj = {role: firstAvailableRole, content: insertedContent};
        setEditorContentArray([...editorContentArray, newPromptObj]);
        updatePrompt([...editorContentArray, newPromptObj]);
    };

    const deletePrompt = (index) => {
        const updatedContent = [...editorContentArray];
        updatedContent.splice(index, 1);
        setEditorContentArray(updatedContent);
        updatePrompt(updatedContent);
    };

    // const ensureContentWithinLimit = () => {
    //     if (editorContentArray && modelDetails && modelDetails["context_limit"]) {
    //         let currentLength = editorContentArray.reduce((sum, obj) => sum + (obj.content.length || 0), 0) / 4;
    //
    //         if (currentLength > modelDetails["context_limit"] && editorContentArray.length > 1) {
    //             const updatedContentArray = [...editorContentArray];
    //
    //             let startIndex = updatedContentArray[0].role === "system" ? 1 : 0;
    //             let minimumLength = startIndex + 1;
    //
    //             // Delete entries from the beginning starting from the startIndex until we meet the criteria
    //             while (currentLength > modelDetails["context_limit"] && updatedContentArray.length > minimumLength) {
    //                 updatedContentArray.splice(startIndex, 1); // remove the entry at startIndex
    //                 currentLength = updatedContentArray.reduce((sum, obj) => sum + (obj.content.length || 0), 0) / 4;
    //             }
    //
    //             toast({
    //                 title: "Trimmed beginning entries",
    //                 description: "You were over the context limit for the model, so we trimmed your earlier entries.",
    //                 status: "warning",
    //                 duration: 5000,
    //                 isClosable: true,
    //             });
    //
    //             setEditorContentArray(updatedContentArray);
    //             updatePrompt(updatedContentArray);
    //         }
    //     }
    // };
    //
    //
    //
    // useEffect(() => {
    //     ensureContentWithinLimit();
    // }, [editorContentArray]);

    useEffect(() => {
        if (modelDetails && modelDetails.roles) {
            const firstAvailableRole = modelDetails.roles[0];
            const resetRoles = editorContentArray.map(obj => {
                // Check if current role exists in the new modelDetails roles
                if (!modelDetails.roles.includes(obj.role)) {
                    return {...obj, role: firstAvailableRole};
                }
                return obj; // Return the object as is if the role is valid
            });

            // Check if any roles were reset
            if (editorContentArray.some((obj, index) => obj.role !== resetRoles[index].role)) {
                setEditorContentArray(resetRoles);
                updatePrompt(resetRoles);
            }
        }
    }, [modelDetails]);





    return (
        <>
            {prompt !== undefined && editorContent !== undefined && modelDetails &&
                editorContentArray.map((promptObj, index) => (
                    <div key={index}>
                        <HStack mb={2}>
                            <Select
                                value={promptObj.role}
                                onChange={(e) => handleRoleChange(index, e.target.value)}
                                w={'10%'}
                                minW={'150px'}
                                size={'sm'}
                                borderRadius={'md'}
                                disabled={index === 0 && collectionForChain && collectionForChain.starting_messages && collectionForChain.starting_messages.length > 0}
                            >
                                {(modelDetails.roles || []).map((role, roleIndex) => (
                                    <option key={roleIndex} value={role}>{role}</option>
                                ))}
                            </Select>
                            {editorContentArray.length > 1 && !(chainData && chainData.config && chainData.config.type && chainData.config.type === "chat") && (
                                <Button onClick={() => deletePrompt(index)}
                                        size={'sm'}
                                        ml={2}
                                        w={'10%'}
                                        minW={'100px'}
                                        isDisabled={index === 0 && collectionForChain && collectionForChain.starting_messages && collectionForChain.starting_messages.length > 0}
                                >
                                    Delete
                                </Button>
                            )}
                        </HStack>
                        <MonacoEditor
                            width="100%"
                            height={editorHeight}
                            language="chatter-ninja"
                            theme="vs-dark"
                            options={{
                                wordWrap: 'on',
                                automaticLayout: true,
                                scrollBeyondLastLine: false, // disable scrolling beyond last line
                                readOnly: 
                                ((index === 0 && collectionForChain && collectionForChain.starting_messages && collectionForChain.starting_messages.length > 0)
                                || (chainData && chainData.config && chainData.config.type && chainData.config.type === "chat"))
                            }}
                            editorDidMount={editorDidMount}
                            value={promptObj.content}
                            onChange={(value) => handleEditorArrayChange(index, value)}
                            readOnly={!isController}
                        />
                    </div>
                ))

            }
            {!(chainData && chainData.config && chainData.config.type && chainData.config.type === "chat") &&
            <Button onClick={addNewPrompt}
                    size={'sm'}
                    mt={2}
                    w={'10%'}
                    minW={'150px'}
            >
                + Add
            </Button>}
            {modelDetails && prompt &&
                <Text as="span" mt={0}>
                    ~{editorContentArray.reduce((sum, obj) => sum + (obj.content.length || 0), 0) / 4}/{modelDetails["context_limit"]}
                </Text>
            }
            <button ref={fallbackFocusRef} style={{ position: 'absolute', opacity: 0 }} aria-hidden="true"></button>
            <ControllerAlertModal isOpen={showModal} onClose={() => setShowModal(false)} updateChainController={updateChainController} chainID={chainID} editorRef={editorRef} />
        </>
    );
}

export default PromptEditor;