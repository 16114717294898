import { Center, Text, Box } from '@chakra-ui/react';


function WorkspaceTutorialComponent() {
    return (
        <Center height="100vh" width="100%">
            <Box textAlign="center">
                <Text fontSize="8xl" color="#ED7547">Welcome!</Text>
                <Text fontSize="5xl">Create a workspace to begin.</Text>
            </Box>
        </Center>
    );
}
  export default WorkspaceTutorialComponent;