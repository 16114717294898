import React, {useEffect, useState} from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Select,
    useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import {useUtilityFunctions} from "../../UtilityFunctions";


const AddDocumentModal = ({isOpen, onClose, documents, setDocuments, workspaceID}) => {
    const [documentData, setDocumentData] = useState({
        name: '',
        db_details: {},
        config: '',
    });
    const {getAccessToken} = useUtilityFunctions();
    const toast = useToast();


    const [isNameUnique, setIsNameUnique] = useState(true);

    const embeddingModels = {
        'text-embedding-ada-002': 1536,
    };

    const configs = ['chatter-ada-v2-cosine', 'custom-pinecone'];


    useEffect(() => {
        if (documentData.name) {
            setIsNameUnique(!documents.some((doc) => doc.name === documentData.name));
        }
    }, [documentData.name, documents]);

    useEffect(() => {
        if (!isOpen) {
            setDocumentData({
                name: '',
                db_details: {},
                config: '',
            });
        }
    }, [isOpen]);


    const handleChange = (field, value) => {
        setDocumentData({
            ...documentData,
            [field]: value,
        });
    };

    const handleDbDetailsChange = (field, value) => {
        let details = {
            ...documentData.db_details,
            [field]: value,
        };

        if (field === 'embedding_model') {
            details['dimension'] = embeddingModels[value];
        }

        setDocumentData({
            ...documentData,
            db_details: details,
        });
    };


    const handleConfigChange = (value) => {
        if (value === 'custom-pinecone') {
            setDocumentData({
                ...documentData,
                config: 'custom-pinecone',
                db_details: {
                    ...documentData.db_details,
                    type: 'pinecone'
                },
            });
        } else {
            handleChange('config', value);
        }
    };


    const handleSubmit = async () => {
        if (!isNameUnique || documentData.name === '') {
            toast({
                title: "Error",
                description: "Document name must be unique (local to workspace) and non-empty",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (documentData.config === '' || (documentData.config === 'custom-pinecone' && (
            !documentData.db_details.db_link ||
            !documentData.db_details.environment ||
            !documentData.db_details.api_key ||
            !documentData.db_details.index ||
            !documentData.db_details.embedding_model
        ))) {
            toast({
                title: "Error",
                description: "All fields must be filled.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ROUTE_PREFIX}/api/workspace/${workspaceID}/documents`,
                documentData,
                {
                    headers: {
                        'Authorization': `Bearer ${getAccessToken(workspaceID)}`,
                    },
                }
            );

            if (response.status === 200) {
                console.log('Successfully added document:')
                setDocuments([...documents, response.data.document]);
                onClose();
            } else {
                console.error('Failed to add document:', response.data);
            }
        } catch (error) {
            console.error('Failed to add document:', error);
            // Handle error as needed
        }
    };

    const handleDbLinkChange = (value) => {
        const dbLinkPattern = /^https:\/\/(.*?)-([^-]*)\.svc\.(.*?)\.pinecone\.io$/;
        const matches = dbLinkPattern.exec(value);

        if (matches) {
            let details = {
                ...documentData.db_details,
                index: matches[1],
                project: matches[2], // If needed
                environment: matches[3],
                db_link: value,
            };

            setDocumentData({
                ...documentData,
                db_details: details,
            });
        } else {
            handleDbDetailsChange('db_link', value);
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Add New Document</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <FormControl isInvalid={!isNameUnique}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            type="text"
                            placeholder="Enter document name"
                            onChange={(e) => handleChange('name', e.target.value)}
                        />
                    </FormControl>
                    <FormLabel>Config</FormLabel>
                    <Select
                        placeholder="Select config"
                        onChange={(e) => handleConfigChange(e.target.value)}
                    >
                        {configs.map((config) => (
                            <option value={config} key={config}>
                                {config}
                            </option>
                        ))}
                    </Select>

                    {documentData.config === 'custom-pinecone' && (
                        <>
                            <FormControl>
                                <FormLabel>Type</FormLabel>
                                <Input type="text" value="pinecone" readOnly/>
                                <FormLabel>DB Link</FormLabel>
                                <Input type="text" placeholder="Enter Connection Link"
                                       onChange={(e) => handleDbLinkChange(e.target.value)}/>
                                <FormLabel>Environment</FormLabel>
                                <Input type="text" placeholder="Enter Environment"
                                       value={documentData.db_details.environment || ''} readOnly/>
                                <FormLabel>Index</FormLabel>
                                <Input type="text" placeholder="Index" value={documentData.db_details.index || ''}
                                       readOnly/>
                                <FormLabel>API Key</FormLabel>
                                <Input type="text" placeholder="Enter API Key"
                                       onChange={(e) => handleDbDetailsChange('api_key', e.target.value)}/>
                                <FormLabel>Namespace</FormLabel>
                                <Input type="text" placeholder="Enter Namespace"
                                       onChange={(e) => handleDbDetailsChange('namespace', e.target.value)}/>
                                <FormLabel>Embedding Model</FormLabel>
                                <Select
                                    placeholder="Select embedding model"
                                    onChange={(e) => handleDbDetailsChange('embedding_model', e.target.value)}
                                >
                                    {Object.keys(embeddingModels).map((model) => (
                                        <option key={model} value={model}>
                                            {model}
                                        </option>
                                    ))}
                                </Select>
                                <FormLabel>Dimension</FormLabel>
                                <Input type="text" value={documentData.db_details.dimension || ''} readOnly/>

                            </FormControl>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleSubmit} colorScheme="blue" isDisabled={!isNameUnique}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddDocumentModal;

