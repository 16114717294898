import {Input} from "@chakra-ui/react";

const SemanticInput = ({ currAssertion, handleAssertionChange, handleAssertionBlur }) => {
    return (
        <>
            <Input
                placeholder="Threshold"
                type="number"
                min="0"
                max="1"
                step="0.000001"
                defaultValue={currAssertion.assert_num}
                size="sm"
                w="30%"
                onChange={e => {
                    handleAssertionChange(currAssertion.id, {
                        ...currAssertion,
                        assert_num: parseFloat(e.target.value)
                    });
                }}
                onBlur={handleAssertionBlur}
            />
            <Input
                placeholder="Evaluation string"
                defaultValue={currAssertion.assert_string}
                size="sm"
                onChange={e => handleAssertionChange(currAssertion.id, {
                    ...currAssertion,
                    assert_string: e.target.value
                })}
                onBlur={handleAssertionBlur}
            />
        </>
    );
}

export default SemanticInput;